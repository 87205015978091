import React, { useEffect } from "react";
import '../../assets/components/OMMS/archive.scss'
import { LdsModal,LdsTextField, useLdsModal, useToastContext } from "@elilillyco/ux-lds-react";
import {
  LdsTile,
  LdsTable,
  LdsCheckbox,
  LdsIcon,
  LdsButton,
  LdsPagination,
  LdsLoadingSpinner,
  LdsToast,
  
} from "@elilillyco/ux-lds-react";
import { useState } from "react";

import HeaderNav from "../HeaderNav";
import { useDispatch, useSelector } from "react-redux";
import { fetchArchive } from '../../store/Components/OMMS/archiveStore';
import { updateArchive } from '../../store/Components/OMMS/archiveputStore';
// import {fetchUsers} from '../../store/user'

export default function Archive() {
  // const rc = useSelector((state)=>state.getArchiveEntryRows.records);
 
  const data =  useSelector((state)=>state.getArchiveEntryRows.records);
  const tPages =useSelector((state)=>state.getArchiveEntryRows.numberOfPages);
  const loading =  useSelector((state)=>state.getArchiveEntryRows.loader)
  // const [pageRangeVisible, setPageRangeVisible] = useState(5);
  // const loader =  useSelector((state)=>state.getArchiveEntryRows.loader);
  const role2 = useSelector(({user}) => user.role_ids);
  const { addToast } = useToastContext();
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [isData,setIsData] = useState([...data])
  const [getId, setId] = useState();
  // const [isChecked, setIsChecked] = useState([...data])
  const [isChecked, setIsChecked] = useState(false);
  const [deliverySourceSearchFld, setIsDeliverySource] = React.useState(false);
  const [supplierNameFld, setIsSupplierNameFld] = React.useState(false);
  const [deliverySourceTypeSearchFld, setIsDeliverySourceType] = React.useState(false);
  const [deliveryChannelSearchFld, setIsDeliveryChannel] = React.useState(false);
  const [platformFld, setIsPlatform] = React.useState(false);
  const [contactTypeFld, setIsContactType] = React.useState(false);
  const [engagementClassificationFld, setIsEngagementClassification] = React.useState(false);
  const [metricLabelFld, setIsMetricLabel] = React.useState(false);
  const [hierarchyClassificationFld, setIsHierarchyClassification] = React.useState(false);
  const [metricDefinitionFld, setIsMetricDefinition] = React.useState(false);
  const [statusFld, setIsStatus] = React.useState(false);




  const [deliverySourceSearch, setDeliverySourceSearch] = React.useState('');
  const [deliverySourceTypeSearch, setDeliverySourceTypeSearch] = React.useState('');
  const [supplierNameSearch, setSupplierNameSearch] = React.useState('');
  const [deliveryChannelSearch, setDeliveryChannelSearch] = React.useState('');
  const [platformSearch, setPlatformSearch] = React.useState('');
  const [contactTypeSearch, setContactTypeSearch] = React.useState('');
  const [engagementClassificationSearch, setEngagementClassificationSearch] = React.useState('');
  const [metricLabelSearch, setMetricLabelSearch] = React.useState('');
  const [hierarchyClassificationSearch, setHierarchyClassificationSearch] = React.useState('');
  const [metricDefinitionSearch, setMetricDefinitionSearch] = React.useState('');
  const [statusSearch, setStatusSearch] = React.useState('');











  
  // const [isAllSelected, setIsAllSelected] = useState();
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchArchive( 
      {pageSize:10,
      pageNumber:1,
      status:'',
      deliverySource:'',
      suppName:'',
      delSrcType:'',
      del_channel:'',
      platform:'',
      contactType:'',
      engClass:'',
      hierClass:'',
      metLabel:'',
      metdef:''
      }))
  },[])
  useEffect(() => {
    setIsData([...data]);
  }, [data]);

  useEffect(() => {
    setId(
      isData
        ?.filter((item) => item.check === true)
        .map((item) => item.omms_overview_detail_id)
    );
  }, [isData]);
  
  
  const [lastEvent, setLastEvent] = useState('');
  const numberOfPages = tPages

  const [currentPage, setCurrentPage] = useState(1);
  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    dispatch(fetchArchive({
      
      pageSize: 10,
      pageNumber: value,
      page: 'archive',
      status: statusSearch,
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    })); 
  };
  
  const firstPage = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    setCurrentPage(1)
    dispatch(fetchArchive({
      pageSize: 10,
      pageNumber: 1,
      page: 'archive',
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    })); 
  };

  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    dispatch(fetchArchive({
      pageSize: 10,
      pageNumber: currentPage + 1,
      page: 'archive',
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    })); 
  };
  
  // Called when you click the "previous" link/button
  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    dispatch(fetchArchive({
      pageSize: 10,
      pageNumber: currentPage - 1,
      page: 'archive',
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    })); 
  };
  const lastPage = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    setCurrentPage(numberOfPages)
    dispatch(fetchArchive({
      pageSize: 10,
      pageNumber: numberOfPages,
      page: 'archive',
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    })); 
  };
  function retunToOverview () {
    window.location.href = '/omms';
  }

  function checkedData(e) {
    const value = e.target.id;
    const checked = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    for (let obj of tempData) {
      if (obj.omms_overview_detail_id.toString() === value) {
        obj.check = checked;
      }
    }
    setIsData(tempData);
  }
  async function restoreOnOverview(){
    const currentRole = sessionStorage.getItem('role');
    const currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    const updated_by = currentUserData[0][currentRole];
    await dispatch(updateArchive({'omms_overview_detail_id' : getId, status: "inactive", updated_by}))
    dispatch(fetchArchive({
      pageSize: 10,
      pageNumber:1,
      page: 'archive',
    })); 
  }
  function modalOpen(){
    if (getId.length === 0){
      addToast({
      toastMessage: "Please select atleast one record ",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "warning",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 5000,
      inline: false,
      autoDismiss: true,})
      return;
    }
    setIsModalOpen(true)
  }
  function handleClick(){
    setIsModalOpen(false)
  }

  // function handleClick(){
  //   setIsModalOpen(false)
  // }
  function iconClick(id){
    const iconId = [id]
    setId(iconId)
    setIsModalOpen(true)   
  }

  function handleMainChange(e){
    const isChecking = e.target.checked
    setIsChecked(isChecking)
    const tempData = JSON.parse(JSON.stringify(isData))
    const data= tempData.map((obj)=>{
      obj.check = isChecking
      return obj
    })
    setIsData(data)
  } 
  function searchFld() {
    dispatch(fetchArchive({
      status: statusSearch,
      deliverySource: deliverySourceSearch,
      suppName: supplierNameSearch,
      delSrcType: deliverySourceTypeSearch,
      del_channel: deliveryChannelSearch,
      platform: platformSearch,
      contactType: contactTypeSearch,
      pageSize: 10,
      pageNumber: 1,
      engClass:engagementClassificationSearch,
      hierClass:hierarchyClassificationSearch,
      metLabel:metricLabelSearch,
      metdef:metricDefinitionSearch
    }));
  }

  function clearSearch() {
    setDeliverySourceSearch('');
    setDeliveryChannelSearch('');
    setPlatformSearch('');
    setContactTypeSearch('');
    setEngagementClassificationSearch('');
    setMetricLabelSearch('');
    setHierarchyClassificationSearch('');
    setMetricDefinitionSearch('');
    setStatusSearch('');
    setIsDeliverySource(false);
    setIsDeliverySourceType(false)
    setIsDeliveryChannel(false)
    setIsPlatform(false)
    setIsContactType(false)
    setIsEngagementClassification(false)
    setIsMetricLabel(false)
    setIsHierarchyClassification(false)
    setIsMetricDefinition(false)
    setIsStatus(false)
    dispatch(fetchArchive(
      {pageSize:10,
      pageNumber:1,
      status:'',
      deliverySource:'',
      suppName:'',
      delSrcType:'',
      del_channel:'',
      platform:'',
      contactType:'',
      engClass:'',
      hierClass:'',
      metLabel:'',
      metdef:''
      }
    ))
  
  }

  
  return (
       <>
      <HeaderNav msg={"Omnichannel Metadata Management System"} />
      <LdsTile
        className="Hero"
      >
        <div className="cont">
          <span style={{ fontSize: "3rem" }}>Archive</span>
        </div>


        <div  className="ov-button">
          <LdsButton onClick = {retunToOverview} >
            Overview
          </LdsButton> 
        </div>
        <div  className="ov-button2">
          <LdsButton onClick = {searchFld} >
            Search
          </LdsButton> 
        </div>
        <div  className="ov-button2">
          <LdsButton onClick = {clearSearch} >
            Clear All
          </LdsButton> 
        </div>
        <div  className="ov-button2">
          <LdsButton onClick = {clearSearch} >
            Download as CSV
          </LdsButton> 
        </div>
      </LdsTile>
     

        <LdsTable style={{minHeight:'20rem', textAlign:'left'}}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Select ALL 
                <LdsCheckbox 
                  name="opt1" 
                  // value={isChecked}
                  onChange={(e) => {handleMainChange(e)}}
                  checked={isChecked}/>
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  DELIVERY SOURCE
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsDeliverySource(!deliverySourceSearchFld)}
                     
                    />
                  </div>
                </div>
              {deliverySourceSearchFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={deliverySourceSearch}
                    onChange={(e) => setDeliverySourceSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
            </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  SUPPLIER NAME
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsSupplierNameFld(!supplierNameFld)}
                     
                    />
                  </div>
                </div>
              {supplierNameFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={supplierNameSearch}
                    onChange={(e) => setSupplierNameSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  DELIVERY SOURCE TYPE
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsDeliverySourceType(!deliverySourceTypeSearchFld)}
                     
                    />
                  </div>
                </div>
              {deliverySourceTypeSearchFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={deliverySourceTypeSearch}
                    onChange={(e) => setSupplierNameSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  DELIVERY CHANNEL
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsDeliveryChannel(!deliveryChannelSearchFld)}
                     
                    />
                  </div>
                </div>
              {deliveryChannelSearchFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={deliveryChannelSearch}
                    onChange={(e) => setSupplierNameSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  PLATFORM
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsPlatform(!platformFld)}
                     
                    />
                  </div>
                </div>
              {platformFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={platformSearch}
                    onChange={(e) => setSupplierNameSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  CONTACT TYPE
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsContactType(!contactTypeFld)}
                     
                    />
                  </div>
                </div>
              {contactTypeFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={contactTypeSearch}
                    onChange={(e) => setContactTypeSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  ENGAGEMENT CLASSIFICATION
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsEngagementClassification(!engagementClassificationFld)}
                     
                    />
                  </div>
                </div>
              {engagementClassificationFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={engagementClassificationSearch}
                    onChange={(e) => setEngagementClassificationSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  METRIC LABEL
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsMetricLabel(!metricLabelFld)}
                     
                    />
                  </div>
                </div>
              {metricLabelFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={metricLabelSearch}
                    onChange={(e) => setMetricLabelSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                  HIERARCHY CLASSIFICATION
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsHierarchyClassification(!hierarchyClassificationFld)}
                     
                    />
                  </div>
                </div>
              {hierarchyClassificationFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={hierarchyClassificationSearch}
                    onChange={(e) => setHierarchyClassificationSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                    METRIC DEFINITION
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsMetricDefinition(!metricDefinitionFld)}
                     
                    />
                  </div>
                </div>
              {metricDefinitionFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={metricDefinitionSearch}
                    onChange={(e) => setMetricDefinitionSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col">CAP</th>
              <th scope="col" className="table-ends">
              <div class="attriDiv">
                    STATUS
                  <div class="iconStyle" style={{display: 'flex', alignItems: 'initial',maxHeight: '1rem', marginTop: '0.3rem'}}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{ height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'}}
                      onClick={() => setIsStatus(!statusFld)}
                     
                    />
                  </div>
                </div>
              {statusFld &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    id='orgName'
                    value={statusSearch}
                    onChange={(e) => setStatusSearch(e.target.value)}
                    name='orgName' />
                </div>
                }
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Action
              </th>
            </tr>
          </thead>
          {!loading ? (data && data.length > 0 ?<tbody style={{zIndex:'1'}}>
            {isData.map((entry, rowIndex) => (
              <tr
              role="row" key={rowIndex}>
                <td style={{ textAlign: "center" }}>
                  <LdsCheckbox name="opt1" 
                    id={entry.omms_overview_detail_id} 
                    onChange={(e) => {checkedData(e)}}
                    value={entry.omms_overview_detail_id}
                    checked={entry.check}
                    // value={obj.param_key_val_id} 
                    />
                </td>
                <td className="ta-left">{entry.deliverySource }</td>
                <td className="ta-left">{entry.supplierName}</td>
                <td className="ta-left">{entry.platform}</td>
                <td className="ta-left">{entry.contactType}</td>
                <td className="ta-left">{entry.deliveryChannel}</td>
                <td className="ta-left">{entry.deliverySourceType}</td>
                <td className="ta-left">{entry.metricLabel}</td>
                <td className="ta-left">{entry.engagementClassification}</td>
                <td className="ta-left">{entry.hierarchyClassification}</td>
                <td className="ta-left">{entry.metricDefinition}</td>
                <td className="ta-left">{entry.engagementDepth}</td>
                <td className="ta-left">{entry.contractBasisClassification}</td>
                <td className="ta-left">{entry.performanceBasisClassification}</td>
                <td className="ta-left">{entry.engagementRateCalculatedAgainst}</td>
                <td className="ta-left">{entry.iscap}</td>
                <td className="ta-left">{entry.approvalStatus}</td>
              </tr>
            ))}
          </tbody>:  (<tbody>
                <div>
                <span className='no-data'>No Data Available</span>
                </div>
                </tbody>
              )
            ) :<LdsLoadingSpinner class='loader' size={50} animationSpeed={500} />}
        </LdsTable>
      

{!loading &&
  <div className="d-flex">
        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton>

        <LdsPagination
          onLinkClick={clickLink}
          onNextClick={clickNext}
          onPrevClick={clickPrev}
          currentPage={currentPage}
          totalPages={tPages}
          setCurrentPage={setCurrentPage}
          pageRangeVisible={5}
        />
        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton>
</div>
}
{!loading &&
      <div style={{display:'flex', justifyContent:'center', margin:'1rem 0rem 1rem 0rem'}} >
      <div className="restoreButton" >
        <LdsButton onClick={() => modalOpen()}>Restore</LdsButton>
        <LdsModal
        modalId="testModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading="Are you sure you want to retrieve this record?"
      >
        
        <div className = "space">
        <LdsButton  onClick = {()=>{restoreOnOverview(); handleClick()}}>Yes</LdsButton>
        <LdsButton  onClick = {()=>{handleClick()}}>No</LdsButton>
        </div>

      </LdsModal>

      </div>
    </div>
  }
    </>
   
  )
}