import React from 'react';
import { SideNav } from '../../layout/sideNav';
import Overview from '../../components/list-management-auditing';
import { LdsToastProvider } from '@elilillyco/ux-lds-react';
import '../../assets/components/response-matrix/index.scss';
import ResponseTab from '../../components/response-matrix';

const ResponseMatrix = () => {
  return (
    <LdsToastProvider>
      <div className='page-section'>
        <div>
          <SideNav />
        </div>
        <div className='header'>
          <h2 className="pt-4 pl-4" >Response Matrix</h2>
        </div>
        <div className='page-content'>
          <ResponseTab />
        </div>
      </div>
    </LdsToastProvider>
  );
};

export default ResponseMatrix;
