import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getList, getModalReportsFilter } from "../../../api/actions/listMgmt";
import getTargetListDetails from "../../../api/actions/listMgmt/getTargetListDetails";
import getCampaingDetails from "../../../api/actions/listMgmt/getCampaingDetails";
import getScrubbedList from "../../../api/actions/listMgmt/getScrubbedList"
import getDownloadAuditList from "../../../api/actions/listMgmt/getDownloadAuditList"

const initialState = {
  auditTableData: [],
  isauditTableloading: false,
  targetListTableData: [],
  listModalDataList: [],
  listDataModallistTypeLov: [],
  listDataModallistReceiver: [],
  isAuditModal: false,
  pageNo: 1,
  dataCounts: {},
  execution: [],
  contracting: [],
};

const getTableData = createAsyncThunk("audit/tableList", async (obj) => {
  const response = await getList(obj);
  return response;
});

const getTargetListData = createAsyncThunk(
  "audit/targetModalData",
  async (obj) => {
    const response = await getTargetListDetails(obj);
    return response.data;
  }
);

const listAuditmodalData = createAsyncThunk(
  "audit/listModalData",
  async (obj) => {
    const response = await getCampaingDetails(obj);
    return response;
  }
);

const getModalReportsDetails = createAsyncThunk(
  "audit/ModalReportsData",
  async (obj) => {
    const response = await getModalReportsFilter(obj);
    return response;
  }
);

const downloadScrubbedList = createAsyncThunk(
  "audit/ScrubbedList",
  async (scrubbed_file_name) => {
    const response = await getScrubbedList(scrubbed_file_name);
    return response.data;
  }
);

const downloadAuditList = createAsyncThunk(
  "audit/AuditList",
  async (payload) => {
    const response = await getDownloadAuditList(payload);
    return response.data;
  }
);

const auditTableSlicer = createSlice({
  name: "tableList",
  initialState,
  extraReducers(builder) {
    builder.addCase(getTableData.pending, (state, action) => {
      state.isauditTableloading = false;
    });
    builder.addCase(getTableData.fulfilled, (state, action) => {
      state.dataCounts = action.payload.datacounts;
      state.auditTableData = action.payload.data.data;
      state.pageNo = action.payload.data.pages;
      state.isauditTableloading = true;
    });
    builder.addCase(getTableData.rejected, (state, action) => {
      state.isauditTableloading = false;
    });

    builder.addCase(getTargetListData.pending, (state, action) => {});
    builder.addCase(getTargetListData.fulfilled, (state, action) => {
      state.targetListTableData = action.payload.data;
    });
    builder.addCase(getTargetListData.rejected, (state, action) => {});

    builder.addCase(listAuditmodalData.pending, (state, action) => {
      state.isAuditModal = false;
    });
    builder.addCase(listAuditmodalData.fulfilled, (state, action) => {
      state.execution = action.payload.modalReportsDataList.data.execution;
      state.contracting = action.payload.modalReportsDataList.data.contracting;
      state.listModalDataList = action.payload.modalDataList.data;
            const uniqueListType = [
        ...new Set(state.listModalDataList.map((item) => item.list_type)),
      ];
      const uniqueReceiver = [
        ...new Set(state.listModalDataList.map((item) => item.receiver)),
      ];
      state.listDataModallistTypeLov = uniqueListType.map((option) => ({
        value: option.toUpperCase(),
        label: option.toUpperCase(),
      }));
      state.listDataModallistTypeLov.unshift({ value: "", label: "select" });
      state.listDataModallistReceiver = uniqueReceiver.map((option) => ({
        value: option.toUpperCase(),
        label: option.toUpperCase(),
      }));
      state.listDataModallistReceiver.unshift({ value: "", label: "select" });
      state.isAuditModal = true;
    });
    builder.addCase(listAuditmodalData.rejected, (state, action) => {
      state.isAuditModal = false;
    });
    builder.addCase(getModalReportsDetails.pending, (state, action) => {});
    builder.addCase(getModalReportsDetails.fulfilled, (state, action) => {
      state.execution = action.payload.data.data.execution;
      state.contracting = action.payload.data.data.contracting;
    });
    builder.addCase(getModalReportsDetails.rejected, (state, action) => {});
  
    builder.addCase(downloadScrubbedList.pending, (state, action) => {});
    builder.addCase(downloadScrubbedList.fulfilled, (state, action) => {
      state.isAdminDataUrl = true;
    });
    builder.addCase(downloadScrubbedList.rejected, (state, action) => {});

    builder.addCase(downloadAuditList.pending, (state, action) => {});
    builder.addCase(downloadAuditList.fulfilled, (state, action) => {
      state.isAdminDataUrl = true;
    });
    builder.addCase(downloadAuditList.rejected, (state, action) => {});
  
  },
});

export {
  getTableData,
  getTargetListData,
  listAuditmodalData,
  getModalReportsDetails,
  downloadScrubbedList,
  downloadAuditList
};

export default auditTableSlicer.reducer;
