import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { archiveTacticbyIDs } from '../../../api/actions/tacticentry';
// import { archiveget } from '..api/index';


export const initialState = {
  loader: false,
  records: [],
  pageNumber: 1,
  pageSize: undefined,
};

// const fetchArchive = createAsyncThunk('archiveget/fetchArchive', async (pageSize, pageNumber) => {
//   console.log('in fetcharchive')
//   const response = await archived({pageSize:10, pageNumber:1});
//   console.log('bahslhhx',response)
//   return response.data.data;
// });
const updateArchive = createAsyncThunk(
  "archive/editarchive",
  async (queryparams) => {
    const response = await archiveTacticbyIDs(queryparams);
    return response.data.data;
  }
);

const archiveSlice = createSlice({
  name: 'edirarchive',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateArchive.fulfilled, (state, action) => {
      state.loader = false;
      state.records = action.payload;
      // state.pageNumber = action.payload;
      // state.pageSize = action.payload;
      // state.archive = action.payload;
    });
    builder.addCase(updateArchive.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updateArchive.rejected, (state, action) => {
      state.records = action.payload;
      state.loader = false;
      state.archive = action.payload;
      state.pageNumber = action.payload;
      state.pageSize = action.payload;
    });
  },
});

export { updateArchive };

export default archiveSlice.reducer;


