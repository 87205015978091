/**
 * Get Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
import ctx from "../../../plugins/axios";

const getlistmgtFilter = async () => {
  try {
    const { data, status } = await ctx.get("/auditcapvalues");
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export default getlistmgtFilter;
