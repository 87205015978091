// import { handleError, clearError } from '../utils';
import {getProducts} from '../../../../api/actions/TxmyMgmt/addSubCategory/getProducts'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  Products: [],
  loading: false
};


const fetchProducts = createAsyncThunk(
  "product/fetchProduct",
  async () => {
    const response = await getProducts();
    return response.data;
  }
);

const tacctProducts = createSlice({
  name: "products",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchProducts.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      // state.Products = action.payload.data.filter(option => option.active_flag===true && option.param_key === 'BL').map(option => ({ value: option.param_key_val, label: option.param_val }));
      state.Products = action.payload.data
      
      state.loading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { fetchProducts };
export default tacctProducts.reducer;
