import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderNav from '../HeaderNav';
import { addMetaData } from "../../store/Components/OMMS/addmetadata";
import "../../assets/components/OMMS/addmetadata.scss"
import { 
    LdsModal, 
    LdsTextField, 
    useLdsModal, 
    useToastContext, 
    LdsLink, 
    LdsIcon,
    LdsButton,
    LdsLoadingSpinner,
    LdsValidationError,
    LdsToast 
} from "@elilillyco/ux-lds-react";

export default function AddMetadata () {
    
    const { isModalOpen: isConfirmModalOpen, setIsModalOpen: setIsConfirmModalOpen } = useLdsModal(false);
    const [pageError, setPageError] = useState({});
    const { addToast } = useToastContext();
    const [metadataPayload, setMetadataPayload] = useState({})
    const dispatch = useDispatch();
    const loader = useSelector(({ addMetaDataSlice }) => addMetaDataSlice.loader);

    const toastConfig = (errTxtMsg, msgType) =>  {
        return {
            toastMessage: errTxtMsg,
            actionText: "",
            actionCallback: () => {},
            variant: msgType,
            position: "top",
            align: "center",
            dismissible: true,
            light: false,
            timeout: 5000,
            inline: false,
            autoDismiss: true,
        }
    };

    const HandleAddMetadataFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const submitValues = [];
        const payloadData = [];
        const checkIfAnyValidationError = Object.values(pageError).some((ele) => ele.length > 0)
        if (checkIfAnyValidationError) {
            return addToast(toastConfig("Please check below validation error!", "error"))
        }
        for (const value of formData.keys()) {
        submitValues.push(formData.get(value))
        }
        const checkIfAllNull = submitValues.every((ele) => ele.length === 0)
        if (checkIfAllNull) {
            return addToast(toastConfig("Please fill atleast one attribute!", "error"))
        }
        for (const item of formData.entries()) {
            if (item[1].length) {
                payloadData.push({
                    "attributeType": item[0],
                    "attributeValue": item[1]
                })
            }
        }
        setMetadataPayload({
            "metadata": payloadData
        });
        setIsConfirmModalOpen(true);
    }

    const callAddMetadataApi = async (e) => {
        e.preventDefault();
        const callMeta = async () => {
            const { meta, payload } = await dispatch(addMetaData(metadataPayload));
            return { meta, payload };
        }
        setIsConfirmModalOpen(false)
        const { meta, payload } = await callMeta();
        if (meta.rejectedWithValue) {
            return addToast(toastConfig(`Error- ${payload.data.message}`, 'error'))
        } else {
            return addToast(toastConfig('Added Successfully', 'success')); 
        }
        // return navigate('/omms');
    }

    const handleChange = (e) => {
        e.target.value = e.target.value.toUpperCase();
        e.target.value.match(/[^a-zA-Z0-9\s_-]/) ? setPageError((prevError) => ({...prevError, [e.target.id]: 'Special char not allowed'})) : setPageError((prevError) => ({...prevError, [e.target.id]: ''}));
    }

    return (
        <>
            <HeaderNav msg={"Omnichannel Metadata Management System"} />
            <div>
                <LdsLink href="/omms">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <div align='center'>
                <h2> ENTER APPROVED METADATA VALUES </h2>
            </div>
            {loader &&
                <div className='spinner'>
                <LdsLoadingSpinner size={90} />
                </div>
            }
            {!loader &&
                <form id="addMetadataForm" onSubmit={HandleAddMetadataFormSubmit}>
                    <div align='center'>
                        <div className="col-6 col-md-4 lds-form-field row-field">
                            <LdsTextField
                                className='text-input col-md-6'
                                id='delivery_source'
                                name='delivery_source'
                                label='Delivery Source'
                                error={
                                    pageError.delivery_source && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.delivery_source
                                && <LdsValidationError id="errCategory">{pageError.delivery_source}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='supplier_name'
                                name='supplier_name'
                                label='Supplier Name'
                                error={
                                    pageError.supplier_name && true
                                }
                                // onChange={(e)=> e.target.value.match(/[^a-zA-Z0-9\s_-]/) ? setPageError((prevError) => ({...prevError, [e.target.id]: 'Special char not allowed'})) : setPageError((prevError) => ({...prevError, [e.target.id]: ''})) }
                            />
                            {
                                pageError.supplier_name
                                && <LdsValidationError id="errCategory">{pageError.supplier_name}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='platform'
                                name='platform'
                                label='Platform'
                                error={
                                    pageError.platform && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.platform
                                && <LdsValidationError id="errCategory">{pageError.platform}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='contact_type'
                                name='contact_type'
                                label='Contact Type'
                                error={
                                    pageError.contact_type && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.contact_type
                                && <LdsValidationError id="errCategory">{pageError.contact_type}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='delivery_source_type'
                                name='delivery_source_type'
                                label='Delivery Source Type'
                                error={
                                    pageError.delivery_source_type && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.delivery_source_type
                                && <LdsValidationError id="errCategory">{pageError.delivery_source_type}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='delivery_channel'
                                name='delivery_channel'
                                label='Delivery Channel'
                                error={
                                    pageError.delivery_channel && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.delivery_channel
                                && <LdsValidationError id="errCategory">{pageError.delivery_channel}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='activity_type'
                                name='activity_type'
                                label='Engagement Classification'
                                error={
                                    pageError.activity_type && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.activity_type
                                && <LdsValidationError id="errCategory">{pageError.activity_type}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='activity_data_type'
                                name='activity_data_type'
                                label='Metric Label'
                                error={
                                    pageError.activity_data_type && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.activity_data_type
                                && <LdsValidationError id="errCategory">{pageError.activity_data_type}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='activity_type_classification'
                                name='activity_type_classification'
                                label='Hierarchy Classification'
                                error={
                                    pageError.activity_type_classification && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.activity_type_classification
                                && <LdsValidationError id="errCategory">{pageError.activity_type_classification}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='activity_type_definition'
                                name='activity_type_definition'
                                label='Metric Definition'
                                error={
                                    pageError.activity_type_definition && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.activity_type_definition
                                && <LdsValidationError id="errCategory">{pageError.activity_type_definition}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='ad_format'
                                name='ad_format'
                                label='Ad Format'
                                error={
                                    pageError.ad_format && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.ad_format
                                && <LdsValidationError id="errCategory">{pageError.ad_format}</LdsValidationError>
                            }
                            <LdsTextField
                                className='text-input'
                                id='engagement_depth'
                                name='engagement_depth'
                                label='Engagement Depth'
                                error={
                                    pageError.engagement_depth && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.engagement_depth
                                && <LdsValidationError id="errCategory">{pageError.engagement_depth}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='contract_basis_classification'
                                name='contract_basis_classification'
                                label='Contract Basis Classification'
                                error={
                                    pageError.contract_basis_classification && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.contract_basis_classification
                                && <LdsValidationError id="errCategory">{pageError.contract_basis_classification}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='performance_basis_classification'
                                name='performance_basis_classification'
                                label='performance_basis_classification Basis Classification'
                                error={
                                    pageError.performance_basis_classification && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.performance_basis_classification
                                && <LdsValidationError id="errCategory">{pageError.performance_basis_classification}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='engagement_rate_calculated_against'
                                name='engagement_rate_calculated_against'
                                label='Engagement Rate Calculated Against'
                                error={
                                    pageError.engagement_rate_calculated_against && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.engagement_rate_calculated_against
                                && <LdsValidationError id="errCategory">{pageError.engagement_rate_calculated_against}</LdsValidationError>
                            }
                            <br/>
                            <LdsButton type="submit">Add Metadata</LdsButton>
                            <br/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                </form>
            }
                <LdsToast style={{zIndex:'3'}}/>
                <LdsModal
                    modalId="confirmationModal"
                    open={isConfirmModalOpen}
                    setModalOpen={setIsConfirmModalOpen}
                    persistent
                >
                    <h1>Are you sure you want to add these attributes</h1>
                    <div className="space">
                    <LdsButton onClick={async (e) => { await callAddMetadataApi(e)}}>Yes</LdsButton>
                    <LdsButton onClick={() => setIsConfirmModalOpen(false)}>No</LdsButton>
                    </div>
                </LdsModal>
        </>
    )
}
