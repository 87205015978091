import {
  LdsButton,
  LdsModal,
  LdsTable,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/components/adhocListScrubbing/adhocLsModal.scss";
import { postData } from "../../store/adhocScrubbing/adhocScrubbing";
import infoToastConfig from "../../data/utils/infoToastConfig.js";
import { useEffect, useState } from "react";
import LdsSortableTable from "../../data/utils/SortableTable.js";

export default function AdhocLsModal({
  isModalOpen,
  setIsModalOpen,
  selectedData,
  isFileAdded
}) {
  const { modalData, initialHcpCount, listStatus } = useSelector(
    ({ filters }) => filters
  );
  const dispatch = useDispatch();
  const { addToast } = useToastContext();
  function submitRequiest() {
    const role = sessionStorage.getItem("role");
    selectedData.userRole = role;
    selectedData.initialTargetListCount = initialHcpCount;
    setIsModalOpen(false);
    const post = async () => {
      await dispatch(postData(selectedData));
      addToast(
        infoToastConfig({
          toastMessage:
            "Your list has been successfully submitted and you can check status on History tab.",
          variant: "success",
        })
      );
      window.location.reload(true);
    };
    post();
  }
  // sortable table function
  const [tableRowData, setTableRowData] = useState(modalData);
  useEffect(() => {
    setTableRowData(modalData);
  }, [modalData]);

  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }
  return (
    <>
      <LdsModal
        open={isModalOpen}
        modalId="testModal"
        setModalOpen={setIsModalOpen}
        modalSizeClass={listStatus === true ? "col-md-9" : "col-md-8"}>
       {
        isFileAdded ? 
        <div>
        {initialHcpCount > 0 ? (
          listStatus ? (
            <div>
              <p className="modalData2">
                System has the list(s) that are listed below. Do you still
                want to proceed?
              </p>
              <LdsTable id="existingListTable">
                <LdsSortableTable
                  headAlign={'align-left b'}
                  header={[
                    'Brand',
                    'Indication',
                    'Vendor',
                    'Behavioral Segment',
                    'Brand Segment',
                    'Initial Count',
                    'Final Count',
                    'Created by',
                    'Created Date',
                    'Status'
                  ]}
                  rowData={modalData}
                  rowKeyName={[
                    'brand',
                    'indication',
                    'vendor',
                    'behavioral_segment',
                    'brand_segment',
                    'initial_target_list_count',
                    'final_list_count',
                    'created_by',
                    'created_date',
                    'status_detail'
                  ]}
                  sortedRowData={sortedRowData}
                />
                <tbody>
                  {tableRowData.map((dataList, index) => {
                    return (
                      <tr role="row" key={index}>
                        <th role="rowheader" scope="row">
                          {dataList.brand}{" "}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.indication}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.vendor}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.behavioral_segment}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.brand_segment}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.initial_target_list_count}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.final_list_count}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.created_by}
                        </th>
                        <th role="rowheader" scope="row">
                          {dataList.created_date}
                        </th>
                        <th role="rowheader" scope="row">
                          {" "}
                          {dataList.status_detail}
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </LdsTable>
              <div className="row ta-center mt-5">
                <div className="continueBtn col">
                  <LdsButton onClick={submitRequiest}>Continue</LdsButton>
                </div>
                <div className=" modalbutton col">
                  <LdsButton
                    onClick={() => {
                      setIsModalOpen(false);
                    }}>
                    Cancel
                  </LdsButton>
                </div>
              </div>
            </div>
          ) : (
            <div className="ta-center">
              <h4 className="newSubmitData">
                {" "}
                Are you sure you want to submit?{" "}
              </h4>
              <div>
                <div className="row">
                  <div className="continueBtn col">
                    <LdsButton onClick={submitRequiest}>Continue</LdsButton>
                  </div>
                  <div className=" modalbutton col">
                    <LdsButton
                      onClick={() => {
                        setIsModalOpen(false);
                      }}>
                      Cancel
                    </LdsButton>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="ta-center">
            <h4 className="targetList">
              {" "}
              There is no target list for the selected brand and indication.{" "}
            </h4>
            <LdsButton
              onClick={() => {
                setIsModalOpen(false);
              }}>
              {" "}
              OK{" "}
            </LdsButton>
          </div>
        )}
      </div>
      :
      <div className="ta-center">
              <h4 className="newSubmitData">
                {" "}
                Are you sure you want to submit?{" "}
              </h4>
              <div>
                <div className="row">
                  <div className="continueBtn col">
                    <LdsButton onClick={submitRequiest}>Continue</LdsButton>
                  </div>
                  <div className=" modalbutton col">
                    <LdsButton
                      onClick={() => {
                        setIsModalOpen(false);
                      }}>
                      Cancel
                    </LdsButton>
                  </div>
                </div>
              </div>
      </div>
       }
      </LdsModal>
    </>
  );
}
