import React from 'react'
import '../../assets/components/Loading.scss'

const Loading = () => {
  return (
    <div className='loading-page'>

    <div className='loading-nav'>
        <div className='loading-logo skeleton'></div>
            <div className='loading-cont '>
            <div className='loading-name skeleton'></div>
            <div className='loading-role skeleton'></div>
            <div className='loading-logout skeleton'></div>
        </div>
    </div>
    <div className='loading-header'>
        <div className='loading-header-text skeleton'></div>
    </div>
    <div className='loading-buttons'>
        <div className='loading-page-name skeleton'></div>
        <div className='loading-button skeleton'></div>
        <div className='loading-button skeleton'></div>
        <div className='loading-button skeleton'></div>
    </div>
    <div className='loading-table'>
        <div className='laoding-table-header skeleton'>
        </div>

    </div>
</div>
  )
}

export default Loading