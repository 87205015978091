import {
  LdsTable,
  LdsButton,
  LdsDatepicker,
  LdsModal,
  LdsCheckbox,
  useToastContext,
  LdsLoadingSpinner,
  LdsCard,
  LdsPagination,
  LdsIcon,
} from "@elilillyco/ux-lds-react";
import "../../assets/components/response-matrix/tacticModal.scss";
import { useEffect, useState } from "react";
import infoToastConfig from "../../data/utils/infoToastConfig";
import { useDispatch } from "react-redux";
import { getRmModalData } from "../../store/responseMatrix/rmCap";
import LdsSortableTable from "../../data/utils/SortableTable";

const TacticIdModal = ({
  isTacticModalOpen,
  setTacticModalOpen,
  selectedTacticID,
  tacticModalTableData,
  pageNo,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { addToast } = useToastContext();
  const dispatch = useDispatch();
  let [selectedFilterData, setSelectedFilterData] = useState({
    tactic_id: selectedTacticID,
    send_date: "",
    recieve_date: "",
    pageNo: 1,
    limit: 25,
  });

  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

  const startDate = (startDate) => {
    if (startDate.type === "change") {
      let date = startDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.send_date = date;
        dateRangerChecker();
      }
    } else {
      startDate = startDate.replaceAll("/", "-");
      selectedFilterData.send_date = startDate;
      dateRangerChecker();
    }
  };
  const endDate = (endDate) => {
    if (endDate.type === "change") {
      let date = endDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.recieve_date = date;
        dateRangerChecker();
      }
    } else {
      endDate = endDate.replaceAll("/", "-");
      selectedFilterData.recieve_date = endDate;
      dateRangerChecker();
    }
  };

  const dateRangerChecker = async () => {
    if (
      selectedFilterData.send_date > selectedFilterData.recieve_date &&
      selectedFilterData.recieve_date !== ""
    ) {
      addToast(
        infoToastConfig({
          toastMessage: "The start date should be less than the end date.",
          variant: "warning",
        })
      );
      return;
    }
    selectedFilterData.pageNo = 1;
    tacticFilterDate();
  };
  async function tacticFilterDate() {
    setIsTableLoading(true);
    await dispatch(getRmModalData(selectedFilterData));
    setIsTableLoading(false);
  }

  function clearFilters() {
    selectedFilterData.pageNo = 1;
    setCurrentPage(1);
    selectedFilterData.send_date = "";
    selectedFilterData.recieve_date = "";
    tacticFilterDate();
  }
  function clickLink(e, value) {
    selectedFilterData.pageNo = value;
    tacticFilterDate();
  }
  function clickNext() {
    selectedFilterData.pageNo = selectedFilterData.pageNo + 1;
    tacticFilterDate();
  }
  function clickPrev() {
    selectedFilterData.pageNo = selectedFilterData.pageNo - 1;
    tacticFilterDate();
  }

  //table data
  const [tableRowData, setTableRowData] = useState(tacticModalTableData);
  useEffect(() => {
    setTableRowData(tacticModalTableData);
  }, [tacticModalTableData]);

  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }

  return (
    <>
      <LdsModal
        modalId="testModal"
        open={isTacticModalOpen}
        setModalOpen={setTacticModalOpen}>
        {/* row filter 1 */}
        <div className="row mb-4 rmDatePicker">
          <div className="col-3 datePicker">
            <LdsDatepicker
              dateFormat="MM/DD/YYYY"
              id="testPicker2"
              label="Send Date"
              value={selectedStartDate}
              onChange={(e) => {
                setSelectedStartDate(e);
                startDate(e);
              }}
            />
          </div>
          <div className="col-3 datePicker">
            <LdsDatepicker
              dateFormat="MM/DD/YYYY"
              id="testPicker"
              label="Received Date"
              onChange={(e) => {
                setSelectedEndDate(e);
                endDate(e);
              }}
            />
          </div>
          <div className="col-3 mt-4">
            <LdsButton onClick={clearFilters}>Clear All</LdsButton>
          </div>
        </div>
        <div className="mt-4">
          {!isTableLoading ? (
            tacticModalTableData.length !== 0 ? (
              <div>
                <LdsTable id="tacticTable" >
                  <LdsSortableTable
                    headAlign={'align-left b'}
                    header={['Tactic Id', 'Received From CMI', 'Sent date', 'Received date', 'File Name', 'Status']}
                    rowData={tacticModalTableData}
                    rowKeyName={['tactic_id', '', 'send_date', 'recieve_date', 'filename', 'status']}
                    sortedRowData={sortedRowData}
                  />
                  <tbody>
                    {tableRowData.map((data, rowIndex) => (
                      <tr role="row" key={rowIndex} >
                        <td role="cell" className="align-left">
                          {data.tactic_id}
                        </td>
                        <td role="cell" className="align-center">
                          {
                            data.recieve_date === "" ||
                              data.recieve_date === null ||
                              data.recieve_date === 'None'
                              ? ''
                              : <LdsIcon name="Check" inline className="ldcIconCheck" />
                          }
                        </td>
                        <td role="cell" className="align-left">
                          {data.send_date === 'None' ? '' : data.send_date}
                        </td>
                        <td role="cell" className="align-left">
                          {data.recieve_date === 'None' ? '' : data.recieve_date}
                        </td>
                        <td role="cell" className="align-left rmFileName">
                          <p className="rmFileNamecol">{data.filename}</p>
                        </td>
                        <td role="cell" className="align-left">
                          {data.status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </LdsTable>
                <LdsPagination
                  currentPage={currentPage}
                  onLinkClick={clickLink}
                  onNextClick={clickNext}
                  onPrevClick={clickPrev}
                  pageRangeVisible={5}
                  totalPages={pageNo}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <LdsCard className="ta-center p-3 m-1">
                <p className="noRecord"> No Record Found </p>
              </LdsCard>
            )
          ) : (
            <div className="ta-center p-3 m-4">
              <LdsLoadingSpinner size={50} />
            </div>
          )}
        </div>
      </LdsModal>
    </>
  );
};

export default TacticIdModal;
