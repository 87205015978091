// import { handleError, clearError } from '../utils';
import {getSubCategoryById} from '../../../../api/actions/TxmyMgmt/addSubCategory/getSubCategoryById'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  Categories: [],
  loading: false,
  catLoading: false
};


const fetchSubCategoryByID = createAsyncThunk(
  "category/fetchSubCategory",
  async (id) => {
    const response = await getSubCategoryById(id);
    return response.data;
  }
);

const tacctGetSubcategoryByID = createSlice({
  name: "fetchSubCategoryByID",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchSubCategoryByID.pending, (state, action) => {
      state.loading = true;
      state.catLoading = false;
    });
    builder.addCase(fetchSubCategoryByID.fulfilled, (state, action) => {
        state.Categories = action.payload.data[0][0];
        state.loading = false;
      state.catLoading = true;
    });
    builder.addCase(fetchSubCategoryByID.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { fetchSubCategoryByID };
export default tacctGetSubcategoryByID.reducer;
