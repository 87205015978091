import React, { useEffect } from "react";
import { useState } from "react";
import "../../assets/components/response-matrix/responseMatrix.scss";
import {
  LdsButton,
  LdsSearch,
  LdsSelect,
  LdsLoadingSpinner,
  LdsPagination,
  LdsCard,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRmCapLovs,
  getRmTableData,
  setBrandValue,
} from "../../store/responseMatrix/rmCap";
import RmTable from "./rmTable";
import ExportExcelSheet from "../../data/utils/ExportExcelSheet.js";

const ResponseMatrix = () => {
  const dispatch = useDispatch();
  const { capfilter, isSelectLoading } = useSelector(
    ({ rmSlicer }) => rmSlicer
  );
  const [departmentValue, setSelectedDepartment] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedIndication, setSelectedIndication] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedVeevaDoc, setSelectedVeevaDoc] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchKey, setSearchKey] = useState("");
  const [isLovApiLoad, setIsLovApiLoad] = useState(true);
  useEffect(() => {
    dispatch(
      getRmCapLovs({
        department: "",
        brand: "",
        indication: "",
      })
    );
    dispatch(getRmTableData(selectedFilterData));
  }, []);
  const {
    approvedJobId,
    campaignId,
    department,
    indication,
    product,
    isRmLoading,
    isStatus,
    rmTableData,
    isTableLoading,
    pageCount,
    tableApiStatus,
  } = useSelector(({ rmSlicer }) => rmSlicer);

  let [selectedFilterData, setSelectedFilterData] = useState({
    department: "",
    brand: "",
    indication: "",
    campaign: "",
    veevaDoc: "",
    search: "",
    pageNo: 1,
    limit: 25,
    downloadcsv: "false",
  });
  useEffect(() => {
    setIsLovApiLoad(false);
    setTimeout(() => {
      setIsLovApiLoad(true);
    }, 10);
  }, [product, department]);
  // Campaign  search
  const [searchTermCampID, setSearchTermCampID] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const suggestions = campaignId.filter((item) =>
      item.includes(searchTermCampID)
    );
    setSearchSuggestions(suggestions);
  }, [searchTermCampID]);

  // Veeva Doc ID  search
  const [searchTermDocID, setSearchTermDocID] = useState("");
  const [searchSuggestionsDocID, setSearchSuggestionsDocID] = useState([]);
  const [searchResultsDocID, setSearchResultsDocID] = useState([]);

  useEffect(() => {
    const suggestions = approvedJobId.filter((item) =>
      item.includes(searchTermDocID)
    );
    setSearchSuggestionsDocID(suggestions);
  }, [searchTermDocID]);

  const departmentFilter = (value) => {
    selectedFilterData.pageNo = 1;
    selectedFilterData.department = value;
    casecadeData();
    getTableData();
  };
  async function casecadeData() {
    dispatch(getRmCapLovs(selectedFilterData));
  }
  const brandFilter = (value) => {
    selectedFilterData.pageNo = 1;
    selectedFilterData.brand = value;
    dispatch(setBrandValue(selectedFilterData.brand));
    casecadeData();
    getTableData();
  };
  const indicationFilter = (value) => {
    selectedFilterData.pageNo = 1;
    selectedFilterData.indication = value;
    // casecadeData();
    getTableData();
  };
  const campaignFilter = (value) => {
    selectedFilterData.pageNo = 1;
    selectedFilterData.campaign = value;
    getTableData();
  };
  const veevaDocFilter = (value) => {
    selectedFilterData.pageNo = 1;
    selectedFilterData.veevaDoc = value;
    getTableData();
  };
  function selectedValueSearch(campaignId) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.search = campaignId;
    getTableData();
  }
  function clickLink(e, value) {
    setCurrentPage(value);
    selectedFilterData.pageNo = value;
    getTableData();
  }
  function clickNext() {
    setCurrentPage(currentPage + 1);
    selectedFilterData.pageNo = currentPage + 1;
    getTableData();
  }
  function clickPrev() {
    setCurrentPage(currentPage - 1);
    selectedFilterData.pageNo = currentPage - 1;
    getTableData();
  }
  function getTableData() {
    dispatch(getRmTableData(selectedFilterData));
  }
  function clearFilter() {
    selectedFilterData.department = "";
    selectedFilterData.brand = "";
    selectedFilterData.indication = "";
    selectedFilterData.campaign = "";
    selectedFilterData.veevaDoc = "";
    selectedFilterData.search = "";
    selectedFilterData.pageNo = 1;
    selectedFilterData.downloadcsv = false;
    setSelectedDepartment("");
    setSelectedBrand("");
    setSelectedIndication("");
    setSelectedCampaign("");
    setSelectedVeevaDoc("");
    setSearchTerm("");
    setSearchTermDocID("");
    setSearchTermCampID("");
    setCurrentPage(1);
    getTableData();
    casecadeData();
  }
  async function downloadcsvData() {
    const { meta, payload } = await dispatch(
      getRmTableData({ ...selectedFilterData, downloadcsv: "true" })
    );
    if (meta.requestStatus === "fulfilled") {
      const csvdata = payload.data.data;
      if (csvdata.length) {
        ExportExcelSheet(csvdata, "Response_Matrix");
      }
    }
  }

  return (
    <>
      <div>
        {isLovApiLoad && (
          <div>
            {/* row filter 1 */}
            <div className="row">
              <div className="col-4">
                <LdsSearch
                  id={"search"}
                  placeholder="Search"
                  autofocus={false}
                  disabled={isStatus || isRmLoading}
                  inputAriaLabel={null}
                  isTypeAhead={true}
                  onChange={setSearchTerm}
                  onSubmit={selectedValueSearch}
                  value={searchTerm}
                ></LdsSearch>
              </div>
              <div className="col-2 clearbtn ">
                <LdsButton
                  onClick={clearFilter}
                  disabled={isStatus || isRmLoading}
                >
                  Clear All
                </LdsButton>
              </div>
              <div className="col-4">
                <LdsButton
                  icon="DownloadSimple"
                  disabled={isStatus || isRmLoading}
                  onClick={downloadcsvData}
                >
                  Download As CSV
                </LdsButton>
              </div>
            </div>
            {/* row filter 2 */}
            <div className="row mt-4">
              <div className="col">
                <LdsSelect
                  id="Department"
                  label="Department"
                  name="Department"
                  disabled={isStatus || isRmLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedDepartment(e.value);
                    departmentFilter(e.value);
                  }}
                  options={department}
                  value={departmentValue}
                />
              </div>
              <div className="col">
                <LdsSelect
                  id="Brand"
                  label="Brand/Theraputic Area"
                  name="Brand"
                  disabled={isStatus || isRmLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedBrand(e.value);
                    brandFilter(e.value);
                  }}
                  options={product}
                  value={selectedBrand}
                />
              </div>
              <div className="col rm-select-dropdown-indication">
                <LdsSelect
                  id="Indication"
                  label="Indication/Disease State "
                  name="Indication"
                  disabled={isStatus || isRmLoading}
                  onChange={(e) => {
                    setSelectedIndication(e.value);
                    indicationFilter(e.value);
                  }}
                  options={indication}
                  value={selectedIndication}
                />
              </div>
              <div className="col rm-select-dropdown-campaign">
                <label htmlFor="CampaignID" className="searchfont">
                  Campaign ID
                </label>                
                <LdsSearch
                  id="RmCampaignID"
                  inputAriaLabel={null}
                  isTypeAhead={true}
                  autofocus={false}
                  disabled={isStatus || isRmLoading}
                  onChange={(e) => {
                    setSearchTermCampID(e.toUpperCase());
                  }}
                  onSubmit={(e) => {
                    campaignFilter(e);
                  }}
                  searchSuggestions={searchSuggestions}
                  value={searchTermCampID}
                />
              </div>
              <div className="col veevasearchfont">
                <label htmlFor="VeevaDocID" className="searchfont">
                  Veeva Doc ID
                </label>
                <LdsSearch
                  id="RmVeevaDocID"
                  inputAriaLabel={null}
                  isTypeAhead={true}
                  autofocus={false}
                  disabled={isStatus || isRmLoading}
                  onChange={(e) => {
                    setSearchTermDocID(e.toUpperCase());
                  }}
                  onSubmit={(e) => {
                    veevaDocFilter(e);
                  }}
                  searchSuggestions={searchSuggestionsDocID}
                  value={searchTermDocID}
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-4">
          {isTableLoading ? (
            rmTableData.length > 0 ? (
              <div>
                <RmTable
                  rmTableData={rmTableData}
                  tableApiStatus={tableApiStatus}
                />
                <LdsPagination
                  currentPage={currentPage}
                  onLinkClick={clickLink}
                  onNextClick={clickNext}
                  onPrevClick={clickPrev}
                  pageRangeVisible={10}
                  totalPages={pageCount}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <LdsCard className="ta-center p-3 m-1">
                <p className="noRecord"> No Record Found </p>
              </LdsCard>
            )
          ) : tableApiStatus ? (
            <div className="ta-center p-3 m-4">
              <LdsLoadingSpinner size={50} />
            </div>
          ) : (
            <RmTable
              rmTableData={rmTableData}
              tableApiStatus={tableApiStatus}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ResponseMatrix;
