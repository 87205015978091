import ctx from '../../../plugins/axios';
// eslint-disable-next-line import/no-anonymous-default-export
export default async (payload) => {
  try {
    if (payload.pageName === 'updatePage'){
      const { data, status } = await ctx.put(`/vendorentries?pageName=${payload.pageName}`, payload.payload);
      return { data, status };
    }
    else{
      const { data, status } = await ctx.put(`/vendorentries?pageName=rfa`, payload);
      return { data, status };
    }
  } catch (error) {
    return { error };
  }
};