import ctx from '../../../plugins/axios';

export default async (queryParams) => {
  try {
    const { data, status } = await ctx.get(`/vendorentries/export?delivery_source=${queryParams.deliverySource}&delivery_source_type=${queryParams.deliverySourceType}&supplier_name=${queryParams.supplierName}&contact_type=${queryParams.contactType}&platform=${queryParams.platform}&delivery_channel=${queryParams.deliveryChannel}&role=${queryParams.role}&page=${queryParams.page}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
