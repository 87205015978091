import React, { useEffect } from "react";
import {
  LdsTable,
  LdsPagination,
  LdsLink,
  useLdsModal,
  LdsIcon,
  useToastContext,
  LdsButton,
  LdsLoadingSpinner,
  LdsCard,
} from "@elilillyco/ux-lds-react";
import { useState } from "react";
import "../../assets/components/adhocListScrubbing/historyTable.scss";
import { useDispatch } from "react-redux";
import FinalCountModal from "./finalCountModal";
import { downloadListAdmin } from "../../store/adhocHistory/adhochistory";
import infoToastConfig from "../../data/utils/infoToastConfig.js";
import LdsSortableTable from "../../data/utils/SortableTable.js";

export default function Historytable({
  adhocTableData,
  pagination,
  isTableDataLoading,
  pageNo,
  selectedFilterData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(selectedFilterData);
  }, [selectedFilterData]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: false });
    return `${formattedDate.replaceAll("/", "-")} ${formattedTime}`;
  };
  const {
    isModalOpen: isFinalCoundModal,
    setIsModalOpen: setIsFinalCountModal,
  } = useLdsModal();
  const { isModalOpen: isReasonModal, setIsModalOpen: setIsReasonModal } =
    useLdsModal();
  const [modalData, setModalData] = useState({});
  const [failedReason, setFaiedReason] = useState("");
  const [role, setRole] = useState("");
  const { addToast } = useToastContext();
  const [header, setHeaders] = useState([]);
  const [rowsKeys, setRowsKeys] = useState([]);

  useEffect(() => {
    let choosedRole = sessionStorage.getItem("role");
    setRole(choosedRole);
    if (choosedRole === "omt_list_management_admin" || choosedRole === 'omt_admin') {
      setHeaders([
        'Brand', 
        'Indication', 
        'Vendor', 
        'Behavioral Segment',
        'Brand Segment',
        'Status',
        'Initial Count',
        'Final Count',
        'Created by',
        'Created Date',
        'Job Id',
        'Action'
      ]);
      setRowsKeys([
        'brand',
        'indication',
        'vendor',
        'behavioral_segment',
        'brand_segment', 
        'status_detail', 
        'initial_target_list_count', 
        'final_list_count', 
        'created_by', 
        'created_date',
        'job_id',
        ''
      ]);
      return;
    }
    setHeaders([
      'Brand', 
      'Indication', 
      'Vendor', 
      'Behavioral Segment',
      'Brand Segment',
      'Status',
      'Initial Count',
      'Final Count',
      'Created by',
      'Created Date',
      'Job Id'
    ]);
    setRowsKeys([
      'brand',
      'indication',
      'vendor',
      'behavioral_segment',
      'brand_segment', 
      'status_detail', 
      'initial_target_list_count', 
      'final_list_count', 
      'created_by', 
      'created_date',
      'job_id'
    ]);
  }, [dispatch]);

  function clickLink(e, value) {
    pagination(value);
  }
  function clickNext() {
    pagination(currentPage + 1);
  }
  function clickPrev() {
    pagination(currentPage - 1);
  }
  function fincalCountModalData(supression_details) {
    setModalData(supression_details);
    setIsFinalCountModal(true);
  }
  async function downloadAdminList(jobId) {
    const { payload } = await dispatch(downloadListAdmin(jobId));
    const url = payload.url;
    if (url !== "") {
      window.location.href = url;
      addToast(
        infoToastConfig({
          toastMessage: "Downloaded successfully.",
          variant: "success",
        })
      );
    } else {
      addToast(
        infoToastConfig({
          toastMessage: "There is no data for selected job Id.",
          variant: "informative",
        })
      );
    }
  }
  function reasonFailureModal(reason) {
    setFaiedReason(reason);
    setIsReasonModal(true);
  }

  //sortable table function
  const [tableRowData, setTableRowData] = useState(adhocTableData);
  useEffect(() => {
    setTableRowData(adhocTableData);
  }, [adhocTableData]);

  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }

  return (
    <>
      <section>
        <div className="audit-table-card">
          {isTableDataLoading ? (
            adhocTableData.length > 0 ? (
              <div>
                <LdsTable id="HistoryTable">
                  <LdsSortableTable
                    headAlign={'align-left b'}
                    header={header}
                    rowData={adhocTableData}
                    rowKeyName={rowsKeys}
                    sortedRowData={sortedRowData}
                  />
                  <tbody>
                    {tableRowData.map((entry, rowIndex) => {
                      return (
                        <tr role="row" key={rowIndex}>
                          <td role="cell" className="align-left">
                            {entry.brand}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.indication}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.vendor}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.behavioral_segment}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.brand_segment}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.status_detail === "FAILED" ? (
                              <LdsLink
                                disabled
                                onClick={() =>
                                  reasonFailureModal(entry.reason_for_failure)
                                }>
                                {entry.status_detail}
                              </LdsLink>
                            ) : (
                              entry.status_detail
                            )}
                          </td>
                          <td role="cell" className="align-left">
                            {entry.initial_target_list_count}
                          </td>
                          <td role="cell" className="align-left">
                            <LdsLink
                              onClick={() => {
                                fincalCountModalData(entry.supression_details);
                              }}>
                              {entry.final_list_count}
                            </LdsLink>
                          </td>
                          <td role="cell" className="align-left">
                            <p className="tableColumn">{entry.created_by}</p>
                          </td>
                          <td role="cell" className="align-left">
                            <p className="dataColumn">
                              {formatDate(entry.created_date)}
                            </p>
                          </td>
                          <td role="cell" className="align-left">
                            <p className="tableColumn">{entry.job_id}</p>
                          </td>
                          {(role === "omt_list_management_admin" || role === 'omt_admin') && (
                            <td
                              role="cell"
                              className="align-left iconAlignment">
                              <LdsButton
                                linkButton={true}
                                disabled={
                                  entry.status_detail === "SUCCESS"
                                    ? false
                                    : true
                                }
                                className="downloadIcon"
                                onClick={() => {
                                  downloadAdminList(entry.job_id);
                                }}>
                                <div>
                                  <LdsIcon
                                    name="DownloadSimple"
                                    inline
                                    decorative
                                  />
                                </div>
                              </LdsButton>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </LdsTable>
                <LdsPagination
                  currentPage={currentPage}
                  onLinkClick={clickLink}
                  onNextClick={clickNext}
                  onPrevClick={clickPrev}
                  pageRangeVisible={5}
                  totalPages={pageNo}
                  setCurrentPage={setCurrentPage}
                />
                <FinalCountModal
                  isFinalCoundModal={isFinalCoundModal}
                  setIsFinalCountModal={setIsFinalCountModal}
                  modalData={modalData}
                  isReasonModal={isReasonModal}
                  setIsReasonModal={setIsReasonModal}
                  failedReason={failedReason}
                />
              </div>
            ) : (
              <LdsCard className="ta-center p-3 m-1">
                <p className="noRecord"> No Record Found </p>
              </LdsCard>
            )
          ) : (
            <div className="ta-center p-3 m-4">
              <LdsLoadingSpinner size={50} />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
