// import { handleError, clearError } from '../utils';
import {getCountries} from '../../../../api/actions/TxmyMgmt/addSubCategory/getCountries'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  countryNumber: [],
  loading: false
};


const fetchCountries = createAsyncThunk(
  "country/fetchCountry",
  async () => {
    const response = await getCountries();
    return response.data;
  }
);

const tacctCountries = createSlice({
  name: "countries",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchCountries.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
        state.countryNumber = action.payload.data;
        state.loading = false;
    });
    builder.addCase(fetchCountries.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { fetchCountries };
export default tacctCountries.reducer;
