import ctx from '../../../../plugins/axios';

export async function getcontentEntry (queryParams) {
  try {
    const { data, status } = await ctx.get(`/bl-overview?cat=${queryParams.cat}&active_flag=${queryParams.active_flag}&cat_def=${queryParams.cat_def}&sub_cat=${queryParams.sub_cat}&sub_cat_def=${queryParams.sub_cat_def}&product=${queryParams.product}&country=${queryParams.country}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&status=${queryParams.status}&group_purpose_code=${queryParams.group_purpose_code}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export async function getcontentEntry2 (queryParams) {
  try {
    const { data, status } = await ctx.get(`/bl-overview?active_flag=${queryParams.active_flag}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&group_purpose_code=${queryParams.group_purpose_code}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};