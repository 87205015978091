import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTacticbyID } from '../../../api/actions/tacticentry/index'

export const initialState = {
  TacticbyID: [],
  tacticOptions: [],
  totalPages: 0,
  loader: false
}

const TacticbyID = createAsyncThunk(
  "getMetaData/TacticbyID",
  async (queryParams) => {
    const response = await getTacticbyID(queryParams);
    return await response.data;
  }
);

const getTacticbyIDSlice = createSlice({
  name: 'TacticbyID',
  initialState,
  extraReducers(builder) {
    builder.addCase(TacticbyID.fulfilled, (state, action) => {
      state.TacticbyID = action.payload.data;
      state.loader = true;
    });
    builder.addCase(TacticbyID.pending, (state, action) => {
      state.loader = false;
    });
    builder.addCase(TacticbyID.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { TacticbyID };
// export {getMetaData};
export default getTacticbyIDSlice.reducer;