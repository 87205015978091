import { configureStore } from "@reduxjs/toolkit";
import user from "./user";
import overview from "./Components/OMMS/overview.js";
import getArchiveEntryRows from "./Components/OMMS/archiveStore.js";
import archiveTacticbyIDs from "./Components/OMMS/archiveputStore.js";
import overviewSlice from "./Components/TACCT/BL/tacctmetadata.js";
import historySlice from "./Components/OMMS/history.js";
import tacctEditCatSlice from "./Components/TACCT/BL/taccteditcategory.js";
import tacctUpdateCatSlice from "./Components/TACCT/BL/taccteditcategoryput.js";
import updateTacticbyID from "./Components/OMMS/updateTactic.js";
import tacctRfaDataSlice from "./Components/TACCT/BL/tacctrequestForApproval.js";
import tacctEditApproval from "./Components/TACCT/BL/tacctEditApproval.js";
import lmfilters from "./Components/listmgt/filtersData.js";
import getHistoryTableData from "./adhocHistory/adhocHistoryTable.js";
import getTableData from "./Components/listmgt/auditTrailTable.js";
import HistoryFilter from "./adhocHistory/adhochistory.js";
import filters from "./adhocScrubbing/adhocScrubbing.js";
import OptionsSlice from "./Components/OMMS/getoptions.js";
import metaDataSlice from "./Components/OMMS/addtactic.js";
import addCategorySlice from './Components/TACCT/BL/taactaddcategory.js';
import addTacticSlice from './Components/OMMS/Vendorentries.js';
import tacctCountries from "./Components/TACCT/BL/tacctGetCountries.js";
import tacctProducts from "./Components/TACCT/BL/tacctGetProducts.js";
import tacctCategories from "./Components/TACCT/BL/tacctGetCategories.js";
import tacctCatOpDef from "./Components/TACCT/BL/tacctCatOpDefinition.js";
import tacctDuplicate from "./Components/TACCT/BL/tacctCheckDuplicate.js";
import tacctCreateSubCat from "./Components/TACCT/BL/tacctCreateSubcategory.js";
import tacctGetSubcategoryByID from "./Components/TACCT/BL/tacctGetSubcategoryByID.js";
import tacctUpdateSubcategory from "./Components/TACCT/BL/tacctUpdateSubcategory.js";
import getTacticbyIDSlice from './Components/OMMS/getTacticbyID.js';
import tacctProductSlice from './Components/TACCT/BL/tacctEditProductget.js';
import tacctUpdateProductSlice from './Components/TACCT/BL/tacctEditProductput.js';
import addMetaDataSlice from './Components/OMMS/addEditMetadata.js';
import omtMtSlice from "./maintenance/omtMt.js";
// import addMetaDataSlice from './Components/OMMS/addmetadata.js';
import rmSlicer from './responseMatrix/rmCap.js';
import buDataSlice from './Components/TACCT/BU/buoverview.js';
import tnDataSlice from './Components/TACCT/TN/tnoverview.js';
import buEntrySlice from './Components/TACCT/BU/buentry.js'
import gfEntrySlice from './Components/TACCT/GF/gfentry.js'
import commonTacticSlice from "./Components/OMMS/commonTactic.js";
import tonalityEntrySlice from './Components/TACCT/TN/tonailtyEntry.js'
import rmsStoreSlicer from './responseMatrix/rmsStore.js'
import getUsersLists from './Components/OMMS/UsersList.js'
import exceptionSlice from "./Components/OMMS/exceptionReporting.js";


// export const store = configureStore({
//   reducer: { counter, [postsApi.reducerPath]: postsApi.reducer },
//   middleware: (getDefaultMiddleware) =>
//     // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
//     getDefaultMiddleware().concat(postsApi.middleware),
// });

export const store = configureStore({
  reducer: {
    user,
    overview,
    getArchiveEntryRows,
    archiveTacticbyIDs,
    tacctRfaDataSlice,
    tacctEditApproval,
    overviewSlice,
    historySlice,
    tacctEditCatSlice,
    tacctUpdateCatSlice,
    updateTacticbyID,
    lmfilters,
    getTableData,
    HistoryFilter,
    getHistoryTableData,
    filters,
    OptionsSlice,
    metaDataSlice,
    addCategorySlice,
    addTacticSlice,
    getTacticbyIDSlice,
    tacctCountries,
    tacctCategories,
    tacctProducts,
    tacctCatOpDef,
    tacctDuplicate,
    tacctCreateSubCat,
    addMetaDataSlice,
    tacctGetSubcategoryByID,
    tacctUpdateSubcategory,
    tacctProductSlice,
    tacctUpdateProductSlice,
    rmSlicer,
    buEntrySlice,
    buDataSlice,
    tnDataSlice,
    gfEntrySlice,
    tonalityEntrySlice,
    omtMtSlice,
    commonTacticSlice,
    rmsStoreSlicer,
    getUsersLists,
    exceptionSlice
  },
});