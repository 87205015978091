// import { handleError, clearError } from '../utils';
import {updateCategory} from '../../../../api/actions/TxmyMgmt/editCategory/updateCategory';

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  getCategories: [],
  totalPages: 0,
};



const updateCategoryData = createAsyncThunk(
  "overview/updateCategory",
  async (payload) => {
    const response = await updateCategory(payload);
    return response.data;
  }
);



const tacctUpdateCatSlice = createSlice({
  name: "updateCategory",
  initialState,
  
  extraReducers(builder) {
   
    builder.addCase(updateCategoryData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCategoryData.fulfilled, (state, action) => {
        // state.getCategories = action.payload.data;
        // state.totalPages = action.payload.totalPages;
        state.loading = false;
    });
    builder.addCase(updateCategoryData.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { updateCategoryData };
export default tacctUpdateCatSlice.reducer;