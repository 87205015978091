import { SideNav } from "../../layout/sideNav";
import "../../assets/pages/adhoc-list-scrubbing/listScrubbingIndex.scss";
import AdhocListScrubbing from "../../components/adhocListScrubbing/adhocListScrubbing";

export default function ListScrubbing() {
  return (
    <>
      <div id="main">
        <div className="section p-0">
          <div>
            <div>
              <SideNav />
            </div>
            <div className="header">
              <h2 className="pt-4 pl-4"> Adhoc List Scrubbing </h2>
            </div>
            <div className="page-content">
              <div className="adhocCard">
                <div className="adhocCardBody">
                  <AdhocListScrubbing />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
