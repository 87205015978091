import ctx from '../../../../plugins/axios';

export  async function getCategory ( queryParams) {
    try {
      const { data, status } = await ctx.get(`/bl-cat-ops?cat=${queryParams.cat}&cat_def=${queryParams.cat_def}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&order_by=${queryParams.order_by}&order_direction=${queryParams.order_direction}`);
      return { data, status };
    } catch (error) {
      return { error };
    }
  };
