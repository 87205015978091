import { LdsAccordion } from "@elilillyco/ux-lds-react";
import BarChart from "./Bar";
import { useEffect, useState } from "react";
import PieChart from "./Pie";

export default function ListDataGraph({ dataCounts }) {
  const vendorlabel = [
    "EHR",
    "LILLY",
    "DIRECT MAIL HIBBERT",
    "SOA",
    "DIRECT MAIL SOA",
  ];
  const medlabels = ["Medical", "Retail"];
  const targetlistlabels = ["YES", "NO"];
  const [vendorValues, setVendorValue] = useState([]);
  const [targetlist, setTargetlist] = useState([]);
  const [medicalRetail, setMedicalRetail] = useState([]);
  useEffect(() => {
    setTargetlist([dataCounts.yes, dataCounts.no]);
    setMedicalRetail([dataCounts.medical, dataCounts.retail]);
    setVendorValue([
      dataCounts.ehr,
      dataCounts.lilly,
      dataCounts["direct mail hibbert"],
      dataCounts.soa,
      dataCounts["direct mail soa"],
    ]);
  }, []);

  return (
    <>
      <LdsAccordion accordionId="accordion1">
        <LdsAccordion.Header accordionId="accordion1">
          Reports
        </LdsAccordion.Header>
        <LdsAccordion.Body accordionId="accordion1" className="pavan">
          <div className="row chartRow">
            <div className="col-3 pieChart">
              <h6>BIA Target List</h6>
              <PieChart
                className="pp"
                targetlistlabels={targetlistlabels}
                targetlist={targetlist}
              />
            </div>
            <div className="col-6 barChart">
              <h6>Campaign count based on Vendor's</h6>
              <BarChart vendorlabel={vendorlabel} vendorValues={vendorValues} />
            </div>
            <div className="col-3  pieChart">
              <h6>Medical/Retail count</h6>
              <PieChart
                targetlistlabels={medlabels}
                targetlist={medicalRetail}
              />
            </div>
          </div>
        </LdsAccordion.Body>
      </LdsAccordion>
    </>
  );
}
