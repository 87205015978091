import ctx from '../../../../plugins/axios';

export async function getTnOverview (queryParams) {
  try {
    const { data, status } = await ctx.get(`/tn-overview?page=${queryParams}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
