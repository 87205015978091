import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getArchiveEntryRows } from '../../../api/actions/tacticentry';

// import { archiveget } from '..api/index';


export const initialState = {
  loader: false,
  records: [],
  pageNumber: 1,
  pageSize: undefined,
  columnType: '',
  numberOfPages: ''
};

// const fetchArchive = createAsyncThunk('archiveget/fetchArchive', async (pageSize, pageNumber) => {
//   console.log('in fetcharchive')
//   const response = await archived({pageSize:10, pageNumber:1});
//   console.log('bahslhhx',response)
//   return response.data.data;
// });
const fetchArchive = createAsyncThunk(
  "overview/fetchOverview",
  async (queryparams) => {
    const response = await getArchiveEntryRows(queryparams);
    return response.data.data;
  }
);

const archiveSlice = createSlice({
  name: 'archived',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchArchive.fulfilled, (state, action) => {
      state.loader = false;
      // state.records = action.payload.data;
      let temp = JSON.parse(JSON.stringify(action.payload.data));
      state.records = temp.map((obj) => {
        obj['check'] = false;
        // obj['toggleCheck'] = false;
        return obj
      });
      // state.pageNumber = action.payload;
      // state.pageSize = action.payload;
      // state.columnType = action.payload;
      state.numberOfPages = action.payload.numberOfPages;
    });
    builder.addCase(fetchArchive.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchArchive.rejected, (state, action) => {
      state.records = action.payload;
      state.loader = false;
      state.archive = action.payload;
      state.pageNumber = action.payload;
      state.pageSize = action.payload;
      state.columnType = action.payload;
      state.numberOfPages = action.payload;
    });
  },
});

export { fetchArchive };

export default archiveSlice.reducer;
