import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOptions } from '../../../api/actions/tacticentry';


// export const initialState = {
//     Options: [],
//     loader:false
// }

// const fetchOptions = createAsyncThunk(
//   "getOptions/fetchOptions",
//   async (queryParams) => {
//     const response = await getOptions(queryParams);
//     console.log(response,'response getoptions');
//     return await response.data;
//   }
// );
const fetchOptions = createAsyncThunk(
  "getOptions/fetchOptions",
  async (queryParams) => {
    // console.log(queryParams )
    const response = await getOptions(queryParams);
    return response.data;
  }
);

  const OptionsSlice = createSlice({
    name: 'fetchOptions',
    initialState : {
      Options: [],
      loader:false
    },
    extraReducers(builder) {
      builder.addCase(fetchOptions.fulfilled, (state, action) => {
        // console.log('from getOption action: ', action)
        state.Options = action.payload.data;
        state.loader = false;
      });
      builder.addCase(fetchOptions.pending, (state, action) => {
        // console.log('in pending state: ', action)
        state.loader = true;
      });
      builder.addCase(fetchOptions.rejected, (state, action) => {
        state.loader = false;
      });
    },
  });

export {fetchOptions};
export default OptionsSlice.reducer;