import React from 'react'
import Overview from '../../components/OMMS/overview'
import Chatbot from '../Chatbot';


export default function Index() {
  return(<>
  <Overview/>
  <Chatbot />
    </>
    
  )
}
