import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getlistmgtFilter } from "../../../api/actions/listMgmt";

const initialState = {
  brand: [],
  vendor: [],
  indication: [],
  campaignType: [],
  userId: [],
  isFilterloading: false,
  biaTarget: [],
  deviation: [],
};

const listmgtFilter = createAsyncThunk("adhoc/listmgtfilters", async () => {
  const response = await getlistmgtFilter();
  return response.data.data;
});

const lmfiltersSlicer = createSlice({
  name: "lmtFilters",
  initialState,
  extraReducers(builder) {
    builder.addCase(listmgtFilter.pending, (state, action) => {
      state.isFilterloading = false;
    });
    builder.addCase(listmgtFilter.fulfilled, (state, action) => {
      action.payload.brand.sort();
      state.brand = action.payload.brand.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.brand.unshift({
        value: "",
        label: "Select",
      });
      action.payload.vendor.sort();
      state.vendor = action.payload.vendor.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.vendor.unshift({
        value: "",
        label: "Select",
      });
      action.payload.indication.sort();
      state.indication = action.payload.indication.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      action.payload.campaign_type.sort();
      state.campaignType = action.payload.campaign_type.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.campaignType.unshift({
        value: "",
        label: "Select",
      });
      state.biaTarget = [
        {
          value: "",
          label: "Select",
        },
        {
          value: "YES",
          label: "YES",
        },
        {
          value: "NO",
          label: "NO",
        },
      ];
      state.deviation = [
        {
          value: "",
          label: "Select",
        },
        {
          value: "YES",
          label: "YES",
        },
        {
          value: "NO",
          label: "NO",
        },
      ];
      //   this.filters.brand = formattedBrand;
      state.isFilterloading = true;
    });
    builder.addCase(listmgtFilter.rejected, (state, action) => {
      state.brand = [{ value: "", label: "Select" }];
      state.vendor = [{ value: "", label: "Select" }];
      state.indication = [{ value: "", label: "Select" }];
      state.campaignType = [{ value: "", label: "Select" }];
      state.userId = [{ value: "", label: "Select" }];
      state.biaTarget = [{ value: "", label: "Select" }];
      state.deviation = [{ value: "", label: "Select" }];

      state.isFilterloading = true;
    });
  },
});

export { listmgtFilter };

export default lmfiltersSlicer.reducer;
