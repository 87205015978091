import { useState } from 'react';
import { LdsModal, LdsTextField, LdsButton, LdsValidationError, LdsToast, useToastContext } from '@elilillyco/ux-lds-react';
import { useDispatch, useSelector } from 'react-redux'
import { addCategory } from '../../../store/Components/TACCT/BL/taactaddcategory';

const AddNewCatModal = (props) => {
    const { modalOpen, setIsAddNewCatModalOpen } = props;
    const { addToast } = useToastContext();
    const [caterrors, setCatError] = useState(null);
    const [catdeferrors, setCatDefError] = useState(null);
    const role2 = useSelector(({user}) => user.user.role_ids);
    const dispatch = useDispatch();
    
    const toastConfig = (errTxtMsg, msgType) =>  {
        return {
            toastMessage: errTxtMsg,
            actionText: "",
            actionCallback: () => {},
            variant: msgType,
            position: "top",
            align: "center",
            dismissible: true,
            light: false,
            timeout: 5000,
            inline: false,
            autoDismiss: true,
        }
    };
    const getUserID = () => {
        let currentRole = sessionStorage.getItem('role');
        let currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
        let userRole = currentUserData[0][currentRole];
        return userRole;
    };

    const makesubmitapicall = async (val1, val2) => {
        let resultdata = await dispatch(addCategory({"metadata":{"name":val1,"definition":val2,"created_by":getUserID()}}));

        if(resultdata.payload.statusCode === 200 && resultdata.payload.statusMessage === 'Already Exists') {
            addToast(toastConfig("Already Exist.", "error"));
            return false;
        }
        else {
            addToast(toastConfig("Your changes have been saved successfully.", "success"));
            setIsAddNewCatModalOpen(false);
            document.getElementById('category').value = '';
            document.getElementById('categoryopdef').value = '';
        }
    }

    const HandleAddNewCatFormSubmit = (e) => {
        // Prepare the payload
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        let cat = formData.get('category').trim();
        let catopdef = formData.get('categoryopdef').trim();
        let formerror = false;
        if ( cat === '' || catopdef === '' ) {
            addToast(toastConfig("Please add both the fields!", "error"));
            formerror = true;
            return;
        }

        if ( cat.match(/[^a-zA-Z0-9\s_-]/) || catopdef.match(/[^a-zA-Z0-9\s_-]/) ) {
            addToast(toastConfig("Special characters are not allowed.", "error"));
            formerror = true;
            return;
        }
        if(!formerror) {
            makesubmitapicall(cat, catopdef);
        }
    }
    const checkRules = (e) => {
        e.target.value = e.target.value.toUpperCase();
        const targetID = e.target.id;
        if(!/^[a-zA-Z0-9\s_-]+$/g.test(e.target.value)) {
            (targetID === 'category') ? setCatError("Invalid Format") : setCatDefError("Invalid Format");
        }
        else {
            (targetID === 'category') ? setCatError(null) : setCatDefError(null);
        }
    }
    
    return (
        <div class="add-cat-modal">
            <LdsModal
                modalId="AddNewCatModal"
                open={modalOpen}
                setModalOpen={setIsAddNewCatModalOpen}
                heading="Add Category"
                persistent
            >
                <form onSubmit={HandleAddNewCatFormSubmit}>  
                    <div align='center'>
                        <div class="col-8 col-md-6 lds-form-field row-field">
                            <div>
                                <LdsTextField
                                id='category'
                                name='category'
                                label='Category'
                                error={
                                    caterrors
                                    && true
                                }
                                onChange={(e) => checkRules(e)}
                                spellCheck="false" autoComplete="off" aria-autocomplete="none"
                                />
                                {
                                    caterrors
                                    && <LdsValidationError id="errCategory">{caterrors}</LdsValidationError>
                                }
                            </div>
                        </div>
                        <div class="col-8 col-md-6 lds-form-field row-field">
                            <div>
                                <LdsTextField
                                id='categoryopdef'
                                name='categoryopdef'
                                label='Category Operational Definition'
                                onChange={(e) => checkRules(e)}
                                spellCheck="false" autoComplete="off" aria-autocomplete="none"
                                error={
                                    catdeferrors
                                    && true
                                }
                                />
                                {
                                    catdeferrors
                                    && <LdsValidationError id="errCategoryOpDef">{catdeferrors}</LdsValidationError>
                                }
                            </div>
                        </div>
                        <div class="col-8 col-md-6 lds-form-field row-field p-2">
                            <div>
                                <LdsButton className="cancelBtn" onClick={() => setIsAddNewCatModalOpen(false)}>Cancel</LdsButton>
                                <LdsButton type="submit">Add</LdsButton>
                            </div>
                        </div>
                    </div>
                
                </form>
                
            </LdsModal>
        <LdsToast />
        </div>
    );
}

export default AddNewCatModal;