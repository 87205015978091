/**
 * Get Target List Data list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default async (payload) => {
    const { data, status } = await ctx.get(`/targetlist?campaign_id=${payload.campaign_id}&is_active=${payload.is_active}`);
    return { data, status };
};
