import ctx from '../../../../plugins/axios';


export default async function getGrammeticalbyID (id) {
  try {
    const { data, status } = await ctx.get(`/edit-gfattributes?grammatical_id=${id}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
