import ctx from '../../../plugins/axios';

export async function addMetadata(payload) {
  try {
    const { data, status } = await ctx.post('/sourcemetadata', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
