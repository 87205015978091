import ctx from '../../../plugins/axios';

export async function getMetadata(payload) {
  try {
    const { data, status } = await ctx.get(`/get-metadata?attribute_type=${payload}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export async function addEditMetadata(payload) {
  const type = payload?.type?.[0]
  try {
    const { data, status } = await type === "edit" ?  ctx.put('/sourcemetadata', payload) : ctx.post('/sourcemetadata', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
