import ctx from '../../../plugins/axios';

export default async (payload, currentPage) => {
  try {
    const { data, status } = await ctx.get(`/cap-overview?pageNumber=${payload?.page || 1}&pageSize=8&delivery_source=${payload?.delivery_source || '' }&platform=${payload?.platform || ''}&verso_tactic_id=${payload?.verso_tactic_id || ''}&verso_campaign_id=${payload?.verso_campaign_id || ''}&tactic_start_date=${payload?.tactic_start_date || ''}&tactic_end_date=&product=&department=&disease_state_indication=`);    
    return { data, status };
  } catch (error) {
    return { error };
  }
};
