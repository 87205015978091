import {
  LdsButton,
  LdsLoadingSpinner,
  LdsSearch,
  LdsSelect,
  useLdsModal,
} from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkList,
  fileUploadedSetVender,
  findSelectedDropDown,
  getAlignPairData,
  getCampaign,
  getFilters,
  setAdhocLovsClear,
} from "../../store/adhocScrubbing/adhocScrubbing";
import AdhocTabRules from "./adhocTabRules";
import data from "./data.json";
import "../../assets/components/adhocListScrubbing/adhocListScrubbing.scss";
import AdhocLsModal from "./adhocLsModal";
import FileUpload from "./fileUpload";
import Select from "react-select";

export default function AdhocListScrubbing() {
  const {
    brand,
    vendor,
    indication,
    brand_target_segment,
    behavioral_segment,
    listTypes,
    campaignList,
    campaignDataResult,
    loading,
    submitLoader,
    campaignLoader,
    isPairListLoad,
  } = useSelector((store) => store.filters);
  const [dropDownCheckedValues, setDropDownCheckedValues] = useState([]);
  const [vendorValue, setVendorValue] = useState("");
  const [listType, setListType] = useState("");
  const [selectedList, setselectedList] = useState("");
  const dispatch = useDispatch();

  const [optionlist, setOptionList] = useState(listTypes);
  const [selectedlistValue, setSelectedListValue] = useState("");

  const [selectedBrandValue, setSelectedBrandValue] = useState([]);
  const [selectedIndicationValue, setSelectedIndicationValue] = useState([]);
  const [selectedBehavioralSegmentValue, setSelectedBehavioralSegmentValue] =
    useState([]);
  const [selectedBrandSegmentValue, setSelectedBrandSegmentValue] = useState(
    []
  );
  const [optionalSuppressRules, setOptionalSupressionRules] = useState([]);

  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [isIndicationSelected, setIsIndicationSelected] = useState(false);

  const[isFileAdded , setIsFileAdded] = useState(true);

  const [selected, setSelected] = useState({
    brand: [],
    indication: [],
    vendor: "",
    listType: "",
    behavioral_segment: [],
    brand_segment: [],
    optionalList: [],
    mandatoryList: [
      "kaiser",
      "brand_approved_specialty_adhoc",
      "corporate_opt_out",
      "active_hcp",
      "privacy_status",
      "field_wvu_status",
    ],
    userRole: "",
    initialTargetListCount: 0,
    file: null,
    isFileSelected: false,
  });

  const [dropdown, setDropDown] = useState({
    brand: true,
    indication: true,
    brandSegment: true,
    behavioralSegment: true,
  });

  //search option
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const { isModalOpen: isModalOpened, setIsModalOpen: setIsModalOpened } =
    useLdsModal();

  //file upload variables
  const { isModalOpen: isModal, setIsModalOpen: setIsModal } = useLdsModal();
  const [fileData, setFileData] = useState({
    file: null,
    isFileSelected: false,
  });
  const [fileName, setFileName] = useState(null);
  const [isFileDisableState, setIsFileDisableState] = useState(false);

  useEffect(() => {
    dispatch(getFilters());
  }, [dispatch]);

  const selectedVendor = (e) => {
    setVendorValue(e.value);
    if (
      e.value === "LILLY" ||
      e.value === "DIRECT MAIL – HIBBERT" ||
      e.value === "DIRECT MAIL – SOA"
    ) {
      setOptionList([
        { value: "execution_list", label: "EXECUTION" },
        { value: "", label: "Select" },
      ]);
      setselectedList("execution_list");
    } else if (e.value === "SOA" || e.value === "EHR") {
      setOptionList([
        { value: "", label: "Select" },
        { value: "execution_list", label: "EXECUTION" },
        { value: "contracting_list", label: "CONTRACTING" },
      ]);
    }

    if (
      e.value === "DIRECT MAIL – HIBBERT" ||
      e.value === "DIRECT MAIL – SOA"
    ) {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.directMailExecutionList);
    } else if (e.value === "LILLY") {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.lillyExecutionList);
    } else if (e.value === "SOA" && listType === "") {
      setSelectedListValue("");
      setDropDownCheckedValues([
        ...data.soaContractingList,
        ...data.soaExecutionList,
      ]);
    } else if (e.value === "EHR" && listType === "") {
      setSelectedListValue("");
      setDropDownCheckedValues([
        ...data.ehrExecutionList,
        ...data.ehrContractingList,
      ]);
    } else if (e.value === "EHR" && listType === "execution_list") {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.ehrExecutionList);
    } else if (e.value === "EHR" && listType === "contracting_list") {
      setSelectedListValue("contracting_list");
      setDropDownCheckedValues(data.ehrContractingList);
    } else if (e.value === "SOA" && listType === "execution_list") {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.soaExecutionList);
    } else if (e.value === "SOA" && listType === "contracting_list") {
      setSelectedListValue("contracting_list");
      setDropDownCheckedValues(data.soaContractingList);
    }
    if (e.value === "Market Research") {
      setSelectedListValue("");
      setDropDownCheckedValues([]);
    }
  };
  const renderErrorMessage = (message) => {
    if (message) {
      return <p className="error">{message}</p>;
    }
    return null;
  };
  function listTypeFUN(e) {
    setListType(e.value);
    if (vendorValue === "EHR" && e.value === "execution_list") {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.ehrExecutionList);
    } else if (vendorValue === "EHR" && e.value === "contracting_list") {
      setSelectedListValue("contracting_list");
      setDropDownCheckedValues(data.ehrContractingList);
    } else if (vendorValue === "SOA" && e.value === "execution_list") {
      setSelectedListValue("execution_list");
      setDropDownCheckedValues(data.soaExecutionList);
    } else if (vendorValue === "SOA" && e.value === "contracting_list") {
      setSelectedListValue("contracting_list");
      setDropDownCheckedValues(data.soaContractingList);
    }
  }

  function selectedValues(data) {
    const optionalRules = data.map((rule) => rule.name);
    setOptionalSupressionRules(optionalRules);
  }

  // search for word select
  useEffect(() => {
    // eslint-disable-next-line
    const suggestions = campaignList.filter((item) =>
      item.includes(searchTerm)
    );
    setSearchSuggestions(suggestions);
  }, [searchTerm]);

  const submitSearch = async (campaignId) => {
    if( campaignId === ''){
      return;
    }
    dispatch(setAdhocLovsClear());
    await dispatch(getCampaign(campaignId));
  };

  // values Setting after searched from campaign
  let [iscmp, setIsCmp] = useState(false);
  useEffect(() => {
    if (
      campaignDataResult.product !== "" &&
      campaignDataResult.disease_state_indication !== "" &&
      campaignDataResult.vendor !== ""
    ) {
      setIsCmp(true);
      if (brand.some((obj) => obj.value === campaignDataResult.product)) {
        setSelectedBrandValue([campaignDataResult.product]);
      }
      if (
        indication.some(
          (obj) => obj.value === campaignDataResult.disease_state_indication
        )
      ) {
        setSelectedIndicationValue([
          campaignDataResult.disease_state_indication,
        ]);
      }
      if (vendor.some((obj) => obj.value === campaignDataResult.vendor)) {
        selectedVendor({
          value: campaignDataResult.vendor,
        });
      }
    }
  }, [campaignDataResult]);

  //list dropdown
  const ListTypeDropdown = ({ type, list }) => {
    if (
      type === "LILLY" ||
      type === "DIRECT MAIL – HIBBERT" ||
      type === "DIRECT MAIL – SOA"
    ) {
      return (
        <>
          <LdsSelect
            errorMessage="This field is invalid"
            id="listTypes"
            name="listType"
            disabled={campaignLoader || isFileDisableState}
            required={false}
            onChange={listTypeFUN}
            options={optionlist}
            value={selectedList}
          />
        </>
      );
    }
    return (
      <>
        <LdsSelect
          errorMessage="This field is invalid"
          id="listType"
          name="listType"
          disabled={campaignLoader || isFileDisableState}
          required={false}
          onChange={listTypeFUN}
          options={optionlist}
          value={list}
        />
      </>
    );
  };

  async function finalSubmit() {
    if (
      selectedBrandValue.length === 0 &&
      selectedIndicationValue.length === 0
    ) {
      // write code when field is not selected
      setIsBrandSelected(true);
      setIsIndicationSelected(true);
      return;
    } else if (selectedBrandValue.length === 0) {
      // write code when brand field is not selected
      setIsBrandSelected(true);
      return;
    } else if (selectedIndicationValue.length === 0) {
      // write code when indication field is not selected
      setIsIndicationSelected(true);
      return;
    }
    selected.brand = selectedBrandValue;
    selected.indication = selectedIndicationValue;

    selected.vendor = vendorValue;
    selected.listType = selectedlistValue;

    selected.behavioral_segment = selectedBehavioralSegmentValue;
    selected.brand_segment = selectedBrandSegmentValue;

    selected.optionalList = optionalSuppressRules;
    if (isFileAdded){
      await dispatch(checkList(selected));
    }
    setIsModalOpened(true);
  }

  function clearFilters() {
    setSelectedBrandValue([]);
    setSelectedIndicationValue([]);
    setOptionList([
      { value: "", label: "Select" },
      { value: "execution_list", label: "EXECUTION" },
      { value: "contracting_list", label: "CONTRACTING" },
    ]);
    setListType("");
    setselectedList("");
    setSelectedListValue("");
    setVendorValue("");
    setSelectedBehavioralSegmentValue([]);
    setSelectedBrandSegmentValue([]);
    setSearchTerm("");
    setDropDownCheckedValues([]);
    setFileName(null);
    setFileData(null);
    setIsFileDisableState(false);
    setIsFileAdded(true);
    selected.brand = [];
    selected.indication = [];
    selected.vendor = "";
    selected.listType = "";
    selected.behavioral_segment = [];
    selected.brand_segment = [];
    selected.optionalList = [];
    selected.file = null;
    selected.isFileSelected = false;
    dispatch(
      findSelectedDropDown({
        brand: true,
        indication: true,
        brandSegment: true,
        behavioralSegment: true,
      })
    );
    dispatch(fileUploadedSetVender({ isFileSelected: false }));
    dispatch(setAdhocLovsClear());
  }
  //cascading
  useEffect(() => {
    selected.brand = selectedBrandValue;
    selected.indication = selectedIndicationValue;
    selected.behavioral_segment = selectedBehavioralSegmentValue;
    selected.brand_segment = selectedBrandSegmentValue;
    if (
      selected.brand.length === 0 &&
      selected.indication.length === 0 &&
      selected.behavioral_segment.length === 0 &&
      selected.brand_segment.length === 0
    ) {
      return;
    }
    if (iscmp) {
      setIsCmp(false);
      return;
    }
    dispatch(findSelectedDropDown(dropdown));
    dispatch(getAlignPairData(selected));
  }, [
    selectedBrandValue,
    selectedIndicationValue,
    selectedBehavioralSegmentValue,
    selectedBrandSegmentValue,
  ]);

  const uploadFile = async (uploadedFileData, uploadedFileName) => {
    setIsFileDisableState(true);
    selected.listType = "";
    selected.behavioral_segment = [];
    selected.brand_segment = [];
    setSearchTerm("");
    setListType("");
    setselectedList("");
    setSelectedListValue("");
    setSelectedBehavioralSegmentValue([]);
    setSelectedBrandSegmentValue([]);
    selected.file = uploadedFileData;
    selected.isFileSelected = true;
    setFileName(uploadedFileName.name);
    await dispatch(fileUploadedSetVender({ isFileSelected: true }));
    setVendorValue("Market Research");
    selectedVendor({ value: "Market Research" });
    selected.vendor = "Market Research";
    setIsFileAdded(false);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="row mb-3 mt-4 w-50">
            <div className="col-4">
              <label>Campaign ID</label>
              <LdsSearch
                id={"searchAutoSuggest"}
                placeholder="Search for Campaign ID"
                disabled={campaignLoader || isFileDisableState}
                inputAriaLabel={null}
                isTypeAhead={true}
                onChange={(e) => {
                  setSearchTerm(e.toUpperCase());
                }}
                onSubmit={(e) => {
                  submitSearch(e);
                }}
                searchSuggestions={searchSuggestions}
                value={searchTerm}
              />
            </div>
            <div className="col-2 mt-4">
              <LdsButton onClick={clearFilters} disabled={campaignLoader}>
                Clear
              </LdsButton>
            </div>
            {fileName && (
              <div className="col-4 mt-4">
                <div>
                  <span>Uploaded File</span>
                  <div className="file-name-home">{fileName}</div>
                </div>
              </div>
            )}
            <div
              className={
                isFileDisableState
                  ? "col-2 mt-4 "
                  : "col-2 mt-4 file-upload-button"
              }>
              <LdsButton
                onClick={() => setIsModal(true)}
                disabled={campaignLoader}>
                File Upload
              </LdsButton>
            </div>
          </div>
          <div className="row mb-3 mt-4">
            <div className="col-2">
              {/* <LdsSelect
                errorMessage="This field is invalid"
                id="brand"
                label="Brand"
                name="brand"
                disabled={campaignLoader}
                error={isBrandSelected}
                onChange={(e) => {
                  setIsBrandSelected(false);
                  setSelectedBrandValue(e.value);
                }}
                options={brand}
                value={selectedBrandValue}
              /> */}
              <label className="ml-font">Brand</label>
              <Select
                id="brand"
                name="brand"
                placeholder = "Select"
                isDisabled={campaignLoader}
                isMulti
                options={brand}
                onChange={(e) => {
                  setDropDown({
                    brand: false,
                    indication: true,
                    brandSegment: true,
                    behavioralSegment: true,
                  });
                  const values = e.map((arr) => arr.value);
                  setIsBrandSelected(false);
                  setSelectedBrandValue(values);
                }}
                value={[...selectedBrandValue].map((option) => ({
                  value: option.toString(),
                  label: option.toString(),
                }))}
                styles={{
                  control: (provided) => ({
                   ...provided,
                    backgroundColor: isBrandSelected? '#FEECEF' : provided.backgroundColor,
                    borderColor: isBrandSelected? '#FEECEF' : provided.borderColor,
                    border: '1px solid #9E9E9E',
                    boxShadow: 'none!important',
                    "&:hover":{
                      borderColor:"#black"
                     }
                     
                  }),
                  option: (provided, state) => ({
                    ...provided,
                     backgroundColor: state.isFocused || state.hovered||state.isSelected? '#F1F1F1' : null,
                     "&:active":{
                      backgroundColor:"#e6e6e6"
                     }
                     
                  })
                }}
              />
                 {renderErrorMessage(isBrandSelected? "This field is invalid" : null)}
            </div>
            <div className="col-2">
              {/* <LdsSelect
                errorMessage="This field is invalid"
                id="indication"
                label="Indication"
                name="indication"
                disabled={campaignLoader}
                required={true}
                error={isIndicationSelected}
                onChange={(e) => {
                  setIsIndicationSelected(false);
                  setSelectedIndicationValue(e.value);
                }}
                options={indication}
                value={selectedIndicationValue}
              /> */}
              <label className="ml-font">Indication</label>
              <Select
                id="indication"
                name="indication"
                placeholder = "Select"
                isDisabled={campaignLoader}
                isMulti
                options={indication}
                onChange={(e) => {
                  const values = e.map((arr) => arr.value);
                  setDropDown({
                    brand: true,
                    indication: false,
                    brandSegment: true,
                    behavioralSegment: true,
                  });
                  setIsIndicationSelected(false);
                  setSelectedIndicationValue(values);
                }}
                value={[...selectedIndicationValue].map((option) => ({
                  value: option.toString(),
                  label: option.toString(),
                }))}
                styles={{
                  control: (provided) => ({
                   ...provided,
                    backgroundColor: isIndicationSelected? '#FEECEF' : provided.backgroundColor,
                    borderColor: isIndicationSelected? '#FEECEF' : provided.borderColor,
                    border: '1px solid #9E9E9E',
                    boxShadow: 'none!important',
                    "&:hover":{
                      borderColor:"#black"
                     }
                  }),
                  option: (provided, state) => ({
                    ...provided,
                     backgroundColor: state.isFocused || state.hovered||state.isSelected? '#F1F1F1' : null,
                     "&:active":{
                      backgroundColor:"#e6e6e6"
                     }
                     
                  }),
                }}
              />
                {renderErrorMessage(isIndicationSelected? "This field is invalid" : null)}
            </div>
            <div className="col-2">
            <label className="ml-font">Vendor</label>
              <LdsSelect
                errorMessage="This field is invalid"
                id="vendor"
                name="vendor"
                disabled={campaignLoader}
                onChange={selectedVendor}
                options={vendor}
                value={vendorValue}
              />
            </div>
            <div className="col-2">
            <label className="ml-font">List Type</label>
              <ListTypeDropdown type={vendorValue} list={listType} />
            </div>
            <div className="col-2">
              {/* <LdsSelect
                errorMessage="This field is invalid"
                id="Behavioral"
                label="Behavioral Segment"
                name="behavioral_segment"
                disabled={campaignLoader || isFileDisableState}
                required={true}
                onChange={(e) => setSelectedBehavioralSegmentValue(e.value)}
                options={behavioral_segment}
                value={selectedBehavioralSegmentValue}
              /> */}
              <label className="ml-font">Behavioral Segment</label>
              <Select
                id="Behavioral"
                name="behavioral_segment"
                placeholder = "Select"
                isDisabled={campaignLoader || isFileDisableState}
                isMulti
                options={behavioral_segment}
                onChange={(e) => {
                  setDropDown({
                    brand: true,
                    indication: true,
                    brandSegment: true,
                    behavioralSegment: false,
                  });
                  const values = e.map((arr) => arr.value);
                  setSelectedBehavioralSegmentValue(values);
                }}
                value={[...selectedBehavioralSegmentValue].map((option) => ({
                  value: option.toString(),
                  label: option.toString(),
                }))}
                styles={{
                  control: (provided) => ({
                   ...provided,
                    border: '1px solid #9E9E9E',
                    boxShadow: 'none!important',
                    "&:hover":{
                      borderColor:"#black"
                     }
                  }),
                  option: (provided, state) => ({
                    ...provided,
                     backgroundColor: state.isFocused || state.hovered||state.isSelected? '#e6e6e6' : null,
                     "&:active":{
                      backgroundColor:"#e6e6e6"
                     } 
                  }),
                }}
              />
            </div>
            <div className="col-2">
              {/* <LdsSelect
                errorMessage="This field is invalid"
                id="brandseg"
                label="Brand Segment"
                name="brand_segment"
                disabled={campaignLoader || isFileDisableState}
                required={true}
                onChange={(e) => setSelectedBrandSegmentValue(e.value)}
                options={brand_target_segment}
                value={selectedBrandSegmentValue}
              /> */}
              <label className="ml-font">Brand Segment</label>
              <Select
                id="brandseg"
                name="brand_segment"
                placeholder = "Select"
                isDisabled={campaignLoader || isFileDisableState}
                isMulti
                options={brand_target_segment}
                onChange={(e) => {
                  setDropDown({
                    brand: true,
                    indication: true,
                    brandSegment: false,
                    behavioralSegment: true,
                  });
                  const values = e.map((arr) => arr.value);
                  setSelectedBrandSegmentValue(values);
                }}
                value={[...selectedBrandSegmentValue].map((option) => ({
                  value: option.toString(),
                  label: option.toString(),
                }))}
                styles={{
                  control: (provided) => ({
                   ...provided,
                    border: '1px solid #9E9E9E',
                    boxShadow: 'none!important',
                    "&:hover":{
                      borderColor:"#black"
                     }
                  }),
                  option: (provided, state) => ({
                    ...provided,
                     backgroundColor: state.isFocused || state.hovered||state.isSelected? '#e6e6e6' : null,
                     "&:active":{
                      backgroundColor:"#e6e6e6"
                     } 
                  }),
                }}
              />
            </div>
          </div>
          <div className="discribe mb-3">
            <strong>Note : </strong>Optional Suppression rules would be checked
            on the basis of Vendor and List Type.
          </div>
          <div>
            <AdhocTabRules
              selectedValues={selectedValues}
              checkedValues={dropDownCheckedValues}
              isDisabled={campaignLoader}
            />
            <div className="ta-end mr-1 mb-3">
              <LdsButton onClick={finalSubmit} disabled={campaignLoader|| isBrandSelected || isIndicationSelected}>
                {submitLoader ? (
                  <div className="subLoader">
                    {" "}
                    <LdsLoadingSpinner size={23} />{" "}
                  </div>
                ) : (
                  "Submit Request"
                )}
              </LdsButton>
            </div>
          </div>
          <div>
            {
              <AdhocLsModal
                setIsModalOpen={setIsModalOpened}
                isModalOpen={isModalOpened}
                selectedData={selected}
                isFileAdded={isFileAdded}
              />
            }
          </div>
          <div>
            {
            // isModal &&             
            <FileUpload
              isModal={isModal}
              setIsModal={setIsModal}
              uploadFile={uploadFile}
              dispatch={dispatch}
              LdsLoadingSpinner={LdsLoadingSpinner}
            />}
          </div>
        </>
      ) : (
        <div className="ta-center p-3 m-4">
          <LdsLoadingSpinner size={50} />
        </div>
      )}
    </>
  );
}
