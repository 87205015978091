import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { blArchiveValues } from '../../../../api/actions/TxmyMgmt/tacctBlOverview/blArchive';

export const initialState = {
  apiresponse : [],
  pageLoader: false,
};

const blArchive = createAsyncThunk('/bl-archive', async (postdata) => {
  const response = await blArchiveValues(postdata);
  return response.data;
});

const blArchiveSlice = createSlice({
  name: 'addNewCategory',
  initialState,
  extraReducers(builder) {
    builder.addCase(blArchive.fulfilled, (state, action) => {
      state.apiresponse = action.payload;
      state.pageLoader = false;
    });
    builder.addCase(blArchive.pending, (state, action) => {
      state.pageLoader = true;
    });
    builder.addCase(blArchive.rejected, (state, action) => {
      state.apiresponse = action.payload;
      state.pageLoader = false;
    });
  },
});

export { blArchive };

export default blArchiveSlice.reducer;