import ctx from '../../../../plugins/axios';


export  async function getProduct (queryparam) {
    try {
      const { data, status } = await ctx.get(`/fetchproducts?group_purpose_code=${queryparam}`);
      return { data, status };
    } catch (error) {
      return { error };
    }
  };



