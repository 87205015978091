import { LdsModal, LdsTable } from "@elilillyco/ux-lds-react";

export default function TargetListModal({
  isFinalCoundModal,
  setIsFinalCountModal,
  targetListTableData,
}) {
  let header = Object.keys(targetListTableData[0]).map((x) =>
    x === "initial_hcp_count"
      ? "HCP COUNT"
      : x.replaceAll("_", " ").toString().toUpperCase()
  );
  return (
    <>
      <div>
        <LdsModal
          modalId="testModal"
          open={isFinalCoundModal}
          modalSizeClass="col-12 col-md-12"
          setModalOpen={setIsFinalCountModal}>
          <LdsTable id="existingListTable">
            <thead>
              <tr role="row">
                {header.map((arr) => {
                  return <th key={arr}>{arr}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {targetListTableData.map((arr, index) => {
                return (
                  <tr role="row" key={index}>
                    <th role="rowheader" scope="row" className="align-center">
                      {arr.bia_drop_outbound}
                    </th>
                    <th role="rowheader" scope="row" className="align-center">
                      {arr.initial_hcp_count}
                    </th>
                    <th role="rowheader" scope="row" className="align-center">
                      {arr.reason_for_not_generated}
                    </th>
                    <th role="rowheader" scope="row" className="align-center">
                      {arr.is_active}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </LdsTable>
        </LdsModal>
      </div>
    </>
  );
}
