// import { handleError, clearError } from '../utils';
import { getCatOpDefinition } from "../../../../api/actions/TxmyMgmt/addSubCategory/getCatOpDefinition"; 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  Definition: [],
  loading: false
};



const fetchCatOpDef = createAsyncThunk(
  "catOpDef/fetchCatOpDef",
  async (queryParams) => {
    const response = await getCatOpDefinition(queryParams);
    return response.data;
  }
);

const tacctCatOpDef = createSlice({
  name: "overview",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchCatOpDef.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCatOpDef.fulfilled, (state, action) => {
        state.Definition = action.payload.data;
        state.loading = false;
    });
    builder.addCase(fetchCatOpDef.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { fetchCatOpDef };
export default tacctCatOpDef.reducer;
