// import { handleError, clearError } from '../utils';
import { putBuApproval } from "../../../../api/actions/TxmyMgmt/tacct_BU_overview";
import { createAsyncThunk } from "@reduxjs/toolkit";



// const initialState = {
//   getApprovals: [],
//   totalPages: 0,
// };



const UPDATE_APPROVAL_DATA = createAsyncThunk(
  "overview/updateBuApproval",
  async (payload) => {
    const response = await putBuApproval(payload);
    return response.data;
  }
);



export { UPDATE_APPROVAL_DATA };
