import React from 'react'
import maintananceImage from "../assets/images/maintanance.png"
// import { maintananceImage } from "../assets/images/maintanace.png";

const Maintanance = () => {
  return (
    <div 
        style={{
           display: 'flex',
           flexDirection:'column',
           justifyContent:'center',
           alignItems:'center',
           marginTop:'5vw',
           gap:'2rem'
        }}
    >
        <h1>Maintenance in Progress</h1>
        <img style={{width:'30rem', height:'20rem'}} src={maintananceImage} alt='Maintenance' />
        <h3>We are currently performing maintenance on our website. We should be back shortly.</h3>
    </div>
  )
}

export default Maintanance