import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getRmCapData,
  rmCapFilters,
  rmModalData,
} from "../../api/actions/responseMatrix";

const initialState = {
  approvedJobId: [],
  campaignId: [],
  department: [],
  indication: [],
  product: [],
  tacticId: [],
  isRmLoading: false,
  isStatus: false,
  rmTableData: [],
  isTableLoading: false,
  pageCount: 0,
  tacticData: [],
  tacticModalTableData: [],
  pageNo: 1,
  capfilter: {},
  isSelectLoading: false,
  tableApiStatus: false,
  selectedBrand: "",
};

function setRmlovs(state, action) {
  action.payload.data.data.approved_job_id.sort();
  state.approvedJobId = action.payload.data.data.approved_job_id;

  action.payload.data.data.campaign_id.sort();
  state.campaignId = action.payload.data.data.campaign_id;
  state.department = [
    {
      value: "",
      label: "Select",
    },
    {
      value: "MEDICAL",
      label: "MEDICAL",
    },
    {
      value: "RETAIL",
      label: "RETAIL",
    },
  ];
  action.payload.data.data.indication.sort();
  state.indication = action.payload.data.data.indication.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.indication.unshift({
    value: "",
    label: "Select",
  });

  action.payload.data.data.tactic_id.sort();
  state.tacticId = action.payload.data.data.tactic_id.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.tacticId.unshift({
    value: "",
    label: "Select",
  });
  const brand = action.payload.data.data.brand;
  if (brand.length === 1 && brand[0] === state.selectedBrand) {
    // state.product = state.product;
    return;
  } else {
    action.payload.data.data.brand.sort();
    state.product = action.payload.data.data.brand.map((option) => ({
      value: option.toString(),
      label: option.toString(),
    }));
    state.product.unshift({
      value: "",
      label: "Select",
    });
  }
}

const getRmCapLovs = createAsyncThunk("FETCH_RM_CAP_LOVS", async (payload) => {
  const response = await rmCapFilters(payload);
  return response;
});

const getRmTableData = createAsyncThunk("rm/capTableList", async (obj) => {
  const response = await getRmCapData(obj);
  return response;
});

const getRmModalData = createAsyncThunk("rm/rmModal", async (obj) => {
  const response = await rmModalData(obj);
  return response;
});

const rmSlicer = createSlice({
  name: "Response-Matrix",
  initialState,
  reducers: {
    setBrandValue(state, action) {
      state.selectedBrand = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRmCapLovs.pending, (state, action) => {
      state.approvedJobId = [];
      state.campaignId = [];
      state.department.unshift({
        value: "",
        label: "Select",
      });
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      state.product.unshift({
        value: "",
        label: "Select",
      });
      let jsonObject = state.product.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      state.product = Array.from(uniqueSet).map(JSON.parse);
      state.tacticId.unshift({
        value: "",
        label: "Select",
      });
      state.isRmLoading = true;
      state.isStatus = true;
    });
    builder.addCase(getRmCapLovs.fulfilled, (state, action) => {
      const { brand, indication } = action.payload.data.data;
      if (brand.length === 0 && indication.length === 0) {
        state.isRmLoading = false;
        state.isStatus = false;
        return;
      }
      setRmlovs(state, action);

      state.isRmLoading = false;
      state.isStatus = false;
    });
    builder.addCase(getRmCapLovs.rejected, (state, action) => {
      state.approvedJobId = [];
      state.campaignId = [];
      state.department.unshift({
        value: "",
        label: "Select",
      });
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      state.product.unshift({
        value: "",
        label: "Select",
      });
      state.tacticId.unshift({
        value: "",
        label: "Select",
      });
      state.isRmLoading = true;
      state.isStatus = false;
    });

    builder.addCase(getRmTableData.pending, (state, action) => {
      state.tableApiStatus = true;
      state.isTableLoading = false;
      state.isStatus = true;
    });
    builder.addCase(getRmTableData.fulfilled, (state, action) => {
      state.rmTableData = action.payload.data.data;
      state.pageCount = action.payload.data.pages;
      state.tableApiStatus = true;
      state.isTableLoading = true;
      state.isStatus = false;
    });
    builder.addCase(getRmTableData.rejected, (state, action) => {
      state.isTableLoading = false;
      state.isStatus = false;
      state.tableApiStatus = false;
    });

    builder.addCase(getRmModalData.pending, (state, action) => {});
    builder.addCase(getRmModalData.fulfilled, (state, action) => {
      state.tacticModalTableData = action.payload.data.data;
      state.pageNo = action.payload.data.pages;
    });
    builder.addCase(getRmModalData.rejected, (state, action) => {});
  },
});

export { getRmCapLovs, getRmTableData, getRmModalData };
export const { setBrandValue } = rmSlicer.actions;
export default rmSlicer.reducer;