import { mkConfig, generateCsv, download } from "export-to-csv";

const ExportExcelSheet = (data, csvname, validateUnderScore = true) => {
  if(validateUnderScore){
    data = data.map((row) => {
      const temp = {};
      for (let key in row) {
        temp[key.replaceAll("_", " ").toUpperCase()] = row[key];
      }
      return temp;
    });
  }
  // Define your custom options
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    // title: '',
    useTextFile: false,
    useBom: true,
    filename: csvname,
    useKeysAsHeaders: true,
  };

  // Create the CSV configuration by merging your options with the defaults
  const csvConfig = mkConfig(options);

  // Generate the CSV string from your data using the configuration
  const csv = generateCsv(csvConfig)(data);

  // Download the CSV file using the generated CSV string and the configuration
  download(csvConfig)(csv);
};

export default ExportExcelSheet;
