import React from 'react';
import "../assets/components/HeaderNav.scss"

export default function HeaderNav({msg}) {
  return (
    <div className='header home-page-header'>
        <span>{msg}</span>
    </div>
  )
}

