import React, { useEffect } from "react";
import {
  LdsTable,
  LdsPagination,
  LdsLink,
  LdsLoadingSpinner,
  LdsCard,
  useLdsModal,
} from "@elilillyco/ux-lds-react";
import { useState } from "react";
import "../../assets/components/list-management-auditing/auditTrialTable.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTargetListData,
  listAuditmodalData,
} from "../../store/Components/listmgt/auditTrailTable";
import TargetListModal from "./targetListModal";
import ListDataModal from "./listDataModal";
import ListDataGraph from "./ListDataGraph";
import LdsSortableTable from "../../data/utils/SortableTable";

export default function Audittable({
  auditTableData,
  pagination,
  isauditTableloading,
  pageNo,
  dataCounts,
  selectedFilterData,
}) {
  const dispatch = useDispatch();
  const {
    isModalOpen: isFinalCoundModal,
    setIsModalOpen: setIsFinalCountModal,
  } = useLdsModal();
  const { isModalOpen: islistModal, setIsModalOpen: setIslistModal } =
    useLdsModal();
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    targetListTableData,
    listModalDataList,
    listDataModallistTypeLov,
    listDataModallistReceiver,
    execution,
    contracting,
    isAuditModal,
  } = useSelector(({ getTableData }) => getTableData);
  useEffect(() => {
    setCurrentPage(selectedFilterData);
  }, [selectedFilterData]);
  function clickLink(e, value) {
    pagination(value);
  }
  function clickNext() {
    pagination(currentPage + 1);
  }
  function clickPrev() {
    pagination(currentPage - 1);
  }
  async function targetListModalData(obj) {
    await dispatch(getTargetListData(obj));
    setIsFinalCountModal(true);
  }
  async function listDataModaldetails(cmpId) {
    setSelectedCampaignId(cmpId);
    const selectedDetails = {
      campaign_id: cmpId,
      list_type: "",
      receiver: "",
      search: "",
      start_date: "",
      end_date: "",
    };
    await dispatch(listAuditmodalData(selectedDetails));
    setIslistModal(true);
  }

  const [tableRowData, setTableRowData] = useState(auditTableData);
  useEffect(()=>{
    setTableRowData(auditTableData);
  },[auditTableData]);

  function sortedRowData(sortedData){
    setTableRowData(sortedData);
  }

  return (
    <>
      <section>
        <div className="audit-table-card">
          {isauditTableloading ? (
            // <div>
            auditTableData.length > 0 ? (
              <div>
                {dataCounts && (
                  <div className="mb-4">
                    <ListDataGraph dataCounts={dataCounts} />
                  </div>
                )}
                <LdsTable id="auditTable">
                  <LdsSortableTable
                    headAlign={'align-left b'}
                    header={['Campaign Id','Campaign Description','Campaign Start Date','Campaign End Date','Brand','Indication','Campaign Type','Vendor','BIA Target List']}
                    rowData={auditTableData}
                    rowKeyName={['campaign_id','campaign_description','campaign_start_date','campaign_end_date','brand','indication','campaign_type','vendor','TargetList']}
                    sortedRowData={sortedRowData}
                  />

                  <tbody>
                    {tableRowData.map((entry, rowIndex) => (
                      <tr
                        role="row"
                        key={rowIndex}
                        className={entry.flag === true ? "highlight" : ""}>
                        <td role="cell" className="align-left" >
                          <LdsLink
                            onClick={() => {
                              listDataModaldetails(entry.campaign_id);
                            }}>
                            {entry.campaign_id}
                          </LdsLink>
                        </td>
                        <td role="cell" className="align-left" >{entry.campaign_description}</td>
                        <td role="cell" className="align-left" >{entry.campaign_start_date}</td>
                        <td role="cell" className="align-left" >{entry.campaign_end_date}</td>
                        <td role="cell" className="align-left" >{entry.brand}</td>
                        <td role="cell" className="align-left" >{entry.indication}</td>
                        <td role="cell" className="align-left" >{entry.campaign_type}</td>
                        <td role="cell" className="align-left" >{entry.vendor}</td>
                        <td role="cell" className="align-left" >
                          <LdsLink
                            onClick={() => {
                              targetListModalData({
                                campaign_id: entry.campaign_id,
                                is_active: entry.TargetList,
                              });
                            }}>
                            {entry.TargetList}
                          </LdsLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </LdsTable>
                <LdsPagination
                  currentPage={currentPage}
                  onLinkClick={clickLink}
                  onNextClick={clickNext}
                  onPrevClick={clickPrev}
                  pageRangeVisible={5}
                  totalPages={pageNo}
                  setCurrentPage={setCurrentPage}
                />
                <div>
                  {isFinalCoundModal && (
                    <TargetListModal
                      isFinalCoundModal={isFinalCoundModal}
                      setIsFinalCountModal={setIsFinalCountModal}
                      targetListTableData={targetListTableData}
                    />
                  )}
                </div>
                <div>
                  {islistModal && (
                    <ListDataModal
                      execution={execution}
                      contracting={contracting}
                      selectedCampaignId={selectedCampaignId}
                      listDataModallistReceiver={listDataModallistReceiver}
                      listDataModallistTypeLov={listDataModallistTypeLov}
                      islistModal={islistModal}
                      setIslistModal={setIslistModal}
                      listModalData={listModalDataList}
                      isAuditModal={isAuditModal}
                    />
                  )}
                </div>
              </div>
            ) : (
              <LdsCard className="ta-center p-3 m-1">
                <p className="noRecord"> No Record Found </p>
              </LdsCard>
            )
          ) : (
            // </div>
            <LdsCard className="ta-center p-3 m-4 ">
              <LdsLoadingSpinner size={50} />
            </LdsCard>
          )}
        </div>
      </section>
    </>
  );
}
