import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBuOverview } from "../../../../api/actions/TxmyMgmt/tacct_BU_overview/index";


const initialState = {
  buBody: [],
  loading: false
};

const fetchBuData = createAsyncThunk(
  "overview/getBuOverview",
  async (queryparams) => {
    const response = await getBuOverview(queryparams);
    return response.data;
  }
);

const buDataSlice = createSlice({
  name: "BUData",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchBuData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBuData.fulfilled, (state, action) => {
      state.buBody = action.payload.data;
        state.loading = false;
        // console.log('fetchBuData',fetchBuData)
    });
    builder.addCase(fetchBuData.rejected, (state, { payload }) => {
      console.error("failed to fetch  data", payload);
      state.loading = false;
    });
  },
});
export { fetchBuData };
export default buDataSlice.reducer;