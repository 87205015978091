export default function infoToastConfig({toastMessage, variant, tmOut = 3000}){
    return {
        toastMessage: toastMessage,
        actionText: "",
        variant: variant,
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: tmOut,
        inline: false,
        autoDismiss: true,
      };
}
