/**
 * Get List Mgmt  list and Modal Reports Data.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default async (campaignId) => {
    const queryStrcount = `/auditmodal?campaign_id=${campaignId.campaign_id}`;
    const queryStr = `/deviationreports?campaign_id=${campaignId.campaign_id}&list_type=${campaignId.list_type}&receiver=${campaignId.receiver}&search=${campaignId.search}&start_date=${campaignId.start_date}&end_date=${campaignId.end_date}`;
    const [modalData, modalReportsData] = await Promise.all([ctx.get(queryStrcount), ctx.get(queryStr)]);
    const { data: modalDataList, status: modalDataStatus } = modalData;
    const { data: modalReportsDataList, status: modalReportsDataStatus } = modalReportsData;
    return {
      modalDataList, modalDataStatus, modalReportsDataList, modalReportsDataStatus,
    };
};
