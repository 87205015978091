// import { handleError, clearError } from '../utils';
import {updateSubCategory} from '../../../../api/actions/TxmyMgmt/addSubCategory/updateSubCategory';

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  getCategories: [],
  totalPages: 0,
};



const updateSubCategoryData = createAsyncThunk(
  "overview/updateCategory",
  async (payload) => {
    const response = await updateSubCategory(payload);
    return response.data;
  }
);



const tacctUpdateSubCatSlice = createSlice({
  name: "updateCategory",
  initialState,
  
  extraReducers(builder) {
   
    builder.addCase(updateSubCategoryData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateSubCategoryData.fulfilled, (state, action) => {
        // state.getCategories = action.payload.data;
        // state.totalPages = action.payload.totalPages;
        state.loading = false;
    });
    builder.addCase(updateSubCategoryData.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { updateSubCategoryData };
export default tacctUpdateSubCatSlice.reducer;