import React, { useEffect, useState } from 'react';
import HeaderNav from '../../HeaderNav';
import { useDispatch } from 'react-redux';
import { LdsButton, LdsCheckbox, LdsIcon, LdsLink, LdsLoadingSpinner, LdsTable } from '@elilillyco/ux-lds-react';
import { fetchTacctData2 } from '../../../store/Components/TACCT/BL/tacctmetadata';
import { blArchiveValues } from '../../../api/actions/TxmyMgmt/tacctBlOverview/blArchive';
import { blArchive } from '../../../store/Components/TACCT/BL/tacctArchive';

const BlArchive = () => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // State to track loading state
    const displayColumns = ['product_name', 'status', 'category', 'category_definition', 'subcategory', 'subcategory_definition'];

    useEffect(() => {
        dispatch(fetchTacctData2({
            pageSize: 10,
            pageNumber: 1,
            group_purpose_code: 'THPC,DSST,CCML,BRND',
            active_flag: false
        })).then((response) => {
            const data = response?.payload?.data || [];
            setTableData(data);

            if (data.length > 0) {
                const keys = Object.keys(data[0]);
                const filteredHeaders = keys.filter(header => displayColumns.includes(header));
                setTableHeaders(filteredHeaders);
            }

            setIsLoading(false); // Set loading state to false once data is fetched
        }).catch((error) => {
            console.error('Error fetching data:', error);
            setIsLoading(false); // Set loading state to false in case of error
        });
    }, [dispatch]);

    // Function to handle individual checkbox selection
    const handleCheckboxChange = (event, id) => {
        const checked = event.target.checked;
        if (checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        }
    };

    // Function to handle "Select All" checkbox
    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            const allIds = tableData.map(item => item.blue_list_subcategory_detail_id); 
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
    };

    const Retpayload = {
        uid: selectedIds,
        active_flag: true
    }

    const retirveRec = () =>{
        dispatch(blArchive(Retpayload))
    }

    return (
        <>
            <div>
                <HeaderNav msg="Blue List Archive" />
            </div>
            <div className="p-4 mt-4">
                <LdsLink href="/tacct/blue-list">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <div className='d-flex justify-content-around'>
                <div className="">
                    <h2>Archive Overview</h2>
                </div>
                <div id='button-contianer-archive' className=''>
                    <LdsButton onClick={() => retirveRec()}>Retrieve</LdsButton>
                </div>
            </div>
            
            {isLoading ? (
                <div className="text-center mt-4">
                    <LdsLoadingSpinner size={30} animationSpeed={500} />
                </div>
            ) : (
                <div className='container-lg'>
                    <LdsTable>
                        <thead>
                            <tr>
                                <th className='ta-right'>
                                    <LdsCheckbox
                                        checked={selectedIds.length === tableData.length}
                                        onChange={handleSelectAllChange}
                                    />
                                </th>
                                {tableHeaders.map((header, index) => (
                                    <th className='ta-left' key={index} scope="col">{header.replace(/_/g, ' ').toUpperCase()}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>
                                        <LdsCheckbox
                                            checked={selectedIds.includes(item.blue_list_subcategory_detail_id)} // Replace with your actual API response key
                                            onChange={(event) => handleCheckboxChange(event, item.blue_list_subcategory_detail_id)} // Replace with your actual API response key
                                        />
                                    </td>
                                    {tableHeaders.map((header, colIndex) => (
                                        <td className='ta-left' key={colIndex} role="cell">{item[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </LdsTable>
                </div>
            )}
        </>
    );
}

export default BlArchive;