import ctx from '../../../../plugins/axios';

export async function getCatOpDefinition (queryParams) {
  try {
    const { data, status } = await ctx.get(`/bl-autocategory?category=${queryParams.selectedCategory}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
