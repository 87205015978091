import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";

export default function PieChart({ targetlistlabels, targetlist }) {
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    LinearScale
  );
  const data = {
    labels: targetlistlabels,
    datasets: [
      {
        data: targetlist,
        backgroundColor: ["#f87979", "#3E6CA4"],
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <>
      <Pie options={options} data={data} />
    </>
  );
}
