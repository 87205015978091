import { SideNav } from "../../layout/sideNav";
import "../../assets/pages/list-management-auditing/Index.scss";
import History from "../../components/adhocListScrubbingHistory/history";

export default function ListManagementAuditing() {
  return (
    <>
      <div className="page-section">
        <div>
          <SideNav />
        </div>
        <div className="header">
          <h2 className="pt-4 pl-4"> Adhoc List Scrubbing </h2>
        </div>
        <div className="page-content">
          <History />
        </div>
      </div>
    </>
  );
}
