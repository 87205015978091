/**
 * Post Select Rules.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

/* eslint-disable import/no-anonymous-default-export */
export default async function addRules(payload) {
  const { data, status } = await ctx.post("/adhocdata", payload);
  return { data, status };
}
