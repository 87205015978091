/**
 * Get campaign list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default async (campaignId) => {
      const { data, status } = await ctx.get(`/adhoccampaignresult?searchword=${campaignId}`);
      return { data, status };
  };
  