/**
 * Determines the current environment, based on the window hostname.
 *
 * @returns {String} Current environment. Either "prod", "stg", or "dev".
 */
export default() => {
  // eslint-disable-next-line no-console
  // Set in ENV
  if (process.env.REACT_APP_ENV) return process.env.REACT_APP_ENV;
  // Production
  if (
    window.location.hostname.includes('prod')
    || window.location.hostname === 'omt.lilly.com'
  ) {
    return 'prod';
  }
  // Staging
  if (window.location.hostname.includes('qa') || window.location.hostname === 'd7lmv764yvhny.cloudfront.net') {
    return 'stg';
  }
  // Develop
  return 'dev';
};
