import ctx from '../../../../plugins/axios';

export async function updateSubCategory ( payload) {
  try {
    const { data, status } = await ctx.put('/edit-subcategoryattributes', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

