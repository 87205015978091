import { LdsModal, LdsTable } from "@elilillyco/ux-lds-react";
import "../../assets/components/adhocListScrubbing/history.scss";

export default function FinalCountModal({
  isFinalCoundModal,
  setIsFinalCountModal,
  modalData,
  isReasonModal,
  setIsReasonModal,
  failedReason,
}) {
  return (
    <>
      <div>
        <LdsModal
          modalId="testModal"
          open={isFinalCoundModal}
          setModalOpen={setIsFinalCountModal}
          modalSizeClass="col-4 col-md-6"
          className="p-4">
          <LdsTable id="existingListTable">
            <thead>
              <tr>
                <th scope="col" className="b align-center">
                  {" "}
                  Suppression Rule
                </th>
                <th scope="col" className="b align-center">
                  Count
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(modalData).map((dataList, index) => {
                return (
                  <tr role="row" key={index}>
                    <th role="rowheader" scope="row" className="align-center">
                      {dataList.toUpperCase()}{" "}
                    </th>
                    <th role="rowheader" scope="row" className="align-center">
                      {modalData[dataList]}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </LdsTable>
        </LdsModal>
      </div>
      <div>
        <LdsModal
          modalId="reasonModal"
          open={isReasonModal}
          setModalOpen={setIsReasonModal}
          modalSizeClass="col-4 col-md-6"
          className="p-4">
          <>
            <div className="b reasonFailed">Reason for failed</div>
            <div>{failedReason}</div>
          </>
        </LdsModal>
      </div>
    </>
  );
}
