
import ctx from '../../../../plugins/axios';

export async function getCountries (pageFilter) {
  try {
    const { data, status } = await ctx.get('/fetchcountries');
    return { data, status };
  } catch (error) {
    return { error };
  }
};