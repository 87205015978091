import {
  LdsCheckbox,
  LdsIcon,
  LdsLoadingSpinner,
  LdsTabPanel,
  LdsTabs,
} from "@elilillyco/ux-lds-react";
import "../../assets/components/adhocListScrubbing/adhocTabRules.scss";
import { useEffect, useState } from "react";
import data from "./data.json";

export default function AdhocTabRules({
  selectedValues,
  checkedValues,
  isDisabled,
}) {
  const [currentTabValue, setcurrentTabValue] = useState(0);
  const [cdata, setcData] = useState(data.data);
  const handleTheChecked = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(cdata));
    for (let entry of tempData) {
      if (entry.value === value) {
        entry.checked = checked;
      }
    }
    setcData(tempData);
  };
  useEffect(() => {
    const tempData = JSON.parse(JSON.stringify(cdata));
    setcData(data.data);
    for (let entry of tempData) {
      entry.checked = false;
    }

    for (let entry of tempData) {
      if (checkedValues.includes(entry.name.toLowerCase())) {
        entry.checked = true;
      }
    }
    setcData(tempData);
  }, [checkedValues]);

  useEffect(() => {
    const data = cdata.filter((array) => {
      if (array.checked) {
        return array.name;
      }
    });
    selectedValues(data);
  }, [cdata]);
  return (
    <div>
      <LdsTabs
        id="tabGroupaa1"
        activeTab={currentTabValue}
        onChange={(n) => {
          setcurrentTabValue(n);
        }}
        tabAlign="align-left"
        tabLabels={[
          {
            label: "Standard Suppression Logic",
            tabId: 0,
          },
          {
            label: "Optional Suppression logic",
            tabId: 1,
          },
        ]}
        tabStyle="card">
        <LdsTabPanel activeTab={currentTabValue} tabId={0}>
          {!isDisabled ? (
            <div className="row">
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Kaiser & Current Kaiser</span>
              </div>
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Corporate Opt Out</span>
              </div>
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Active Hcp</span>
              </div>
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Brand Approved Specialty</span>
              </div>
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Privacy Status</span>
              </div>
              <div className="col">
                <LdsIcon name="Check" inline className="ldcIconCheck" />
                <span>Field WVU Status</span>
              </div>
            </div>
          ) : (
            <div className="ta-center p-3 m-4">
              <LdsLoadingSpinner size={50} />
            </div>
          )}
        </LdsTabPanel>
        <LdsTabPanel activeTab={currentTabValue} tabId={1}>
          <div className="tab2">
            {cdata.map((rules) => {
              return (
                <div className="" key={rules.id}>
                  <LdsCheckbox
                    id={rules.id}
                    label={rules.label}
                    name={rules.name}
                    value={rules.value}
                    checked={rules.checked}
                    onChange={handleTheChecked}
                  />
                </div>
              );
            })}
          </div>
        </LdsTabPanel>
      </LdsTabs>
    </div>
  );
}
