import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { users } from "../api/index";

// Function to determine default role based on roles array
const determineDefaultRole = (roles) => {
  const roleMap = {
    omms_admin: 'omms_admin',
    omms_vendor: 'omms_vendor',
    omms_peer_review_bia: 'omms_peer_review_bia',
    omms_peer_review_channel: 'omms_peer_review_channel',
    omms_peer_review_campaign: 'omms_peer_review_campaign',
    omms_metadata_manager: 'omms_metadata_manager',
    omms_read_only: 'omms_read_only',
  };

  // Find the first matching role from the provided roles
  if(roles?.length > 0) {
    for (let role of roles) {
      if (roleMap[role]) {
        return roleMap[role];
      }
    }
    
  }
  return roles?.length > 0 ? roles[0] : '';
 

  // If no specific role is found, return the first role from the array
 
};

export const initialState = {
  user: {},
  loader: true,
  defaultRole: sessionStorage.getItem("role") || '',
  AllRole: [],
  userUUID: undefined,
  role_ids: [],
  first_name: "",
  last_name: ""
};

const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  try {
    const response = await users();
    return response.data.data;
  } catch (error) {
    throw Error('Failed to fetch users.');
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.user = action.payload;
      state.defaultRole = (sessionStorage.getItem("role") === '' || sessionStorage.getItem("role") === undefined || sessionStorage.getItem("role") === null)
        ? determineDefaultRole(action.payload.role)
        : sessionStorage.getItem("role") || action.payload.role[0];
      state.userUUID = action.payload.uuid;
      state.AllRole = action.payload.role;
      state.loader = false;
      state.role_ids = action.payload.role_ids;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
    });
    builder.addCase(fetchUsers.pending, (state, action) => {
      if (!Object.keys(state.user).length) {
        state.loader = true;
      }
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.user = {}; 
      state.loader = false;
    });
  },
});

export { fetchUsers };

export default userSlice.reducer;
