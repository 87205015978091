/**
 * Get List Mgmt RMS Table data in Response Matrix Summary Table.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default async function getRmsTableData(selectedFilters){
    const queryStrcount = `/rmsummary?department=${selectedFilters.department}&brand=${selectedFilters.brand}&indication=${selectedFilters.indication}&campaign_id=${selectedFilters.campaign}&search=${selectedFilters.search}&downloadcsv=${selectedFilters.downloadCsv}&CurrentPage=${selectedFilters.pageNo}&pageDataLimit=${selectedFilters.limit}`;
    const { data, status } = await ctx.get(queryStrcount);
    return { data, status}
}