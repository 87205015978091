import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getOmtMt} from '../../api/omtMt'


export const initialState = {
  MtState: [],
  loader: false
}

const fetchOmtMt = createAsyncThunk(
  "getOmtMt/Maintenance",
  async () => {
    const response = await getOmtMt();
    return response.data;
  }
);

const omtMtSlice = createSlice({
  name: 'omtMt',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchOmtMt.fulfilled, (state, action) => {

      state.MtState = action.payload.data
      state.loader = false;
    });
    builder.addCase(fetchOmtMt.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchOmtMt.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { fetchOmtMt };
export default omtMtSlice.reducer;