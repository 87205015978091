import { LdsButton, LdsLoadingSpinner, LdsPagination, LdsTable, LdsTile,LdsIcon,LdsTextField, LdsLink } from '@elilillyco/ux-lds-react'
import React, { useEffect, useState } from 'react'
import HeaderNav from '../HeaderNav'
import { useDispatch,useSelector  } from 'react-redux';
import { fetchException } from '../../store/Components/OMMS/exceptionArchive';

const columns = [
    'Supplier Name',
    'Platform',
    'MetricLabel',
];

const ExceptionArchive = () => {
    const dispatch = useDispatch();
    const [apiRes, setApiRes] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalPage, setTotalPage] = useState()
    const [pageSize, setPageSize] = useState(5);
    const [statusSearchFld1, setIsON1] = React.useState(false);
    const [statusSearchFld2, setIsON2] = React.useState(false);
    const [metricLabel, setMetricLabel] = useState('')
    const [supplierName, setSupplierName] = useState('')
    const [statusSearchFld3, setIsON3] = React.useState(false);
    const [platform, setPlatform] = useState('');
    const totalPages = useSelector(({ exceptionSlice }) => exceptionSlice.totalPages);
    const loader = useSelector(({exceptionSlice}) => exceptionSlice.loader);


    const apiPayload = {
        active_flag: false,
        delivery_source: '',
        supplier_name: supplierName,
        delivery_source_type: '',
        delivery_channel: '',
        platform: platform,
        pageSize: 10,
        pageNumber: currentPage,
        status: '',
        contact_type: '',
        metric_label: metricLabel
    };

    useEffect(() => {
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data), setTotalPage(d?.payload?.numberOfPages)));
    }, [currentPage]);

    const handleSearch = () => {
        setApiRes()
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data)));
    }

    const handleClear = () => {
        setIsON1(false)
        setIsON2(false)
        setIsON3(false)
        setSupplierName('')
        setPlatform('')
        setMetricLabel('')

        // Reset the inputColumns state

        const clearPayload = {
            active_flag: true,
            supplier_name: '',
            metric_label: '',
            platform: '',
            pageSize: 10,
            pageNumber: currentPage,
            status: '',
            contact_type: '',
        }
        dispatch(fetchException(clearPayload)).then((d) => (setApiRes(d?.payload?.data)));
    }


    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(prevSelectedRows => prevSelectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows(prevSelectedRows => [...prevSelectedRows, rowId]);
        }
    }

    useEffect(() => {
        // Check if all rows are selected after the state update
        setSelectAll(apiRes?.every(row => selectedRows.includes(row.omms_exception_reporting_id)));
    }, [selectedRows]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(apiRes?.map(row => row.omms_exception_reporting_id));
        }
        // Toggle the selectAll state
        setSelectAll(!selectAll);
    }
    console.log("apiRes", apiRes)

    function retunToOverview() {
        window.location.href = '/omms/exception-reporting';
    }

    const firstPage = () => {
        setCurrentPage(1)
    }
    const lastPage = () => {
        setCurrentPage(totalPage)
    }


    return (
        <>
            <HeaderNav msg={"Exception Reporting Archive"} />
            <div className="ms-3 mt-2 p-2 lds-tile">
                <LdsLink href="/omms">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <LdsTile
                className="Hero"
            >
                <div className="cont">
                    <span style={{ fontSize: "3rem" }}>Archives</span>
                </div>

                <div className="ov-button2">
                    <LdsButton onClick={handleClear} >
                        Clear Search
                    </LdsButton>
                </div>
            </LdsTile>
            {loader && <div className='container mt-4'><LdsLoadingSpinner size={50} animationSpeed={500} />

            </div>
            }

            {
                (apiRes?.length < 1 && !loader) &&
                <span>No Data available </span>
            }
            {
                (apiRes?.length >= 1 && !loader) &&
                <div>
                    <LdsTable>
                        <thead>
                            <tr>
                                <th scope="col" className="table-ends">
                                    <div class="attriDivCore">
                                        Supplier Name
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON1(!statusSearchFld1) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld1 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld-exception'
                                                id='orgName'
                                                value={supplierName}
                                                onChange={(e) => setSupplierName(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Platform
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => {
                                                    setIsON2(!statusSearchFld2);
                                                }}

                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld2 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={platform}
                                                onChange={(e) => setPlatform(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Metric Label
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON3(!statusSearchFld3) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld3 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={metricLabel}
                                                onChange={(e) => setMetricLabel(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {apiRes?.map((resp) =>
                                <tr role="row">

                                    <td className='ta-start' role="rowheader" scope="row">{resp?.supplierName}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.platform}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.metricLabel}</td>
                                </tr>
                            )}
                        </tbody>
                    </LdsTable>
                    <div className="d-flex">
                        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton>
                        <LdsPagination
                            currentPage={currentPage}
                            onNextClick={() => setCurrentPage(currentPage + 1)}
                            onPrevClick={() => setCurrentPage(currentPage - 1)}
                            setCurrentPage={setCurrentPage}
                            useButtons={true}
                            pageRangeVisible={pageSize}
                        />
                        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton>
                    </div>
                </div>}
        </>
    );
        }
           
export default React.memo(ExceptionArchive);