import ctx from "../../../plugins/axios";

/**
 * Download Contracting/Execution List File Data
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (payload) => {
  const queryStrcount = `/downloadauditlist?list_type=${payload.list_type}&filename=${payload.file_name}&receiver=${payload.receiver}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};

