import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {putOmtMt} from '../../api/omtMtPut'


const omtMaintenancePut = createAsyncThunk(
  "getOmtMt/Maintenance",
  async (payload) => {
    const response = await putOmtMt(payload);
    return await response.data;
  }
);



export { omtMaintenancePut };
