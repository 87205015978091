import {
  LdsButton,
  LdsDatepicker,
  LdsLoadingSpinner,
  LdsSearch,
  LdsSelect,
} from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/components/list-management-auditing/Index.scss";
import { listmgtFilter } from "../../store/Components/listmgt/filtersData";
import AuditTable from "./AuditTrailTable.js";
import { getTableData } from "../../store/Components/listmgt/auditTrailTable.js";
import ExportExcelSheet from "../../data/utils/ExportExcelSheet.js";

export default function Overview() {
  const dispatch = useDispatch();
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedIndication, setSelectedIndication] = useState("");
  const [selectedBIAtARGET, setSelectedBIAtARGET] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDeviation, setSelectedDeviation] = useState("");
  let [selectedFilterData] = useState({
    brand: "",
    vendor: "",
    campaign_start_date: "",
    campaign_id: "",
    campaign_type: "",
    indication: "",
    TargetList: "",
    deviation: "",
    campaign_end_date: "",
    searchKeyword: "",
    pageNo: 1,
    limit: 25,
    downloadcsv: "false",
  });

  const {
    brand,
    vendor,
    indication,
    campaignType,
    isFilterloading,
    biaTarget,
    deviation,
    isApiFail,
  } = useSelector((store) => store.lmfilters);
  const { auditTableData, isauditTableloading, pageNo, dataCounts } =
    useSelector((store) => store.getTableData);

  useEffect(() => {
    dispatch(listmgtFilter());
    dispatch(getTableData(selectedFilterData));
  }, [dispatch]);

  const getFilteredTableData = () => {
    dispatch(getTableData(selectedFilterData));
  };

  function selectedValueVendor(vendor) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.vendor = vendor;
    getFilteredTableData();
  }
  function selectedValueCampaign(cmpType) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.campaign_type = cmpType;
    getFilteredTableData();
  }
  function selectedValueBrand(brand) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.brand = brand;
    getFilteredTableData();
  }
  function selectedValueIndication(indication) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.indication = indication;
    getFilteredTableData();
  }
  function selectedValueBIAtARGET(targetList) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.TargetList = targetList;
    getFilteredTableData();
  }
  function selectedValueCmpId(campaignId) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.campaign_id = campaignId;
    getFilteredTableData();
  }
  function selectedValueDeviation(deviation) {
    selectedFilterData.pageNo = 1;
    selectedFilterData.deviation = deviation;
    getFilteredTableData();
  }

  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

  const startDate = (startDate) => {
    if (startDate.type === "change") {
      let date = startDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.campaign_start_date = date;
        dateRangerChecker();
      }
    } else {
      startDate = startDate.replaceAll("/", "-");
      selectedFilterData.campaign_start_date = startDate;
      dateRangerChecker();
    }
  };
  const endDate = (endDate) => {
    if (endDate.type === "change") {
      let date = endDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.campaign_end_date = date;
        dateRangerChecker();
      }
    } else {
      endDate = endDate.replaceAll("/", "-");
      selectedFilterData.campaign_end_date = endDate;
      dateRangerChecker();
    }
  };
  const dateRangerChecker = () => {
    if (
      selectedFilterData.campaign_start_date >
        selectedFilterData.campaign_end_date &&
      selectedFilterData.campaign_end_date !== ""
    ) {
      return;
    }
    selectedFilterData.pageNo = 1;
    getFilteredTableData();
  };
  function pagination(pageno) {
    selectedFilterData.pageNo = pageno;
    getFilteredTableData();
  }

  function clearFilter() {
    selectedFilterData.brand = "";
    selectedFilterData.vendor = "";
    selectedFilterData.campaign_start_date = "";
    selectedFilterData.campaign_id = "";
    selectedFilterData.campaign_type = "";
    selectedFilterData.indication = "";
    selectedFilterData.TargetList = "";
    selectedFilterData.deviation = "";
    selectedFilterData.campaign_end_date = "";
    selectedFilterData.searchKeyword = "";
    selectedFilterData.pageNo = 1;
    selectedFilterData.limit = 25;
    selectedFilterData.downloadcsv = false;
    getFilteredTableData();
    setSelectedVendor("");
    setSelectedCampaign("");
    setSelectedBrand("");
    setSelectedIndication("");
    setSelectedBIAtARGET("");
    setSelectedDeviation("");
    setSelectedStartDate("");
    setSelectedStartDate("");
    setSearchTerm("");
  }
  async function downloadcsvData() {
    const { meta, payload } = await dispatch(
      getTableData({ ...selectedFilterData, downloadcsv: "true" })
    );
    if (meta.requestStatus === "fulfilled") {
      const csvdata = payload.data.data;
      if (csvdata.length) {
        ExportExcelSheet(csvdata, "campaigndata");
      }
    }
  }
  return (
    <>
      <div className="index-section">
        {isFilterloading ? (
          <>
            <div>
              <div className="filterheader">
                <div className="row">
                  <div className="col-4">
                    <LdsSearch
                      id={"searchSuggest"}
                      placeholder="Search for Campaign ID"
                      disabled={!isauditTableloading}
                      inputAriaLabel={null}
                      isTypeAhead={true}
                      onChange={setSearchTerm}
                      onSubmit={selectedValueCmpId}
                      value={searchTerm}
                    />
                  </div>
                  <div className="col-2">
                    <LdsButton
                      onClick={clearFilter}
                      disabled={!isauditTableloading}>
                      Clear
                    </LdsButton>
                  </div>
                  <div className="col-2 mr-4 downloadCSV">
                    <LdsButton
                      className="buttons"
                      icon="DownloadSimple"
                      disabled={!isauditTableloading}
                      onClick={downloadcsvData}>
                      Download As CSV
                    </LdsButton>
                  </div>
                </div>
                <div className="row mt-3 campaign-filters-row-second">
                  <div className="col-2">
                    <LdsSelect
                      id="vendor"
                      label="Vendor"
                      name="vendor"
                      disabled={!isauditTableloading}
                      onChange={(e) => {
                        setSelectedVendor(e.value);
                        selectedValueVendor(e.value);
                      }}
                      options={vendor}
                      value={selectedVendor}
                    />
                  </div>
                  <div className="col-2">
                    <LdsSelect
                      id="campaigntype"
                      label="Campaign Type"
                      name="campaign_type"
                      disabled={!isauditTableloading}
                      onChange={(e) => {
                        setSelectedCampaign(e.value);
                        selectedValueCampaign(e.value);
                      }}
                      options={campaignType}
                      value={selectedCampaign}
                    />
                  </div>
                  <div className="col-2">
                    <LdsSelect
                      id="brand"
                      label="Brand"
                      name="brand"
                      disabled={!isauditTableloading}
                      required={true}
                      onChange={(e) => {
                        setSelectedBrand(e.value);
                        selectedValueBrand(e.value);
                      }}
                      options={brand}
                      value={selectedBrand}
                    />
                  </div>
                  <div className="col-3 datePicker">
                    <LdsDatepicker
                      dateFormat="MM/DD/YYYY"
                      id="testPicker"
                      label="Campaign Start Date"
                      disabled={!isauditTableloading}
                      value={selectedStartDate}
                      defaultValue={selectedStartDate}
                      onChange={(e) => {
                        setSelectedStartDate(e);
                        startDate(e);
                      }}
                    />
                  </div>
                  <div className="col-3 datePicker">
                    <LdsDatepicker
                      dateFormat="MM/DD/YYYY"
                      id="testPicker"
                      label="Campaign End Date"
                      disabled={!isauditTableloading}
                      onChange={(e) => {
                        setSelectedEndDate(e);
                        endDate(e);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-2">
                    <LdsSelect
                      id="indication"
                      label="Indication"
                      name="indication"
                      disabled={!isauditTableloading}
                      required={true}
                      onChange={(e) => {
                        setSelectedIndication(e.value);
                        selectedValueIndication(e.value);
                      }}
                      options={indication}
                      value={selectedIndication}
                    />
                  </div>
                  <div className="col-2">
                    <LdsSelect
                      id="BIASelect"
                      label="BIA Target List"
                      name="BIASelect"
                      disabled={!isauditTableloading}
                      options={biaTarget}
                      onChange={(e) => {
                        setSelectedBIAtARGET(e.value);
                        selectedValueBIAtARGET(e.value);
                      }}
                      value={selectedBIAtARGET}
                    />
                  </div>
                  <div className="col-2">
                    <LdsSelect
                      id="deviation"
                      label="Data Deviation"
                      name="deviation"
                      disabled={!isauditTableloading}
                      options={deviation}
                      onChange={(e) => {
                        setSelectedDeviation(e.value);
                        selectedValueDeviation(e.value);
                      }}
                      value={selectedDeviation}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordionlm"></div>
            <div className="auditTable">
              <AuditTable
                dataCounts={dataCounts}
                selectedFilterData={selectedFilterData.pageNo}
                auditTableData={auditTableData}
                pagination={pagination}
                isauditTableloading={isauditTableloading}
                pageNo={pageNo}
              />
            </div>
          </>
        ) : (
          <div className="ta-center p-3 m-4">
            <LdsLoadingSpinner size={50} />
          </div>
        )}
      </div>
    </>
  );
}
