import { LdsButton, LdsCard, LdsLoadingSpinner, LdsPagination, LdsSearch, LdsSelect, LdsToast } from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";
import { choosenBrandValue, getRmsTableList, rmsLovsFilter } from "../../store/responseMatrix/rmsStore";
import { useDispatch, useSelector } from "react-redux";
import RmSummaryTable from "./RmSummaryTable";
import '../../assets/components/response-matrix/responseMatrixSummary.scss';
import ExportExcelSheet from "../../data/utils/ExportExcelSheet";

export default function ResponseMatrixSummary() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [departmentValue, setSelectedDepartment] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedIndication, setSelectedIndication] = useState("");
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const { rmsTableList, isRmsTableLoading, pageCount, brand, campaignId, department, indication, isRmsLoading, isLovsStatus, tableApiStatus } = useSelector(({ rmsStoreSlicer }) => rmsStoreSlicer);
    const [isLovApiLoad, setIsLovApiLoad] = useState(true);
    useEffect(() => {
        dispatch(getRmsTableList(selectedFilterData));
        dispatch(rmsLovsFilter({
            department: "",
            brand: "",
            indication: "",
        }));
    }, []);
    let [selectedFilterData, setSelectedFilterData] = useState({
        department: "",
        brand: "",
        indication: "",
        campaign: "",
        search: "",
        downloadCsv: false,
        pageNo: 1,
        limit: 25
    });
    useEffect(() => {
        setIsLovApiLoad(false);
        setTimeout(() => {
            setIsLovApiLoad(true);
        }, 10)
    }, [brand, department]);
    function selectedValueSearch(campaignId) {
        selectedFilterData.pageNo = 1;
        selectedFilterData.search = campaignId;
        getTableData();
    }
    const departmentFilter = (value) => {
        selectedFilterData.pageNo = 1;
        selectedFilterData.department = value;
        casecadeData();
    };
    function casecadeData() {
        dispatch(rmsLovsFilter(selectedFilterData))
    }
    const brandFilter = (value) => {
        selectedFilterData.pageNo = 1;
        selectedFilterData.brand = value;
        dispatch(choosenBrandValue(value));
        casecadeData();
        getTableData();
    };
    const indicationFilter = (value) => {
        selectedFilterData.pageNo = 1;
        selectedFilterData.indication = value;
        // casecadeData();
        getTableData();
    };
    const campaignFilter = (value) => {
        selectedFilterData.pageNo = 1;
        selectedFilterData.campaign = value;
        getTableData();
    };

    function clickLink(e, value) {
        setCurrentPage(value);
        selectedFilterData.pageNo = value;
        getTableData();
    }
    function clickNext() {
        setCurrentPage(currentPage + 1);
        selectedFilterData.pageNo = currentPage + 1;
        getTableData();
    }
    function clickPrev() {
        setCurrentPage(currentPage - 1);
        selectedFilterData.pageNo = currentPage - 1;
        getTableData();
    }
    function getTableData() {
        dispatch(getRmsTableList(selectedFilterData));
    }
    function clearFilter() {
        selectedFilterData.department = "";
        selectedFilterData.brand = "";
        selectedFilterData.indication = "";
        selectedFilterData.campaign = "";
        selectedFilterData.search = "";
        selectedFilterData.pageNo = 1;
        setSelectedDepartment("");
        setSelectedBrand("");
        setSelectedIndication("");
        setSelectedCampaign("");
        setSearchTerm("");
        setCurrentPage(1);
        getTableData();
        casecadeData();
    }

    async function downloadcsvData() {
        const { meta, payload } = await dispatch(
            getRmsTableList({ ...selectedFilterData, downloadCsv: "true" })
        );
        if (meta.requestStatus === "fulfilled") {
            const csvdata = payload.data;
            if (csvdata.length) {
                ExportExcelSheet(csvdata, "Response_Matrix_Summary");
            }
        }
    }

    return (
        <div>
            {isLovApiLoad &&
                <div>
                    {/* row filter 1 */}
                    <div className="row">
                        <div className="col-4">
                            <LdsSearch
                                id={"rmssearch"}
                                placeholder="Search"
                                disabled={isLovsStatus || isRmsLoading}
                                inputAriaLabel={null}
                                isTypeAhead={true}
                                onChange={setSearchTerm}
                                onSubmit={selectedValueSearch}
                                value={searchTerm}
                            ></LdsSearch>
                        </div>
                        <div className="col-2 clearbtn ">
                            <LdsButton
                                onClick={clearFilter}
                                disabled={isLovsStatus || isRmsLoading}
                            >
                                Clear All
                            </LdsButton>
                        </div>
                        <div className="col-4 downloadCSV" >
                            <LdsButton
                                className="buttons"
                                disabled={isLovsStatus || isRmsLoading}
                                icon="DownloadSimple"
                                onClick={downloadcsvData}
                            >
                                Download As CSV
                            </LdsButton>
                        </div>
                    </div>
                    {/* row filter 2 */}
                    <div className="row mt-3">
                        <div className="col-3">
                            <LdsSelect
                                id="rmsDepartment"
                                label="Department"
                                name="Department"
                                disabled={isLovsStatus || isRmsLoading}
                                onChange={(e) => {
                                    setSelectedDepartment(e.value);
                                    departmentFilter(e.value);
                                }}
                                options={department}
                                value={departmentValue}
                            />
                        </div>
                        <div className="col-3 mb-4">
                            <LdsSelect
                                id="rmsBrand"
                                label="Brand/Theraputic Area"
                                name="Brand"
                                disabled={isLovsStatus || isRmsLoading}
                                onChange={(e) => {
                                    setSelectedBrand(e.value);
                                    brandFilter(e.value);
                                }}
                                options={brand}
                                value={selectedBrand}
                            />
                        </div>
                        <div className="col-3">
                            <LdsSelect
                                id="rmsIndication"
                                label="Indication/Disease State"
                                name="Indication"
                                disabled={isLovsStatus || isRmsLoading}
                                onChange={(e) => {
                                    setSelectedIndication(e.value);
                                    indicationFilter(e.value);
                                }}
                                options={indication}
                                value={selectedIndication}
                            />
                        </div>
                        <div className="col-3 rms-select-dropdown-campaign ">
                            <LdsSelect
                                id="rmsCampaignID"
                                label="Campaign ID"
                                name="CampaignID"
                                disabled={isLovsStatus || isRmsLoading}
                                onChange={(e) => {
                                    setSelectedCampaign(e.value);
                                    campaignFilter(e.value);
                                }}
                                options={campaignId}
                                value={selectedCampaign}
                            />
                        </div>
                    </div>
                    {/* table */}
                </div>}
            <div className="mt-3" >
                {
                    isRmsTableLoading ?
                        (
                            rmsTableList.length > 0 ?
                                (
                                    <div>
                                        <RmSummaryTable
                                            rmsTableList={rmsTableList}
                                            tableApiStatus={tableApiStatus}
                                        />
                                        <LdsPagination
                                            currentPage={selectedFilterData.pageNo}
                                            onLinkClick={clickLink}
                                            onNextClick={clickNext}
                                            onPrevClick={clickPrev}
                                            pageRangeVisible={5}
                                            totalPages={pageCount}
                                            setCurrentPage={setCurrentPage}
                                        />
                                        <LdsToast/>
                                    </div>
                                )
                                :
                                <LdsCard className="ta-center p-3 m-1">
                                    <p className="noRecord"> No Record Found </p>
                                </LdsCard>
                        )
                        :
                        (
                            tableApiStatus ?
                                <div className="ta-center p-3 m-4">
                                    <LdsLoadingSpinner size={50} />
                                </div>
                                :
                                <RmSummaryTable
                                    rmsTableList={rmsTableList}
                                    tableApiStatus={tableApiStatus}
                                />
                        )
                }

            </div>
        </div>
    )
}