import ctx from "../../../plugins/axios";
/**
 * Get History Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default async (selectedFilter) => {
    const queryStr = `/rmcapmodal?tactic_id=${selectedFilter.tactic_id}&send_date=${selectedFilter.send_date}&recieve_date=${selectedFilter.recieve_date}&pageDataLimit=${selectedFilter.limit}&CurrentPage=${selectedFilter.pageNo}`;
    const { data, status } = await ctx.get(queryStr);
    return { data, status };
  };
  