import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { LdsIcon, LdsLink, LdsLoadingSpinner, LdsTextField, LdsValidationError, LdsToast, LdsModal, LdsButton, useToastContext, useLdsModal } from '@elilillyco/ux-lds-react';
import '../../../assets/components/OMMS/addmetadata.scss'
import HeaderNav from '../../../components/HeaderNav';
import infoToastConfig from '../../../data/utils/infoToastConfig';
import getCurrentUserId from '../../../data/utils/getCurrentUserId';
import { CREATE_TONALITY, FETCH_TONALITY_BY_ID, UPDATE_TONALITY } from '../../../store/Components/TACCT/TN/tonailtyEntry';

export default function TonalityEntry() {
    const { isModalOpen: isConfirmModalOpen, setIsModalOpen: setIsConfirmModalOpen } = useLdsModal(false);
    const [pageError, setPageError] = useState({});
    const { addToast } = useToastContext();
    const [toPayload, setTOPayload] = useState({});
    const { tonalityEntryLoader, toanlityDataForEdit } = useSelector(({ tonalityEntrySlice }) => tonalityEntrySlice);
    const role = useSelector(({ user }) => user.role_ids);
    const dispatch = useDispatch();
    const [id, setId] = useState();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        tonality_values: '',
        tonality_ops_def: ''
    })
    const [getstatus, setStatus] = useState()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('id')) {
            setId(urlParams.get('id'));
        }
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(FETCH_TONALITY_BY_ID(id));
        }
    }, [id, dispatch])

    useEffect(() => {
        const tonalityDetails = {
            'tonality_values': toanlityDataForEdit?.tonality_values,
            'tonality_ops_def': toanlityDataForEdit?.tonality_operational_definition,
        }
        setFormValues(tonalityDetails);
    }, [toanlityDataForEdit])

    const HandleBUFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const submitValues = [];
        const payloadData = {};
        const checkIfAnyValidationError = Object.values(pageError).some((ele) => ele.length > 0)
        if (checkIfAnyValidationError) {
            return addToast(infoToastConfig({ toastMessage: "Please check below validation error!", variant: "error" }))
        }
        for (const value of formData.keys()) {
            submitValues.push(formData.get(value))
        }
        const checkIfAnyNull = submitValues.some((ele) => ele.length === 0)
        if (checkIfAnyNull) {
            return addToast(infoToastConfig({ toastMessage: "Please fill all attribute!", variant: "error" }))
        }
        for (const item of formData.entries()) {
            payloadData[item[0]] = item[1]
        }
        payloadData['created_by'] = id ? toanlityDataForEdit.created_by : getCurrentUserId(role);
        payloadData['status'] = document.activeElement.value === 'submit-btn' ? 'PENDING' : 'DRAFT';

        if (id) {
            payloadData['business_id'] = toanlityDataForEdit.grammatical_form_overview_detail_id;
            payloadData['updated_by'] = getCurrentUserId(role);
            payloadData['tonality_id'] = id;
            payloadData['created_by'] = getCurrentUserId(role);
        }
        let payloadToUse = { "tonality_data": payloadData }
        if (id) {
            payloadToUse = { "edit_values": payloadData }
        }
        setTOPayload(payloadToUse);
        setIsConfirmModalOpen(true);
    }

    const callBUEntryApi = async (e) => {
        let isPageErr = false;
        document.getElementById("TonalityForm").addEventListener('submit', (e => {
            e.preventDefault();
        }));
        const callMeta = async () => {
            if (id) {
                const { meta, payload } = await dispatch(UPDATE_TONALITY(toPayload));
                return { meta, payload };
            }
            else {
                const { meta, payload } = await dispatch(CREATE_TONALITY(toPayload));
                return { meta, payload };
            }
        }

        setIsConfirmModalOpen(false);

        const { meta, payload } = await callMeta();
        if (meta.rejectedWithValue) {
            isPageErr = true;
            addToast(infoToastConfig({ toastMessage: `Error- ${payload.data.message}`, variant: 'error' }))
        } else {
            if (payload.message === "Already Exists") {
                isPageErr = true;
                addToast(infoToastConfig({ toastMessage: payload.message, variant: 'warning' }));
            }
            else {
                addToast(infoToastConfig({ toastMessage: 'Added Successfully', variant: 'success' }));
            }
        }

        if (!isPageErr) {
            setFormValues({
                tonality_values: '',
                tonality_ops_def: ''
            })
            setTimeout(() => {
                return navigate('/tacct/tonality-ops');
            }, 1000);
        }
        return;
    }

    const handleChange = (e) => {
        e.target.value.match(/[^a-zA-Z0-9\s_-]/) ? setPageError((prevError) => ({ ...prevError, [e.target.id]: 'Special characters not allowed' })) : setPageError((prevError) => ({ ...prevError, [e.target.id]: '' }));
        setFormValues(prevFormData => {
            return {
                ...prevFormData,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <>
            <HeaderNav msg={"Tonality"} />
            <div className='mt-2'>
                <LdsLink href="/tacct/tonality-ops">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <div align='center'>
                <h2> {id ? 'Edit' : 'Enter New'} Tonality </h2>
            </div>
            {tonalityEntryLoader &&
                <div className='bu-spinner'>
                    <LdsLoadingSpinner size={90} />
                </div>
            }
            {!tonalityEntryLoader &&
                <form id="TonalityForm" onSubmit={HandleBUFormSubmit}>
                    <div align='center'>
                        <div className="col-6 col-md-4 lds-form-field row-field">
                            <LdsTextField
                                className='text-input col-md-6'
                                id='tonality_values'
                                name='tonality_values'
                                label='Tonality'
                                value={formValues?.tonality_values}
                                error={
                                    pageError.tonality_values && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.tonality_values
                                && <LdsValidationError id="errCategory">{pageError?.tonality_values}</LdsValidationError>
                            }
                            <br />
                            <LdsTextField
                                className='text-input'
                                id='tonality_ops_def'
                                name='tonality_ops_def'
                                label='Tonality operational definition'
                                value={formValues?.tonality_ops_def}
                                error={
                                    pageError.tonality_ops_def && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.tonality_ops_def
                                && <LdsValidationError id="errCategory">{pageError.tonality_ops_def}</LdsValidationError>
                            }
                            <br />
                            <div className='save'>
                                <LdsButton type="submit" name='draft-btn' id='draft-btn' value='draft-btn' onClick={()=>setStatus('draft')}>Draft </LdsButton>
                                <LdsButton type="submit" name='submit-btn' id='submit-btn' value='submit-btn' onClick={()=>setStatus('add')}>{id ? 'Edit' : 'Add'} </LdsButton>
                            </div>
                            <br />
                        </div>
                    </div>
                    <br />
                    <br />
                </form>
            }
            <LdsToast style={{ zIndex: '3' }} />
            <LdsModal
                modalId="confirmationModal"
                open={isConfirmModalOpen}
                setModalOpen={setIsConfirmModalOpen}
                persistent
            >
                <h1>Are you sure you want to {getstatus === 'draft' ? 'draft' : 'add'} these attributes</h1>
                <div className="space">
                    <LdsButton onClick={async (e) => { await callBUEntryApi(e) }}>Yes</LdsButton>
                    <LdsButton onClick={() => setIsConfirmModalOpen(false)}>No</LdsButton>
                </div>
            </LdsModal>
        </>
    )
}