import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSimilarTactic } from '../../../api/actions/tacticentry/getCommonTactic';

export const initialState = {
  commonData : [],
  commonLoader: false,
};

const fetchCommonTactic = createAsyncThunk('overview/commonTactic', async (queryStringParameters) => {
  const response = await getSimilarTactic(queryStringParameters);
  return response.data;
});

const commonTacticSlice= createSlice({
  name: 'commonTactic',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchCommonTactic.fulfilled, (state, action) => {
      state.commonData = action.payload.data;
      state.commonLoader = false;
    });
    builder.addCase(fetchCommonTactic.pending, (state, action) => {
      state.commonLoader = true;
    });
    builder.addCase(fetchCommonTactic.rejected, (state, action) => {
      state.commonData = action.payload;
      state.commonLoader = false;
    });
  },
});

export { fetchCommonTactic };

export default commonTacticSlice.reducer;
