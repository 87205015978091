import ctx from "../../../plugins/axios";

/**
 * Download Scrubbed HCPs List File Data
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (scrubbed_file_name) => {
  const queryStrcount = `/scrubbedlist?scrubbed_list_filename=${scrubbed_file_name}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};
