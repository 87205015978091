import {
  LdsLink, LdsCheckbox, LdsSwitch, LdsButton, LdsToast, useToastContext, LdsIcon, LdsTable, LdsLoadingSpinner, LdsRadio,
} from "@elilillyco/ux-lds-react";
import HeaderNav from "../../HeaderNav";
import "../../../assets/components/TACCT/BL/editProduct.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../../store/Components/TACCT/BL/tacctEditProductget";
import { updateProductData } from "../../../store/Components/TACCT/BL/tacctEditProductput";
import { useState } from "react";

export default function EditProduct() {
  const dispatch = useDispatch();
  const data = useSelector(({ tacctProductSlice }) => tacctProductSlice.getProducts);
  const loading = useSelector(({ tacctProductSlice }) => tacctProductSlice.loading);
  const [isCode, setIsCode] = useState(['CCML', 'THPC', 'DSST']);
  const firstname = useSelector(({ user }) => user.first_name);
  const lastname = useSelector(({ user }) => user.last_name);
  const [isData, setIsData] = useState([...data]);
  const [isChecked, setIsChecked] = useState(false);
  const [getId, setId] = useState();
  const { addToast } = useToastContext();
  const [refresh, setRefresh] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const filterMedicalProducts = (e) => {
    if (e.target.checked) {
      if (!isCode.includes(e.target.value)) {
        setIsCode([...isCode, e.target.value])
      }
    }
    if (!e.target.checked) {
      setIsCode(prevProducts => isCode.filter(obj => obj !== e.target.value))
    }

  }

  useEffect(() => {
    setMainLoader(true)
    dispatch(fetchProductData(
      isCode
    )).then((data) => { setTimeout(() => { setMainLoader(false) }, 1000) })
  }, [isCode]);

  useEffect(() => {
    setIsData([...data]);
  }, [data]);

  useEffect(() => {
    setId(
      isData
        ?.filter((item) => item.check === true)
        .map((item) => item.param_key_val_id)
    );
  }, [isData]);

  function checkedData(e) {
    const value = e.target.id;
    const checked = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    for (let obj of tempData) {
      if (obj.param_key_val_id.toString() === value) {
        obj.check = checked;
      }
    }
    setIsData(tempData);
  }

  async function updateCategory(e) {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    const updated_by = firstname + " " + lastname;
    await dispatch(
      updateProductData({
        master_key_val_id: getId,
        category_enable: "active",
        updated_by,
      })
    );
    addToast({
      toastMessage: "Your changes have been saved successfully",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "success",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,
    });
    dispatch(fetchProductData(
      isCode
    )).then((data) => setRefresh(data.payload));
    return;
  }

  async function disableCategory() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    const updated_by = firstname + " " + lastname;
    await dispatch(
      updateProductData({
        master_key_val_id: getId,
        category_enable: "inactive",
        updated_by,
      })
    );
    addToast({
      toastMessage: "Your changes have been saved successfully",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "success",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,
    });
    dispatch(fetchProductData(
      isCode
    )).then((data) => setRefresh(data.payload));
    return;
  }

  function handleToggleCategory(e) {
    console.log("clicked")
    const name = e.target.name;
    const flag = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    const data = tempData.filter((object) => {
      if (object.param_key_val_id.toString() === name) {
        object.category_enable = flag;
        return object;
      }
    });
    const toggledId = data[0];
    const paramId = toggledId.param_key_val_id;
    const updated_by = firstname + " " + lastname;
    if (e.target.checked) {
      dispatch(
        updateProductData({
          master_key_val_id: [paramId],
          category_enable: "active",
          updated_by,
        })
      );
    } else {
      dispatch(
        updateProductData({
          master_key_val_id: [paramId],
          category_enable: "inactive",
          updated_by,
        })
      );
    }
  }


  async function updateProduct(e) {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    const updated_by = firstname + " " + lastname;
    setMainLoader(true)
    await dispatch(
      updateProductData({
        master_key_val_id: getId,
        status: "active",
        updated_by,
      })
    );
    addToast({
      toastMessage: "Your changes have been saved successfully",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "success",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,
    });
    dispatch(fetchProductData(
      isCode
    )).then((data) => setRefresh(!refresh));
    setMainLoader(false)
    return;
  }

  async function disableProduct() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    const updated_by = firstname + " " + lastname;
    setMainLoader(true)
    await dispatch(
      updateProductData({
        master_key_val_id: getId,
        status: "inactive",
        updated_by,
      })
    );
    addToast({
      toastMessage: "Your changes have been saved successfully",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "success",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,
    });
    dispatch(fetchProductData(
      isCode
    )).then((data) => setRefresh(!refresh));
    setMainLoader(false)
    return;
  }
  useEffect(() => {
    setMainLoader(true);
    dispatch(fetchProductData(
      isCode
    )).then((data) => { setTimeout(() => { setMainLoader(false) }, 1000) });;
  }, [refresh]);

  function handleToggle(e) {
    const name = e.target.name;
    const flag = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    const data = tempData.filter((object) => {
      if (object.param_key_val_id.toString() === name) {
        object.active_flag = flag;
        return object;
      }
    });
    const toggledId = data[0];
    const paramId = toggledId.param_key_val_id;
    const updated_by = firstname + " " + lastname;
    if (e.target.checked) {
      dispatch(
        updateProductData({
          master_key_val_id: [paramId],
          status: "active",
          updated_by,
        })
      );
    } else {
      dispatch(
        updateProductData({
          master_key_val_id: [paramId],
          status: "inactive",
          updated_by,
        })
      );
    }
  }
  function handleMainChange(e) {
    const isChecking = e.target.checked;
    setIsChecked(isChecking);
    const tempData = JSON.parse(JSON.stringify(isData));
    const data = tempData.map((object) => {
      object.check = isChecking;
      return object;
    });
    setIsData(data);
  }
  function toTopPage() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div>
        <div>
          <HeaderNav msg="Blue List Content Category & Subcategory" />
        </div>
        <div className="m-3">
          <LdsLink href="/tacct/blue-list">
            <LdsIcon
              description="CaretLeft"
              label="search"
              name="CaretLeft"
              inline
            />
            Back to Overview
          </LdsLink>
        </div>
        <div className="d-flex justify-content-center">
          <h2>Edit Product</h2>
        </div>
        <div className='options-medcom-ep'>
          <LdsRadio
            id="med"
            label="Medical"
            name="but"
            value="CCML"
            defaultChecked
            onChange={(e) => setIsCode(['CCML', 'THPC', 'DSST'])}
            error
          />
          <LdsRadio
            id="com"
            label="Commercial"
            name="but"
            value="BRND"
            onChange={(e) => setIsCode(e.target.value)}
            error
          /></div>
        {isCode !== 'BRND' &&
          // <div className=" " style={{float: "left" , position:"absolute"}}>

          <div className='d-flex justify-content-center mb-4'>
            {/* <span>Select medical products:</span> */}
            <span>CCML</span>&nbsp;
            <LdsCheckbox
              id='CCML'
              value='CCML'
              // label='CCML'
              className="checkboxTheme"
              name="appCheckbox"
              onChange={(e) => filterMedicalProducts(e)}
              checked={isCode.includes('CCML')}
            />
            <span>DSST</span>&nbsp;
            <LdsCheckbox
              id='DSST'
              value='DSST'
              // label='DSST'
              className="checkboxTheme"
              name="appCheckbox"
              onChange={(e) => filterMedicalProducts(e)}
              checked={isCode.includes('DSST')}
            />
            <span>THPC</span>&nbsp;
            <LdsCheckbox
              id='THPC'
              value='THPC'
              // label='THPC'
              className="checkboxTheme"
              name="appCheckbox"
              onChange={(e) => filterMedicalProducts(e)}
              checked={isCode.includes('THPC')}
            />

          </div>
          // </div>
        }
        <div className="d-flex justify-content-center">
          <LdsButton
            className="searchbtn"
            onClick={() => { updateProduct() }}
          >
            Enable Products
          </LdsButton>
          <LdsToast />
          <LdsButton
            onClick={() => { disableProduct() }}
          >
            Disable Products
          </LdsButton>
        </div>
        <div className="d-flex justify-content-center mt-3 ">
          <LdsButton
            className="searchbtn"
            onClick={() => { updateCategory() }}
          >
            Show category of products
          </LdsButton>
          <LdsToast />
          <LdsButton
            onClick={() => { disableCategory() }}
          >
            Hide category of products
          </LdsButton>
        </div>

      </div>


      {/* {!isData && (
        <div className="spinner container">
          <LdsLoadingSpinner size={90} />
        </div>
      )} */}
      <div className="container-sm mt-5">
        {!mainLoader ? (
          <LdsTable id="my-table" >
            <thead>
              <tr>
                <th className='ta-left' scope="col">
                  <LdsCheckbox
                    name="opt1"
                    onChange={(e) => { handleMainChange(e) }}
                    checked={isChecked}
                  />
                  Select All
                </th>
                <th style={{ display: 'flex', justifyContent: 'left' }} scope="col">
                  Product
                </th>
                <th className='ta-left' scope="col" >
                  Visible
                </th>
                <th className="ta-right" scope="col" >
                  Show/Hide category and subcategory
                </th>
              </tr>
            </thead>

            <tbody className="">
              {isData?.map((obj, rowIndex) => {
                return (
                  <tr role="row" key={rowIndex}>
                    <td className="ta-left">
                      <LdsCheckbox
                        name={obj.param_key_val_id}
                        id={obj.param_key_val_id}
                        onChange={(e) => { checkedData(e) }}
                        checked={obj.check}
                        value={obj.param_key_val_id}
                      />
                    </td>

                    <td style={{ display: 'flex', justifyContent: 'left' }}>{obj.param_val}</td>
                    <td class="ta-left p-0">
                      <LdsSwitch
                        className={"LdsSwitchAlign"}
                        name={obj.param_key_val_id}
                        id={obj.param_key_val_id + 1}
                        onByDefault={obj.active_flag}
                      />
                    </td>
                    <td className="ta-right mr-3">
                      <LdsSwitch
                        name={obj?.param_key_val_id}
                        onClick={(e)=>handleToggleCategory(e)}
                        value={obj?.category_enable}
                        onByDefault={obj?.category_enable}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LdsTable>
        ) : <div className="spinner container">
          <LdsLoadingSpinner size={90} />
        </div>}
      </div>
      <div className="place">
        <LdsIcon
          name="CaretCircleUpFill"
          style={{ fill: "#F00", width: '20%' }}
          onClick={toTopPage}
        />
      </div>
    </>
  );
}
