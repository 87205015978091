import { LdsAccordion } from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";
import ModalBar from "./modalBar";

export default function ListDataModalGraph({
  dataCounts,
  contracting,
  execution,
}) {
  const modalnameexecution = "Execution";
  const [executionLabels, setExecutionLabels] = useState([]);
  const [executionData, setExecutionData] = useState([]);
  const [excutionPercentage, setExcutionPercentage] = useState([]);
  const modalnameContracting = "Contracting";
  const [contractingLabels, setContractingLabels] = useState([]);
  const [contractingData, setContractingData] = useState([]);
  const [contractingPercentage, setContractingPercentage] = useState([]);
  useEffect(() => {
    setExecutionLabels(execution.map((item) => item.date));
    setExecutionData(execution.map((item) => item.count));
    setExcutionPercentage(execution.map((item) => `${item.percentage}%`));
    setContractingLabels(contracting.map((item) => item.date));
    setContractingData(contracting.map((item) => item.count));
    setContractingPercentage(contracting.map((item) => `${item.percentage}%`));
  }, []);

  return (
    <>
      <LdsAccordion accordionId="modalAccordion">
        <LdsAccordion.Header accordionId="modalAccordion">
          Reports
        </LdsAccordion.Header>
        <LdsAccordion.Body accordionId="modalAccordion" className="pavan">
          <div className="row chartRow">
            <div className="col-6 barChart">
              <ModalBar
                name={modalnameexecution}
                labels={executionLabels}
                dataList={executionData}
                percentage={excutionPercentage}
              />
            </div>
            <div className="col-6 barChart">
              <ModalBar
                name={modalnameContracting}
                labels={contractingLabels}
                dataList={contractingData}
                percentage={contractingPercentage}
              />
            </div>
          </div>
        </LdsAccordion.Body>
      </LdsAccordion>
    </>
  );
}
