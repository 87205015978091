/* eslint-disable import/no-anonymous-default-export */
/**
 * Get Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

export default async (selectedFilter) => {
  const quary = `/listcheck?brand=${selectedFilter.brand}&indication=${selectedFilter.indication}&vendor=${selectedFilter.vendor}&listType=${selectedFilter.listType}&behavioral_segment=${selectedFilter.behavioral_segment}&brand_segment=${selectedFilter.brand_segment}&optionalList=${selectedFilter.optionalList}&mandatoryList=${selectedFilter.mandatoryList}`;
  const { data, status } = await ctx.get(quary);
  return { data, status };
};
