import ctx from "../../../plugins/axios";

/**
 * Get adhocCascading  data.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (selectedValue) => {
  const queryStrcount = `/adhoccascading?brand=${selectedValue.brand}&indication=${selectedValue.indication}&behavioral_segment=${selectedValue.behavioral_segment}&brand_segment=${selectedValue.brand_segment}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};
