import { default as ctx } from 'axios';
import getCurrentEnv from '../data/utils/getCurrentEnv';

import data from '../data/env.config.json'

// Add a request interceptor
ctx.defaults.baseURL = `https://${sessionStorage.getItem('gateway')}/omnichannel`;
ctx.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'Token'
    )}`;

    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
ctx.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const setAxiosBaseURL = (gateway) => {
  ctx.defaults.baseURL = `https://${gateway}/omnichannel`;
};

export default ctx;
