// import { handleAxiosError } from '~/lib/utils';

/**
 * Get options for cascading options.
 * @param {Object} ctx Axios instance for calling apis.
 * @param {String} queryParams Query params for fetching options
*/
import ctx from '../.././../plugins/axios';

export default async function getOptions (queryParams) {
  try {
    const { data, status } = await ctx.get(`/tacticentries/getoptions?${queryParams}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};