import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { gettacticEntryRows, gettacticToExport } from '../../../api/actions/tacticentry';


export const initialState = {
  vendorData: [],
  totalPages: 0,
  loader: false
}

// const fetchVendorData = createAsyncThunk('getVendorData/fetchVendorData', async (queryParams) => {
//     const response = await gettacticEntryRows(queryParams);
//     console.log (response)
//     return response.data.data;
//   });

const ExportVendorData = createAsyncThunk(
  "getVendorData/ExportVendorData",
  async (queryParams) => {
    const response = await gettacticToExport(queryParams);
    return await response.data;
  }
);

const fetchVendorData = createAsyncThunk(
  "getVendorData/fetchVendorData",
  async (queryParams) => {
    const response = await gettacticEntryRows(queryParams);
    return await response.data;
  }
);

const vendorDataSlice = createSlice({
  name: 'vendorData',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchVendorData.fulfilled, (state, action) => {
      // console.log(action.payload)
      // state.vendorData = action.payload.data;
      let temp = JSON.parse(JSON.stringify(action.payload.data));
      state.vendorData = temp.map((obj) => {
        obj['check'] = false;
        // obj['toggleCheck'] = false;
        return obj
      });
      state.totalPages = action.payload.numberOfPages;

      state.loader = false;
    });
    builder.addCase(fetchVendorData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchVendorData.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { fetchVendorData, ExportVendorData };
export default vendorDataSlice.reducer;