import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBUEntry, getBusinessUnitById, updateBusinessUnit } from "../../../../api/actions/TxmyMgmt/tacct_BU_overview/index";

const initialState = {
  buEntryLoader: false,
  errMsg:'',
  buDataForEdit: {},
}

const CREATE_BUSINESS_UNIT = createAsyncThunk(
  "overview/addBUEntry",
  async (payload, { rejectWithValue }) => {
    const response = await addBUEntry(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const FETCH_BUSINESS_UNIT_BY_ID = createAsyncThunk(
  "overview/getBusinessUnitById",
  async (id, { rejectWithValue }) => {
    const response = await getBusinessUnitById(id);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const UPDATE_BUSINESS_UNIT = createAsyncThunk(
  "overview/updateBusinessUnit",
  async (payload, { rejectWithValue }) => {
    const response = await updateBusinessUnit(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const buEntrySlice = createSlice({
  name: "buEntrySlice",
  initialState,
  extraReducers(builder) {
    builder.addCase(CREATE_BUSINESS_UNIT.pending, (state, action) => {
        state.buEntryLoader = true;
    });
    builder.addCase(CREATE_BUSINESS_UNIT.fulfilled, (state, action) => {
        state.buEntryLoader = false; 
        state.errMsg = '';
    });
    builder.addCase(CREATE_BUSINESS_UNIT.rejected, (state, action) => {
        state.buEntryLoader = false;
        state.errMsg = 'an error occurred!'
    });
    builder.addCase(FETCH_BUSINESS_UNIT_BY_ID.pending, (state, action) => {
      state.buEntryLoader = true;
    });
    builder.addCase(FETCH_BUSINESS_UNIT_BY_ID.fulfilled, (state, action) => {
        state.buEntryLoader = false;
        state.buDataForEdit = action.payload.data;
        state.errMsg = '';
    });
    builder.addCase(FETCH_BUSINESS_UNIT_BY_ID.rejected, (state, action) => {
        state.buEntryLoader = false;
        state.errMsg = 'an error occurred while fetching!'
    });
    builder.addCase(UPDATE_BUSINESS_UNIT.pending, (state, action) => {
      state.buEntryLoader = true;
    });
    builder.addCase(UPDATE_BUSINESS_UNIT.fulfilled, (state, action) => {
        state.buEntryLoader = false;
        state.errMsg = '';
    });
    builder.addCase(UPDATE_BUSINESS_UNIT.rejected, (state, action) => {
        state.buEntryLoader = false;
        state.errMsg = 'an error occurred while updating!'
    });
  },
});

export {
    CREATE_BUSINESS_UNIT,
    FETCH_BUSINESS_UNIT_BY_ID,
    UPDATE_BUSINESS_UNIT,
}

export default buEntrySlice.reducer;
