import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGfOverview } from "../../../../api/actions/TxmyMgmt/grammaticalForm/getOverview";

const fetchGfData = createAsyncThunk(
  "overview/getBuOverview",
  async (queryparams) => {
    const response = await getGfOverview(queryparams);
    return response.data;
  }
);

export { fetchGfData };