import ctx from "../../../plugins/axios";
/**
 * Get History Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

const getHistoryFilter = async () => {
  try {
    const { data, status } = await ctx.get("/historyfilter");
    return { data, status };
  } catch (error) {
    return { error };
  }
};
export default getHistoryFilter;
