import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addNewCategory from '../../../../api/actions/TxmyMgmt/tacctBlOverview/addNewCategory';

export const initialState = {
  apiresponse : [],
  pageLoader: false,
};

const addCategory = createAsyncThunk('overview/addcategory', async (postdata) => {
  const response = await addNewCategory(postdata);
  return response.data;
});

const addCategorySlice = createSlice({
  name: 'addNewCategory',
  initialState,
  extraReducers(builder) {
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.apiresponse = action.payload;
      state.pageLoader = false;
    });
    builder.addCase(addCategory.pending, (state, action) => {
      state.pageLoader = true;
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.apiresponse = action.payload;
      state.pageLoader = false;
    });
  },
});

export { addCategory };

export default addCategorySlice.reducer;
