import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gethistorydata } from "../../api/actions/adhoc";
const initialState = {
  adhocTableData: [],
  loading: false,
  isTableDataLoading: false,
  pageNo: 1,
};

const getHistoryTableData = createAsyncThunk(
  "audit/tableList",
  async (selectedFilterData) => {
    const response = await gethistorydata(selectedFilterData);
    return response;
  }
);

const historyTableSlicer = createSlice({
  name: "tableList",
  initialState,
  extraReducers(builder) {
    builder.addCase(getHistoryTableData.pending, (state, action) => {
      state.isTableDataLoading = false;
    });
    builder.addCase(getHistoryTableData.fulfilled, (state, action) => {
      state.adhocTableData = action.payload.data.data;
      state.pageNo = action.payload.data.pages;
      state.isTableDataLoading = true;
    });
    builder.addCase(getHistoryTableData.rejected, (state, action) => {});
  },
});

export { getHistoryTableData };

export default historyTableSlicer.reducer;
