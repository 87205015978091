import ctx from '../../../plugins/axios';

export default async (histPayload) => {
    try {
      let apiUrl = (histPayload.sysname === 'omms') ? '/getrecordhistory?omms_overview_detail_id' : '/bl-history?subcategory_id';
      const { data, status } = await ctx.get(`${apiUrl}=${histPayload.tacid}`);
      return { data, status };
    } catch (error) {
      return { error };
    }
};