import ctx from '../../../../plugins/axios';


export default async function updateBusinessUnit (payload) {
  try {
    const { data, status } = await ctx.put('/edit-buattributes', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

