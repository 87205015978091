// import { handleError, clearError } from '../utils';
import {getPendingRequest} from '../../../../api/actions/TxmyMgmt/requestForApproval/getPendingRequest';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  contentRfaBody: [],
  totalPages: 0,
  loading: false
};

// const reducers = {
//   SET_METADATA_TBODY(state, { payload }) {
//     console.log('testing')
//     state.contentTBody = payload;
//   },
// };
// async UPDATE_PENDINGSTATUS(context, payload) {
//   const { data, status } = await editPendingRequests(this.$axios, payload);
//   console.log('in actions');
//   if (status !== 200) {
//     handleError(data.data, context);
//   } else {
//     context.commit('EDIT_PENDING_REQUESTS_STATUS', data.data);
//     clearError(context);
//   }
// },

const fetchTacctRfaData = createAsyncThunk(
  "overview/fetchRfa",
  async (pageFilter) => {
    const response = await getPendingRequest(pageFilter);
    return response.data;
  }
);

const tacctRfaDataSlice = createSlice({
  name: "tacctApprovals",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchTacctRfaData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTacctRfaData.fulfilled, (state, action) => {
      let temp = JSON.parse(JSON.stringify(action.payload.data));
      state.contentRfaBody = temp.map((obj)=> {
        obj['check'] = false;
        
        // obj['toggleCheck'] = false;
        return obj
        
        });

        state.totalPages = action.payload.totalPages;
        state.loading = false;
    });
    builder.addCase(fetchTacctRfaData.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { fetchTacctRfaData };
export default tacctRfaDataSlice.reducer;
