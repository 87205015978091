import {
  LdsButton,
  LdsCard,
  LdsDatepicker,
  LdsIcon,
  LdsLink,
  LdsLoadingSpinner,
  LdsModal,
  LdsPagination,
  LdsSearch,
  LdsSelect,
  LdsTable,
  LdsToast,
  useLdsModal,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";
import ListDataModalGraph from "./ListDataModalGraph";
import { useDispatch } from "react-redux";
import { getModalReportsDetails } from "../../store/Components/listmgt/auditTrailTable";
import infoToastConfig from "../../data/utils/infoToastConfig";
import LdsSortableTable from "../../data/utils/SortableTable";
import "../../assets/components/list-management-auditing/listDataModal.scss";
import {downloadScrubbedList,downloadAuditList} from '../../store/Components/listmgt/auditTrailTable'

export default function ListDataModal({
  selectedCampaignId,
  islistModal,
  setIslistModal,
  listModalData,
  listDataModallistTypeLov,
  listDataModallistReceiver,
  contracting,
  execution,
  isAuditModal,
}) {
  const [modalTableData, setModalTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [listType, setListType] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedListType, setSelectedListType] = useState("");
  const [selectedReceiver, setSelectedReceiver] = useState("");
  let [isReportModalData, setIsReportModalData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, SetTotalPages] = useState(1);
  const [modalData, setModalData] = useState({});
  const {
    isModalOpen: isFinalCoundModal,
    setIsModalOpen: setIsFinalCountModal,
  } = useLdsModal();
  const dispatch = useDispatch();
  const [executionData, setExecutionData] = useState([]);
  const [contractingData, setContractingData] = useState([]);
  const [isPageNation, setIspagenation] = useState(true);
  const { addToast } = useToastContext();
  useEffect(() => {
    const totalPage = Math.ceil(listModalData.length / 10);
    SetTotalPages(totalPage);
    setCurrentPage(1);
    const start = (1 - 1) * 10;
    const end = start + 10;
    const listModallist = listModalData.slice(start, end) || [];
    selectedFilterDetails.currentPage = 1;
    listModallist.sort((a, b) => {
      const dateA = parseData(
        a.list_generate_date === "None"
          ? a.bia_drop_outbound
          : a.list_generate_date
      );
      const dateB = parseData(
        b.list_generate_date === "None"
          ? b.bia_drop_outbound
          : b.list_generate_date
      );
      return dateB - dateA;
    });
    setModalTableData(listModallist);
    setTempData(listModalData);
    setListType(listDataModallistTypeLov);
    setReceiver(listDataModallistReceiver);
  }, [listModalData]);
  useEffect(() => {
    const start = (1 - 1) * 10;
    const end = start + 10;
    selectedFilterDetails.currentPage = 1;
    const executionDatas = execution.slice(start, end) || [];
    const contractingDatas = contracting.slice(start, end) || [];
    setExecutionData(executionDatas);
    setContractingData(contractingDatas);
    setTimeout(() => {
      setIsReportModalData(true);
    }, 1000);
  }, [contracting, execution]);

  let [selectedFilterDetails, setSelectedFilterDetails] = useState({
    campaign_id: selectedCampaignId,
    list_type: "",
    receiver: "",
    search: "",
    start_date: "",
    end_date: "",
    currentPage: 1,
  });
  let filteredData = [...tempData];
  function selectedValueCmpId(searchText) {
    setSelectedListType("");
    setSelectedReceiver("");
    setCurrentPage(1);
    selectedFilterDetails.search = searchText;
    selectedFilterDetails.currentPage = 1;
    filterCampaingList();
  }
  function selectedValueListType(listType) {
    selectedFilterDetails.list_type = listType;
    selectedFilterDetails.currentPage = 1;
    setCurrentPage(1);
    filterCampaingList();
  }
  function selectedValueReceiver(receiver) {
    selectedFilterDetails.receiver = receiver;
    selectedFilterDetails.currentPage = 1;
    setCurrentPage(1);
    filterCampaingList();
  }
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  const formatDate = (dateString) => {
    const d = new Date(dateString);
    const y = d.getFullYear();
    const m = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${y}-${m}-${day}`;
  };
  const startDate = (startDate) => {
    if (startDate.type === "change") {
      let date = startDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        const convertedDate = formatDate(date);
        selectedFilterDetails.start_date = convertedDate;
      }
    } else {
      startDate = startDate.replaceAll("/", "-");
      const convertedDate = formatDate(startDate);
      selectedFilterDetails.start_date = convertedDate;
    }
    dateFiltering();
  };
  const endDate = (endDate) => {
    if (endDate.type === "change") {
      let date = endDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        const convertedDate = formatDate(date);
        selectedFilterDetails.end_date = convertedDate;
      }
    } else {
      endDate = endDate.replaceAll("/", "-");
      const convertedDate = formatDate(endDate);
      selectedFilterDetails.end_date = convertedDate;
    }
    dateFiltering();
  };
  function parseData(dateString) {
    return dateString ? new Date(dateString) : null;
  }
  function dateFiltering() {
    selectedFilterDetails.currentPage = 1;
    setCurrentPage(1);
    filterCampaingList();
  }
  let isPagenationData = true;
  async function clearFilters() {
    setSearchTerm("");
    setSelectedListType("");
    setSelectedReceiver("");
    filteredData = tempData;
    setCurrentPage(1);
    selectedFilterDetails.currentPage = 1;
    selectedFilterDetails.campaign_id = selectedCampaignId;
    selectedFilterDetails.list_type = "";
    selectedFilterDetails.receiver = "";
    selectedFilterDetails.search = "";
    selectedFilterDetails.start_date = "";
    selectedFilterDetails.end_date = "";
    isPagenationData = true;
    await filterCampaingList();
    isPagenationData = false;
  }
  const filterCampaingList = () => {
    if (isPagenationData) {
      fetchModalReportsData();
    }
    if (selectedFilterDetails.search !== "") {
      filteredData = filteredData.filter(
        (obj) =>
          obj.list_type
            .toLowerCase()
            .includes(selectedFilterDetails.search.toLowerCase()) ||
          obj.list_generate_date
            .toLowerCase()
            .includes(selectedFilterDetails.search.toLowerCase()) ||
          obj.receiver
            .toLowerCase()
            .includes(selectedFilterDetails.search.toLowerCase()) ||
          obj.bia_drop_outbound
            .toLowerCase()
            .includes(selectedFilterDetails.search.toLowerCase())
      );
      setModalTableData(filteredData);
    }
    if (selectedFilterDetails.list_type !== "") {
      filteredData = filteredData.filter((obj) =>
        obj.list_type
          .toLowerCase()
          .includes(selectedFilterDetails.list_type.toLowerCase())
      );

      setModalTableData(filteredData);
    }
    if (selectedFilterDetails.receiver !== "") {
      filteredData = filteredData.filter((obj) =>
        obj.receiver
          .toLowerCase()
          .includes(selectedFilterDetails.receiver.toLowerCase())
      );

      setModalTableData(filteredData);
    }
    // Date filter
    const parsedStartDate = parseData(selectedFilterDetails.start_date);
    const parsedEndDate = parseData(selectedFilterDetails.end_date);
    if (parsedStartDate && parsedEndDate) {
      if (parsedStartDate > parsedEndDate) {
        addToast(
          infoToastConfig({
            toastMessage: "start date is greater then end date",
            variant: "warning",
          })
        );
      }
    }
    if (parsedStartDate && parsedEndDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate =
          item.list_type === "target"
            ? parseData(item.bia_drop_outbound)
            : parseData(item.list_generate_date);
        return itemDate >= parsedStartDate && itemDate <= parsedEndDate;
      });
    } else if (parsedStartDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate =
          item.list_type === "target"
            ? parseData(item.bia_drop_outbound)
            : parseData(item.list_generate_date);
        return itemDate >= parsedStartDate;
      });
    } else if (parsedEndDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate =
          item.list_type === "target"
            ? parseData(item.bia_drop_outbound)
            : parseData(item.list_generate_date);
        return itemDate <= parsedEndDate;
      });
    }
    const totalPage = Math.ceil(filteredData.length / 10);
    // Calculating the starting index of the page
    SetTotalPages(totalPage);
    const start = (selectedFilterDetails.currentPage - 1) * 10;
    // Calculating the starting index of the page
    const end = start + 10;
    const modalTablelist = filteredData.slice(start, end) || [];

    if (!isPagenationData) {
      const executionDatas = execution.slice(start, end) || [];
      const contractingDatas = contracting.slice(start, end) || [];
      setExecutionData(executionDatas);
      setContractingData(contractingDatas);
      setTimeout(() => {
        setIsReportModalData(true);
      }, 400);
    }
    const tableDat = modalTablelist.sort((a, b) => {
      const dateA = parseData(
        a.list_generate_date === "None"
          ? a.bia_drop_outbound
          : a.list_generate_date
      );
      const dateB = parseData(
        b.list_generate_date === "None"
          ? b.bia_drop_outbound
          : b.list_generate_date
      );
      return dateB - dateA;
    });
    setModalTableData(tableDat);
  };
  async function clickLink(e, value) {
    selectedFilterDetails.currentPage = value;
    setIsReportModalData(false);
    isPagenationData = false;
    await filterCampaingList();
    isPagenationData = true;
  }
  async function clickNext() {
    selectedFilterDetails.currentPage = selectedFilterDetails.currentPage + 1;
    setIsReportModalData(false);
    isPagenationData = false;
    await filterCampaingList();
    isPagenationData = true;
  }
  async function clickPrev() {
    selectedFilterDetails.currentPage = selectedFilterDetails.currentPage - 1;
    setIsReportModalData(false);
    isPagenationData = false;
    await filterCampaingList();
    isPagenationData = true;
  }
  async function fetchModalReportsData() {
    setIsReportModalData(false);
    await dispatch(getModalReportsDetails(selectedFilterDetails));
  }
  async function downloadHCPsList(scrubbed_file_name) {
    const { payload } = await dispatch(downloadScrubbedList(scrubbed_file_name));
    const url = payload.url;
    if (url !== "") {
      window.location.href = url;
      addToast(
        infoToastConfig({
          toastMessage: "Downloaded successfully.",
          variant: "success",
        })
      );
    } else {
      addToast(
        infoToastConfig({
          toastMessage: "There is no data for selected filename.",
          variant: "informative",
        })
      );
    }
  }
  async function downloadLists(obj) {
    const { payload } = await dispatch(downloadAuditList(obj));
    const url = payload.url;
    if (url !== "") {
      window.location.href = url;
      addToast(
        infoToastConfig({
          toastMessage: "Downloaded successfully.",
          variant: "success",
        })
      );
    } else {
      addToast(
        infoToastConfig({
          toastMessage: "There is no data for selected filename.",
          variant: "informative",
        })
      );
    }
  }
  function finalCountModal(suppressionList) {
    setModalData(suppressionList);
    setIsFinalCountModal(true);
  }

  //sortable table function
  const [tableRowData, setTableRowData] = useState(modalTableData);
  useEffect(() => {
    setTableRowData(modalTableData);
  }, [modalTableData]);

  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }
  return (
    <>
      <LdsModal
        modalId="testModal"
        open={islistModal}
        modalSizeClass="col-11 col-md-10"
        setModalOpen={setIslistModal}>
        {listType.length > 0 && (
          <div className="row">
            <div className="d-flex justify-center mb-4">
              <div className="h b">{selectedCampaignId}</div>
            </div>
            <div className="col-4">
              <LdsSearch
                id={"searchSuggest"}
                placeholder="Search for Campaign ID"
                // disabled={!isauditTableloading}
                inputAriaLabel={null}
                isTypeAhead={true}
                onChange={setSearchTerm}
                onSubmit={selectedValueCmpId}
                value={searchTerm}
              />
            </div>
            <div className="col-2">
              <LdsButton onClick={clearFilters}>Clear</LdsButton>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <LdsDatepicker
                  dateFormat="MM/DD/YYYY"
                  id="testPicker"
                  label="Campaign Start Date"
                  //  disabled={!isauditTableloading}
                  value={selectedStartDate}
                  defaultValue={selectedStartDate}
                  onChange={(e) => {
                    setSelectedStartDate(e);
                    startDate(e);
                  }}
                />
              </div>
              <div className="col-3">
                <LdsDatepicker
                  dateFormat="MM/DD/YYYY"
                  id="testPicker"
                  label="Campaign End Date"
                  //  disabled={!isauditTableloading}
                  onChange={(e) => {
                    setSelectedEndDate(e);
                    endDate(e);
                  }}
                />
              </div>
              <div className="col-3">
                <LdsSelect
                  label="List Type"
                  id="listtype"
                  name="listtype"
                  options={listType}
                  onChange={(e) => {
                    setSelectedListType(e.value);
                    selectedValueListType(e.value);
                  }}
                  value={selectedListType}
                />
              </div>
              <div className="col-3">
                <LdsSelect
                  id="Receiver"
                  label="Receiver"
                  name="Receiver"
                  options={receiver}
                  onChange={(e) => {
                    setSelectedReceiver(e.value);
                    selectedValueReceiver(e.value);
                  }}
                  value={selectedReceiver}
                />
              </div>
            </div>
            {modalTableData.length > 0 ? (
              <div>
                {isReportModalData ? (
                  <>
                    <div>
                      <div className="mt-5 m-2">
                        <ListDataModalGraph
                          contracting={contractingData}
                          execution={executionData}
                        />
                      </div>
                      <LdsTable id="listmodal" className="mt-4 m-2">
                        {/* <thead>
                          <tr>
                            <th scope="col" className="align-left b" >List Type</th>
                            <th scope="col" className="align-left b" >Date</th>
                            <th scope="col" className="align-left b" >Receiver</th>
                            <th scope="col" className="align-left b" >Initil HCP Count</th>
                            <th scope="col" className="align-left b" >Final HCP Count</th>
                            <th scope="col" className="align-left b" >Percentage Deviation</th>
                          </tr>
                        </thead> */}
                        <LdsSortableTable
                          headAlign={"align-left b"}
                          header={[
                            "List Type",
                            "Date",
                            "Receiver",
                            "Initil HCP Count",
                            "Final HCP Count",
                            "%Deviation",
                            "Action",
                          ]}
                          rowData={modalTableData}
                          rowKeyName={[
                            "list_type",
                            "list_generate_date",
                            "receiver",
                            "initial_hcp_count",
                            "final_hcp_count",
                            "count_deviation",
                            "action",
                          ]}
                          sortedRowData={sortedRowData}
                        />
                        <tbody>
                          {tableRowData.map((entry, rowIndex) => (
                            <tr
                              role="row"
                              key={rowIndex}
                              className={
                                entry.flag === true ? "highlight" : ""
                              }>
                              <td role="cell" className="align-left">
                                {entry.list_type.toUpperCase()}
                              </td>
                              <td role="cell" className="align-left">
                                {" "}
                                {entry.list_type.toUpperCase() === "TARGET"
                                  ? entry.bia_drop_outbound.replaceAll("/", "-")
                                  : entry.list_generate_date.replaceAll(
                                      "/",
                                      "-"
                                    )}
                              </td>
                              <td role="cell" className="align-left">
                                {entry.receiver.toUpperCase()}
                              </td>
                              <td role="cell" className="align-left">
                                {entry.initial_hcp_count}
                              </td>
                              <td role="cell" className="align-left">
                                <LdsLink
                                  onClick={() => {
                                    finalCountModal(entry.suppression_details);
                                  }}>
                                  {entry.final_hcp_count}
                                </LdsLink>
                              </td>
                              <td role="cell" className="align-left">
                                {entry.count_deviation}
                              </td>
                              <td role="cell" className="align-left">
                                {entry.list_type.toUpperCase() ===
                                  "EXECUTION" ||
                                entry.list_type.toUpperCase() ===
                                  "CONTRACTING" ? (
                                  <>
                                    <LdsButton
                                      linkButton={true}
                                      className="downloadIcon"
                                      onClick={() => {
                                        downloadLists({
                                          list_type : entry.list_type,
                                          file_name : entry.filename,
                                          receiver : entry.receiver

                                        });
                                      }}>
                                      <div className="iconSize">
                                        <LdsIcon
                                          name="ArrowSquareOut"
                                          inline
                                          decorative
                                          label="Contracting or Execution List"
                                        />
                                      </div>
                                      <span className="textName">List</span>
                                    </LdsButton>
                                    <LdsButton
                                      linkButton={true}
                                      className="downloadIcon"
                                      onClick={() => {
                                        downloadHCPsList(entry.scrubbed_list_filename);
                                      }}>
                                      <div className="iconSize">
                                        <LdsIcon
                                          name="Files"
                                          inline
                                          decorative
                                          label="Scrubbed HCPs List"
                                        />
                                      </div>
                                      <span className="textName">
                                        Scrubbed HCPs
                                      </span>
                                    </LdsButton>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </LdsTable>
                    </div>
                    <LdsPagination
                      currentPage={currentPage}
                      onLinkClick={clickLink}
                      onNextClick={clickNext}
                      onPrevClick={clickPrev}
                      pageRangeVisible={3}
                      totalPages={totalPages}
                      setCurrentPage={setCurrentPage}
                    />
                    <LdsModal
                      modalId="testModal"
                      open={isFinalCoundModal}
                      setModalOpen={setIsFinalCountModal}
                      modalSizeClass="col-4 col-md-6"
                      className="p-4">
                      <LdsTable id="existingListTable">
                        <thead>
                          <tr>
                            <th scope="col" className="b align-center">
                              {" "}
                              Suppression Rule
                            </th>
                            <th scope="col" className="b align-center">
                              Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData !== null &&
                            Object.keys(modalData).map((dataList, index) => {
                              return (
                                <tr role="row" key={index}>
                                  <th
                                    role="rowheader"
                                    scope="row"
                                    className="align-center">
                                    {dataList.toUpperCase()}{" "}
                                  </th>
                                  <th
                                    role="rowheader"
                                    scope="row"
                                    className="align-center">
                                    {modalData[dataList]}
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </LdsTable>
                    </LdsModal>
                  </>
                ) : (
                  <LdsCard className="ta-center p-3 m-4 ">
                    <LdsLoadingSpinner size={50} />
                  </LdsCard>
                )}
              </div>
            ) : (
              <LdsCard className="ta-center p-3 m-1 mt-5">
                <p className="noRecord"> No Record Found </p>
              </LdsCard>
            )}
          </div>
        )}
        <LdsToast />
      </LdsModal>
    </>
  );
}
