import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTnOverview } from "../../../../api/actions/TxmyMgmt/tonality/getTnOverview";


const initialState = {
  tnBody: [],
  loading: false
};

const fetchTnData = createAsyncThunk(
  "overview/getTnOverview",
  async (queryparams) => {
    const response = await getTnOverview(queryparams);
    return response.data;
  }
);

const tnDataSlice = createSlice({
  name: "BUData",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchTnData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTnData.fulfilled, (state, action) => {
      state.tnBody = action.payload.data;
        state.loading = false;
    });
    builder.addCase(fetchTnData.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});
export { fetchTnData };
export default tnDataSlice.reducer;
