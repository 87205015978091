// CustomAccordion.js
import React, { useState } from 'react';
import '../assets/pages/Accordion.scss'; // Add your custom styles here

const Accordion = ({  id, title, isOpen, onToggle, children }) => {

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => onToggle(id)}>
        <p className='header-text'>{title}</p>
        <span>{isOpen ? '−' : '+'}</span>
      </div>
      {isOpen && (
        <div className="accordion-body">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
