// import { handleError, clearError } from '../utils';
import {getcontentEntry, getcontentEntry2} from '../../../../api/actions/TxmyMgmt/tacctBlOverview/getcontentEntry';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getentriesToExport } from '../../../../api/actions/TxmyMgmt/tacctBlOverview/getentriesToExport';



const initialState = {
  contentTBody: [],
  entriesToExport: [],
  totalPages: 0,
  loading: false
};

const getEntriesToExport = createAsyncThunk(
  "overview/getBLentriesToExport",
  async (queryparams) => {
    const response = await getentriesToExport(queryparams);
    return response.data;
  }
);


const fetchTacctData = createAsyncThunk(
  "overview/fetchOverview",
  async (queryparams) => {
    const response = await getcontentEntry(queryparams);
    return response.data;
  }
);
const fetchTacctData2 = createAsyncThunk(
  "overview/fetchOverview",
  async (queryparams) => {
    const response = await getcontentEntry2(queryparams);
    return response.data;
  }
);

const tacctDataSlice = createSlice({
  name: "overview",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchTacctData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTacctData.fulfilled, (state, action) => {
        state.contentTBody = action.payload.data;
        state.totalPages = action.payload.totalPages;
        state.loading = false;
    });
    builder.addCase(fetchTacctData.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { fetchTacctData, getEntriesToExport,fetchTacctData2};
export default tacctDataSlice.reducer;