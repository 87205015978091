import { LdsTabPanel, LdsTabs } from "@elilillyco/ux-lds-react";
import { useState } from "react";
import ResponseMatrix from "./ResponseMatrix.js";
import ResponseMatrixSummary from "./ResponseMatrixSummary.js";
// import ResponseMatrixSummary from "./ResponseMatrixSummary.js";

export default function ResponseTab() {
    const [currentTab, setCurrentTab] = useState(0);
    return (
        <>
            <div className="index-section">
                <LdsTabs
                    id="ResponseMatrix"
                    activeTab={currentTab}
                    onChange={(newIndex) => setCurrentTab(newIndex)}
                    tabLabels={[
                        { tabId: 0, label: "Response Matrix" },
                        { tabId: 1, label: "Response Matrix Summary" },
                    ]}
                >
                    <LdsTabPanel tabId={0} activeTab={currentTab}>
                        <div>
                            <ResponseMatrix />
                        </div>
                    </LdsTabPanel>
                    <LdsTabPanel tabId={1} activeTab={currentTab}>
                        <div>
                            <ResponseMatrixSummary />
                        </div>
                    </LdsTabPanel>
                </LdsTabs>
            </div>
        </>
    )
}