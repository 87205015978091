import ctx from "../../../../plugins/axios";

export default async (payload) => {
    try {
      const { data, status } = await ctx.post('/bl-metadata', payload);
      return { data, status };
    } catch (error) {
      return { error };
    }
  };
  
  