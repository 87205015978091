import ctx from "../../../plugins/axios";
/**
 * Get List Mgmt  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (selectedFilter) => {
  const queryStrcount = `/historydata?brand=${selectedFilter.brand}&vendor=${selectedFilter.vendor}&indication=${selectedFilter.indication}&brand_segment=${selectedFilter.brand_segment}&behavioral_segment=${selectedFilter.behavioral_segment}&status_detail=${selectedFilter.status_detail}&created_by=${selectedFilter.user_id}&downloadcsv=${selectedFilter.downloadcsv}&pageDataLimit=${selectedFilter.limit}&CurrentPage=${selectedFilter.pageNo}&start_date=${selectedFilter.start_date}&end_date=${selectedFilter.end_date}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};
