import React, { useEffect } from 'react'
import {
  LdsTile, LdsCard, LdsTable, LdsCheckbox, LdsIcon, LdsButton, LdsPagination, LdsTextField, LdsLoadingSpinner, LdsLink, LdsTextarea,
  LdsToastProvider,
  LdsToast,
} from "@elilillyco/ux-lds-react";
import { useState } from 'react';
import { LdsModal, useLdsModal, useToastContext } from "@elilillyco/ux-lds-react";
import HeaderNav from '../HeaderNav';
import "../../assets/components/OMMS/overview.scss"
import "../../assets/components/OMMS/reqforapproval.scss"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchVendorData } from '../../store/Components/OMMS/overview';
import { useDispatch, useSelector } from 'react-redux';
import { updateRequestForApproval } from '../../store/Components/OMMS/updateTactic';

export default function Overview(props) {
  const loader = useSelector(({ overview }) => overview.loader);
  const data = useSelector(({ overview }) => overview.vendorData)
  const tPages = useSelector(({ overview }) => overview.totalPages)
  const role2 = useSelector(({ user }) => user.role_ids);
  // const [checkedValue, setcheckedValue] = useState([])
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [editRole, seteditRole] = useState('');
  const [isstatus, setisStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const { addToast } = useToastContext();
  const { isModalOpen: isApproveModalOpen, setIsModalOpen: setApprovedIsModalOpen } = useLdsModal();
  const { isModalOpen: isRejectModalOpen, setIsModalOpen: setRejectIsModalOpen } = useLdsModal();
  const defaultRole = sessionStorage.getItem('role');
  const [isData, setIsData] = useState([...data])
  const [getId, setId] = useState();
  const [isChecked, setIsChecked] = useState(false)
  const dispatch = useDispatch();

  const [curTacticId, setCurTacticId] = useState(null);
  useEffect(() => {
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
      pageName: 'approvalPage',
    }))
  }, [])
  useEffect(() => {
    setIsData([...data]);
  }, [data]);

  useEffect(() => {
    setId(
      isData
        ?.filter((item) => item?.check === true)
        .map((item) => item?.omms_overview_detail_id)
    );
  }, [isData]);
  const [lastEvent, setLastEvent] = useState('');
  const totalPages = tPages
  const [currentPage, setCurrentPage] = useState(1);
  const records = data
  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: value,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
      pageName: 'approvalPage',
    }));
  };
  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: currentPage + 1,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
      pageName: 'approvalPage',
    }));
  };

  // Called when you click the "previous" link/button
  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: currentPage - 1,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
    }));
  };

  const lastPage = () => {
    dispatch(fetchVendorData({
      pageNumber: totalPages,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
      pageName: 'approvalPage',
    }));
  };
  const firstPage = () => {
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 10,
      role: defaultRole,
      page: 'rfa',
      pageName: 'approvalPage',
    }));
  };

  function checkBIArole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omms_peer_review_bia' || role === 'omms_admin') {
      return true;
    }
    return false;
  }

  function checkCAProle() {
    const role = sessionStorage.getItem('role');
    if (role === 'omms_peer_review_campaign' || role === 'omms_admin') {
      return true;
    }
    return false;
  }

  function checkMKTGrole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omms_peer_review_channel' || role === 'omms_admin') {
      return true;
    }
    return false;
  }

  // function reqforapproval () {
  //   window.location.href = '/omms/requestforapproval';
  // }
  function handleClick() {
    setApprovedIsModalOpen(false)
  }
  function rejectHandleClick() {
    setRejectIsModalOpen(false)
  }
  function checkedData(e) {
    const value = e.target.id;
    const checked = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    for (let obj of tempData) {
      if (obj.omms_overview_detail_id.toString() === value) {
        obj.check = checked;
      }
    }
    setIsData(tempData);
  }
  function modalOpen() {
    if (getId?.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    else {
      setApprovedIsModalOpen(true)
    }
  }

  function approveTactic() {
    const payload = {
      requestData: {
        status: isstatus,
        role: editRole,
        user_id: getCurrentUserId(),
        omms_overview_detail_id: getId
      },
    };
    dispatch(updateRequestForApproval(payload));
    setTimeout(() => window.location.href = '/omms', 1000)
  }

  function rejectTactic() {
    if (remarks.length === 0) {
      addToast({
        toastMessage: "Please add reject comments!!",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }

    else {
      const payload = {
        requestData: {
          status: isstatus,
          role: editRole,
          user_id: getCurrentUserId(),
          omms_overview_detail_id: getId,
          remarks: remarks
        }
      }
      dispatch(updateRequestForApproval(payload));
      setTimeout(() => window.location.href = '/omms', 1000)
    };
    setRejectIsModalOpen(true)
  };

  function getCurrentUserId() {
    const currentRole = sessionStorage.getItem('role');
    const currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    return currentUserData[0][currentRole];
  }

  function rejectmodalOpen() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    setRejectIsModalOpen(true)
  }
  const [check, setCheck] = useState(false)
  function handleMainChange(e) {
    const isChecking = e.target.checked
    setIsChecked(isChecking)
    const tempData = JSON.parse(JSON.stringify(isData))
    const data = tempData.map((obj) => {
      obj.check = isChecking
      return obj
    })
    setIsData(data)
  }
  return (
    <>
      <HeaderNav msg={"Omnichannel Metadata Management System"} />
      <LdsTile className='overview-hero' >
        <div className="overview-container">

          <LdsLink href="/omms">{'<'} Back to Overview </LdsLink>
        </div>
      </LdsTile>

      <LdsTable style={{ minHeight: '17rem' }} >
        <thead>
          <tr>

            <th className="table-ends" scope="col">
              <LdsCheckbox
                label="Select All "
                onChange={(e) => { handleMainChange(e) }}
                checked={isChecked}
                style={{ marginTop: '-1.5rem' }} /></th>
            <th scope="col" className="table-ends">
              <div class="attriDiv">
                Status
              </div>

            </th>
            <th scope="col" className="table-ends" >
              <div class="attriDiv">
                Delivery Source
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Supplier Name
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Platform
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Contact Type
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Delivery Channel
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Delivery Source Type
              </div>
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Metric Label
              </div>

            </th>



      <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Engagement Classification
              </div>

            </th>

            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Hierarchy Classification
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Metric Definition
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Engagement Depth
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Contract Basis Classification
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Performance Basis Classification
              </div>

            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDiv">
                Engagement Rate Calculated Against
              </div>
            </th>
            <th className="table-ends" scope="col">
              <div class="attriDiv">
              Engagement Based Tactic
              </div>
              </th>
            <th className="table-ends" scope="col">Cap</th>
            <th className="table-ends" scope="col">BIA APPROVED</th>
            <th className="table-ends" scope="col">CAP APPROVED</th>
            <th className="table-ends" scope="col">MKTG APPROVED</th>
          </tr>
        </thead>
        {!loader ? (data && data.length > 0 ? (
          <tbody style={{ zIndex: '1' }}>
            {isData.map((entry, rowIndex) =>
              <tr role="row" key={rowIndex}>

                <td className="table-ends">
                  <LdsCheckbox name="opt1"
                    id={entry.omms_overview_detail_id}
                    onChange={(e) => { checkedData(e) }}
                    value={entry.omms_overview_detail_id}
                    checked={entry.check} />
                </td>
                <td className='table-ends'>
                  <div>
                    {entry.isUpdated ? "UPDATED" : "NEW"}
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'deliverySource') || entry.updatedFields.includes(
                          'delivery_source')
                        ? 'yellow' : 'transparent'
                    }}>{entry.deliverySource}</span>
                  </div>
                </td>
                <td
                  className='table-ends'>
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'supplierName') || entry.updatedFields.includes(
                          'supplier_name')
                        ? 'yellow' : 'transparent'
                    }}>{entry.supplierName}</span>
                  </div>
                </td>
                <td
                  className='table-ends'>
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'platform')
                        ? 'yellow' : 'transparent'
                    }}>{entry.platform}</span>
                  </div>
                </td>
                <td
                  className='table-ends'>
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'contactType') || entry.updatedFields.includes(
                          'contact_type')
                        ? 'yellow' : 'transparent'
                    }}>{entry.contactType}</span>
                  </div>
                </td>
                <td
                  className='table-ends'>
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'deliveryChannel') || entry.updatedFields.includes(
                          'delivery_channel')
                        ? 'yellow' : 'transparent'
                    }}>{entry.deliveryChannel}</span>
                  </div>
                </td>
                <td
                  className='table-ends'>
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'deliverySourceType') || entry.updatedFields.includes(
                          'delivery_source_type')
                        ? 'yellow' : 'transparent'
                    }}>{entry.deliverySourceType}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'metricLabel' || 'metric_label')
                        ? 'yellow' : 'transparent'
                    }}>{entry.metricLabel}</span>
                  </div>
                </td>



                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        "engagementClassification" || 'engagement_classification')
                        ? 'yellow' : 'transparent'
                    }}>{entry.engagementClassification}</span>
                  </div>
                </td>

                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'hierarchyClassification' || 'hierarchy_classification')
                        ? 'yellow' : 'transparent'
                    }}>{entry.hierarchyClassification}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'metricDefinition' || 'metric_definition')
                        ? 'yellow' : 'transparent'
                    }}>{entry.metricDefinition}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'engagementDepth' || 'engagement_depth')
                        ? 'yellow' : 'transparent'
                    }}>{entry.engagementDepth}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'contractBasisClassification' || 'contract_basis_classification')
                        ? 'yellow' : 'transparent'
                    }}>{entry.contractBasisClassification}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'performanceBasisClassification' || 'performance_basis_classification')
                        ? 'yellow' : 'transparent'
                    }}>{entry.performanceBasisClassification}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'engagementRateCalculatedAgainst' || 'engagement_rate_calculated_against')
                        ? 'yellow' : 'transparent'
                    }}>{entry.engagementRateCalculatedAgainst}</span>
                  </div>
                </td>
                <td
                  className='table-ends' >
                  <div >
                    <span style={{
                      background: entry.updatedFields.includes(
                        'engagementBasedTactic' || 'engagement_based_tactic')
                        ? 'yellow' : 'transparent'
                    }}>{entry.engagementBasedTactic}</span>
                  </div>
                </td>
                <td className='table-ends'>{entry.isCap ? "*" : "-"}</td>
                <td className='table-ends'>
                  {entry.biaApproveStatus === "APPROVED" ? "\u2714" : ""}
                </td>
                <td className='table-ends'>
                  {entry.capApproveStatus === "APPROVED" ? "\u2714" : ""}
                </td>
                <td className='table-ends'>
                  {entry.mktgApproveStatus === "APPROVED" ? "\u2714" : ""}
                </td>

              </tr>)}
          </tbody>) : (<tbody>
            <div>
              <span className='no-data'>No Data Available</span>
            </div>
          </tbody>
        )
        ) : <LdsLoadingSpinner class='loader' size={50} animationSpeed={500} />}
      </LdsTable>
      <div className="d-flex">
        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton>

        <LdsPagination
          onLinkClick={clickLink}
          onNextClick={clickNext}
          onPrevClick={clickPrev}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          pageRangeVisible={5}
        />
        <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton>

      </div>

      <div className='approve-sec'>
        {checkBIArole() &&
          <div className='approve'>
            <h4>BIA</h4>
            <div className='approve-buttons'>
              <LdsIcon className='approve-icon' onClick={() => { modalOpen(); seteditRole('omms_peer_review_bia'); setisStatus('APPROVED') }} name='CheckCircle' label='Approve' />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to Approve this record?"
              >

                <div className="space">
                  <LdsButton onClick={() => { handleClick(); approveTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { handleClick() }}>No</LdsButton>
                </div>

              </LdsModal>

              <LdsIcon className='approve-icon' onClick={() => { rejectmodalOpen(); seteditRole('omms_peer_review_bia'); setisStatus('REJECTED') }} name="XCircle"
                label="Reject" />
              {/* <LdsToast/> */}
              <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these tactics?"

              > <div>
                  <LdsTextarea
                    id='charCountTextarea'
                    name='charCountTextarea'
                    label='Please provide comment for rejection'
                    rows='3'
                    maxLength='200'
                    counterText="/200"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); rejectTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { rejectHandleClick() }}>No</LdsButton>
                </div>

              </LdsModal>
              <LdsToast />
            </div>
          </div>}
        {checkCAProle() &&
          <div className='approve'>
            <h4>CAP</h4>
            <div className='approve-buttons'>
              <LdsIcon className='approve-icon' onClick={() => { modalOpen(); seteditRole('omms_peer_review_campaign'); setisStatus('APPROVED') }} name='CheckCircle' label='Approve' />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to Approve this record?"
              >

                <div className="space">
                  <LdsButton onClick={() => { approveTactic(); handleClick() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { handleClick() }}>No</LdsButton>
                </div>

              </LdsModal>
              <LdsIcon className='approve-icon' onClick={() => { rejectmodalOpen(); seteditRole('omms_peer_review_campaign'); setisStatus('REJECTED') }} name="XCircle"
                label="Reject" />
              <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these tactics?"

              > <div>
                  <LdsTextarea
                    id='charCountTextarea'
                    name='charCountTextarea'
                    label='Please provide comment for rejection'
                    rows='3'
                    maxLength='200'
                    counterText="/200"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); rejectTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { rejectHandleClick() }}>No</LdsButton>
                </div>

              </LdsModal>
              <LdsToast />
            </div>
          </div>}
        {/* <div className='approve-sec'> */}
        {checkMKTGrole() &&
          <div className='approve'>
            <h4>MKTG</h4>
            <div className='approve-buttons'>
              <LdsIcon className='approve-icon' onClick={() => { modalOpen(); seteditRole('omms_peer_review_channel'); setisStatus('APPROVED') }} name='CheckCircle' label='Approve' />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to Approve this record?"
              >

                <div className="space">
                  <LdsButton onClick={() => { handleClick(); approveTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { handleClick() }}>No</LdsButton>
                </div>

              </LdsModal>

              <LdsIcon className='approve-icon' onClick={() => { rejectmodalOpen(); seteditRole('omms_peer_review_channel'); setisStatus('REJECTED') }} name="XCircle"
                label="Reject" />
              {/* <LdsToast /> */}
              <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these tactics?"

              > <div>

                  <LdsTextarea
                    id='charCountTextarea'
                    name='charCountTextarea'
                    label='Please provide comment for rejection'
                    rows='3'
                    maxLength='200'
                    counterText="/200"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); rejectTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => rejectHandleClick()}>No</LdsButton>
                </div>

              </LdsModal>
              <LdsToast />
            </div>
          </div>}
      </div>

    </>
  )


}