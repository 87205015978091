import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addGrammetical from "../../../../api/actions/TxmyMgmt/grammaticalForm/addGrammetical";
import getGrammeticalbyID from "../../../../api/actions/TxmyMgmt/grammaticalForm/getGrammeticalbyID";
import updateGrammetical from "../../../../api/actions/TxmyMgmt/grammaticalForm/updateGrammetical";

const initialState = {
  gfEntryLoader: false,
  errMsg:'',
  gfDataForEdit: {},
}

const CREATE_GRAMMETICAL_FORM = createAsyncThunk(
  "overview/addGrammetical",
  async (payload, { rejectWithValue }) => {
    const response = await addGrammetical(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const FETCH_GRAMMETICAL_FORM_BY_ID = createAsyncThunk(
  "overview/getGrammeticalbyID",
  async (id, { rejectWithValue }) => {
    const response = await getGrammeticalbyID(id);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const UPDATE_GRAMMETICAL_FORM = createAsyncThunk(
  "overview/updateGrammetical",
  async (payload, { rejectWithValue }) => {
    const response = await updateGrammetical(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const gfEntrySlice = createSlice({
  name: "gfEntrySlice",
  initialState,
  extraReducers(builder) {
    builder.addCase(CREATE_GRAMMETICAL_FORM.pending, (state, action) => {
        state.gfEntryLoader = true;
    });
    builder.addCase(CREATE_GRAMMETICAL_FORM.fulfilled, (state, action) => {
        state.gfEntryLoader = false; 
        state.errMsg = '';
    });
    builder.addCase(CREATE_GRAMMETICAL_FORM.rejected, (state, action) => {
        state.gfEntryLoader = false;
        state.errMsg = 'an error occurred!'
    });
    builder.addCase(FETCH_GRAMMETICAL_FORM_BY_ID.pending, (state, action) => {
      state.gfEntryLoader = true;
    });
    builder.addCase(FETCH_GRAMMETICAL_FORM_BY_ID.fulfilled, (state, action) => {
        state.gfEntryLoader = false;
        state.gfDataForEdit = action.payload.data[0];
        state.errMsg = '';
        // console.log('state.buDataForEdit: ', state.buDataForEdit);
    });
    builder.addCase(FETCH_GRAMMETICAL_FORM_BY_ID.rejected, (state, action) => {
        state.gfEntryLoader = false;
        state.errMsg = 'an error occurred while fetching!'
    });
    builder.addCase(UPDATE_GRAMMETICAL_FORM.pending, (state, action) => {
      state.gfEntryLoader = true;
    });
    builder.addCase(UPDATE_GRAMMETICAL_FORM.fulfilled, (state, action) => {
        state.gfEntryLoader = false;
        state.errMsg = '';
    });
    builder.addCase(UPDATE_GRAMMETICAL_FORM.rejected, (state, action) => {
        state.gfEntryLoader = false;
        state.errMsg = 'an error occurred while updating!'
    });
  },
});

export {
    UPDATE_GRAMMETICAL_FORM,
    FETCH_GRAMMETICAL_FORM_BY_ID,
    CREATE_GRAMMETICAL_FORM,
}

export default gfEntrySlice.reducer;