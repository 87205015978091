import ctx from '../../../../plugins/axios';


export default async function getBusinessUnitById (id) {
  try {
    const { data, status } = await ctx.get(`/edit-buattributes?business_id=${id}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

