/**
 * Get Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

const getFiltersData = async () => {
      const [adhocLov, adhoccampaignlist] = await Promise.all([ctx.get('/adhoclov'), ctx.get('/adhoccampaignlist')]);
      const { data: adhocLovData, status: adhocLovStatus } = adhocLov;
      const { data: adhoccampaignlistData, status: adhoccampaignlistStatus } = adhoccampaignlist;
      return {
        adhocLovData, adhocLovStatus, adhoccampaignlistData, adhoccampaignlistStatus,
      };
  };
export default getFiltersData;
  