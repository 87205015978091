import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addRules,
  fileValidationCheck,
  getAlignPair,
  getCampaignResult,
  getExistingList,
  getFiltersData,
} from "../../api/actions/adhocScrubbing";

const initialState = {
  brand: [],
  vendor: [],
  indication: [],
  brand_target_segment: [],
  behavioral_segment: [],
  campaignList: [],
  listTypes: [
    { value: "", label: "Select" },
    { value: "execution_list", label: "EXECUTION" },
    { value: "contracting_list", label: "CONTRACTING" },
  ],
  loading: false,
  listStatus: false,
  submitLoader: false,
  initialHcpCount: 0,
  modalData: [],
  campaignLoader: false,
  campaignDataResult: {
    product:'',
    disease_state_indication: '',
    vendor:''
  },
  postLoader: false,
  emptyList: 0,
  adhocLovInitialValues: {},
  isPairListLoad: false,
  withOutmarketVendor: [],
  withMarketvendor: [],
  dropdown:{
    brand: true,
    indication: true,
    brandSegment: true,
    behavioralSegment: true
  },
  isFileVerified: true,
  isVarifiedFileApi : true,
};

function setAdhocLoves(
  state,
  { brand, indication, brandSegment, behavioralSegment }
) {
  if(state.dropdown.brand){
    brand.sort();
  state.brand = brand.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.brand.unshift({
    value: "",
    label: "Select",
  });
  }
  if(state.dropdown.indication){
    indication.sort();
  state.indication = indication.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.indication.unshift({
    value: "",
    label: "Select",
  });
  }
  if(state.dropdown.brandSegment){
    brandSegment.sort();
  state.brand_target_segment = brandSegment.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.brand_target_segment.unshift({
    value: "",
    label: "Select",
  });
  }
  if(state.dropdown.behavioralSegment){
    behavioralSegment.sort();
  state.behavioral_segment = behavioralSegment.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.behavioral_segment.unshift({
    value: "",
    label: "Select",
  });
  }
}

function setVendorValues(state, vendor){  
  vendor.sort();
  state.vendor = vendor.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.vendor.unshift({
    value: "",
    label: "Select",
  });
}

const getFilters = createAsyncThunk("adhoc/filters", async () => {
  const response = await getFiltersData();
  return response;
});

const checkList = createAsyncThunk("adhoc/checkList", async (selected) => {
  const response = await getExistingList(selected);
  return response.data;
});

const getCampaign = createAsyncThunk(
  "adhoc/campaignData",
  async (campaignId) => {
    const response = await getCampaignResult(campaignId);
    return response.data;
  }
);

const postData = createAsyncThunk("adhoc/cpostData", async (selectedData) => {
  const response = await addRules(selectedData);
  return response.data;
});

const getAlignPairData = createAsyncThunk(
  "adhoc/AlignPairData",
  async (selectedData) => {
    const response = await getAlignPair(selectedData);
    return response.data;
  }
);

const validationCheck = createAsyncThunk(
  "adhocUploadedFileValidation",
  async ( {fileStream,controller} ) => {
    const response = await fileValidationCheck(fileStream,controller);
    return response.data;
  }
)

const filtersSlicer = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setAdhocLovsClear(state, action) {
      setAdhocLoves(state, {
        brand: state.adhocLovInitialValues.brand,
        indication: state.adhocLovInitialValues.indication,
        brandSegment: state.adhocLovInitialValues.brand_target_segment,
        behavioralSegment: state.adhocLovInitialValues.behavioral_segment,
      });
    },
    fileUploadedSetVender(state, action ){
      if( action.payload.isFileSelected){
        setVendorValues(state, state.withMarketvendor);
        return;
      }
      setVendorValues(state ,state.withOutmarketVendor);      
    },
    findSelectedDropDown(state, action){
      state.dropdown = action.payload;
    },
    setIsVarifiedFileApi(state, action){
      state.isVarifiedFileApi = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getFilters.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.adhocLovInitialValues = action.payload.adhocLovData.data;
      setAdhocLoves(state, {
        brand: action.payload.adhocLovData.data.brand,
        indication: action.payload.adhocLovData.data.indication,
        brandSegment: action.payload.adhocLovData.data.brand_target_segment,
        behavioralSegment: action.payload.adhocLovData.data.behavioral_segment,
      });

      const vendor = action.payload.adhocLovData.data.vendor;
      state.withMarketvendor = JSON.parse(JSON.stringify(vendor));
      vendor.splice(vendor.indexOf("Market Research"),1);
      state.withOutmarketVendor = vendor;
      setVendorValues(state, vendor);

      state.campaignList =
        action.payload.adhoccampaignlistData.data.verso_campaign_id;
      state.loading = true;
    });
    builder.addCase(getFilters.rejected, (state, action) => {
      state.brand = [{ value: "", label: "Select" }];
      state.indication = [{ value: "", label: "Select" }];
      state.brand_target_segment = [{ value: "", label: "Select" }];
      state.behavioral_segment = [{ value: "", label: "Select" }];
      state.listTypes = [{ value: "", label: "Select" }];
      state.vendor = [{ value: "", label: "Select" }];
      state.loading = true;
      state.campaignLoader = true;
    });

    builder.addCase(checkList.pending, (state, action) => {
      state.submitLoader = true;
    });
    builder.addCase(checkList.fulfilled, (state, action) => {
      state.listStatus = action.payload.list_exist;
      state.initialHcpCount = action.payload.initial_hcp_count;
      state.modalData = action.payload.data;
      state.submitLoader = false;
    });
    builder.addCase(checkList.rejected, (state, action) => {
      state.submitLoader = true;
    });

    builder.addCase(getCampaign.pending, (state, action) => {
      state.campaignLoader = true;
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.campaignDataResult = action.payload.data[0];
      state.campaignLoader = false;
    });
    builder.addCase(getCampaign.rejected, (state, action) => {
      state.campaignLoader = true;
    });

    builder.addCase(postData.pending, (state, action) => {});
    builder.addCase(postData.fulfilled, (state, action) => {});
    builder.addCase(postData.rejected, (state, action) => {});

    builder.addCase(getAlignPairData.pending, (state, action) => {
      state.campaignLoader = true;
    });
    builder.addCase(getAlignPairData.fulfilled, (state, action) => {
      let objectOfList = [];
      objectOfList = action.payload.data.reduce((acc, obj) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj[key]);
        }
        return acc;
      }, {});
      Object.entries(objectOfList).forEach(([key, value]) => {
        objectOfList[key] = [...new Set(value)];
      });
      if (action.payload.totalcount === 0) {
        state.emptyList = action.payload.totalcount;
        // setAdhocLoves(state, {
        //   brand: state.adhocLovInitialValues.brand,
        //   indication: state.adhocLovInitialValues.indication,
        //   brandSegment: state.adhocLovInitialValues.brand_target_segment,
        //   behavioralSegment: state.adhocLovInitialValues.behavioral_segment,
        // });
        state.campaignLoader = false;
        return;
      }

      setAdhocLoves(state, {
        brand: objectOfList.brand,
        indication: objectOfList.indication,
        brandSegment: objectOfList.brand_target_segment,
        behavioralSegment: objectOfList.behavioral_segment,
      });
      state.campaignLoader = false;
    });
    builder.addCase(getAlignPairData.rejected, (state, action) => {
      state.campaignLoader = false;
    });

    builder.addCase(validationCheck.pending, (state, action ) => {
      state.isFileVerified = false;
      state.isVarifiedFileApi = false;
    });
    builder.addCase(validationCheck.fulfilled, (state, action ) => {
      state.isFileVerified = action.payload.fileValidated;
      state.isVarifiedFileApi = true;
    });
    builder.addCase(validationCheck.rejected, (state, action ) => {
      state.isFileVerified = false;
      state.isVarifiedFileApi = null;
    });
  },
});

export { getFilters, checkList, getCampaign, postData, getAlignPairData, validationCheck };
export const { setAdhocLovsClear, fileUploadedSetVender, findSelectedDropDown, setIsVarifiedFileApi } = filtersSlicer.actions;
export default filtersSlicer.reducer;
