import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import HeaderNav from '../../../../components/HeaderNav';
import { LdsIcon, LdsLink, LdsLoadingSpinner, LdsTextField, LdsValidationError, LdsToast, LdsModal, LdsButton, useToastContext,useLdsModal } from '@elilillyco/ux-lds-react';
import infoToastConfig from '../../../../data/utils/infoToastConfig';
import { CREATE_BUSINESS_UNIT, FETCH_BUSINESS_UNIT_BY_ID, UPDATE_BUSINESS_UNIT } from '../../../../store/Components/TACCT/BU/buentry';
import getCurrentUserId from '../../../../data/utils/getCurrentUserId';
import '../../../../assets/components/OMMS/addmetadata.scss'

export default function BUEntry() {
    const { isModalOpen: isConfirmModalOpen, setIsModalOpen: setIsConfirmModalOpen } = useLdsModal(false);
    const [pageError, setPageError] = useState({});
    const { addToast } = useToastContext();
    const [buPayload, setBUPayload] = useState({});
    const { buEntryLoader, buDataForEdit } = useSelector(({ buEntrySlice }) => buEntrySlice);
    const role = useSelector(({user}) => user.role_ids);
    const dispatch = useDispatch();
    const [id, setId] = useState();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        business_unit: '',
        business_unit_abbreviation: ''
    })
    const [getstatus, setStatus] = useState()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('id')) {
            setId(urlParams.get('id'));
        }
    }, [])

    useEffect(() => {
        if(id) {
            dispatch(FETCH_BUSINESS_UNIT_BY_ID(id));
        }
    }, [id, dispatch])

    useEffect(() => {
        const buDetails = {
            'business_unit': buDataForEdit.business_unit,
            'business_unit_abbreviation': buDataForEdit.business_unit_abbreviation
        }
        setFormValues(buDetails);
    }, [buDataForEdit])

    const HandleBUFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const submitValues = [];
        const payloadData = {};
        const checkIfAnyValidationError = Object.values(pageError).some((ele) => ele.length > 0)
        if (checkIfAnyValidationError) {
            return addToast(infoToastConfig({toastMessage: "Please check below validation error!", variant: "error"}))
        }
        for (const value of formData.keys()) {
            submitValues.push(formData.get(value))
        }
        const checkIfAnyNull = submitValues.some((ele) => ele.length === 0)
        if (checkIfAnyNull) {
            return addToast(infoToastConfig({toastMessage: "Please fill all attribute!", variant: "error"}))
        }
        for (const item of formData.entries()) {
            payloadData[item[0]] = item[1]
        }
        payloadData['created_by'] = id ? buDataForEdit.created_by : getCurrentUserId(role);
        payloadData['status'] =  document.activeElement.value === 'submit-btn' ? 'PENDING' : 'DRAFT';

        if(id) {
            payloadData['business_id'] = buDataForEdit.business_unit_overview_detail_id;
            payloadData['updated_by'] = getCurrentUserId(role);
        }
        let payloadToUse = { "bu" : [payloadData] }
        if(id) {
            payloadToUse = { "editValues" : payloadData }
        }
        setBUPayload(payloadToUse);
        setIsConfirmModalOpen(true);
    }

    const callBUEntryApi = async (e) => {
        let isPageErr = false;
        document.getElementById("BUEntryForm").addEventListener('submit', (e => {
            e.preventDefault();
        }));
        const callMeta = async () => {
            if(id) {
                const { meta, payload } = await dispatch(UPDATE_BUSINESS_UNIT(buPayload));
                return { meta, payload };
            }
            else {
                const { meta, payload } = await dispatch(CREATE_BUSINESS_UNIT(buPayload));
                return { meta, payload };
            }
        }
        
        setIsConfirmModalOpen(false);

        const { meta, payload } = await callMeta();
        if (meta.rejectedWithValue) {
            isPageErr = true;
            addToast(infoToastConfig({toastMessage: `Error- ${payload.data.message}`, variant: 'error'}))
        } else {
            if(payload.message === "Already Exists") {
                isPageErr = true;
                addToast(infoToastConfig({toastMessage: payload.message, variant: 'warning'})); 
            }
            else {
                addToast(infoToastConfig({toastMessage: 'Added Successfully', variant: 'success'})); 
            }
        }

        if(!isPageErr) {
            setFormValues({
                business_unit: '',
                business_unit_abbreviation: ''
            })
            setTimeout(() => {
                return navigate('/tacct/business-unit');
            }, 1000);
        }
        return;
    }

    const handleChange = (e) => {
        // e.target.value = e.target.value.toUpperCase();
        e.target.value.match(/[^a-zA-Z0-9\s_-]/) ? setPageError((prevError) => ({...prevError, [e.target.id]: 'Special characters not allowed'})) : setPageError((prevError) => ({...prevError, [e.target.id]: ''}));
        setFormValues(prevFormData => {
            return {
                ...prevFormData,
                [e.target.name]: e.target.value
            }
        })
    }  

  return(
    <>
            <HeaderNav msg={"Business Unit"} />
            <div>
                <LdsLink href="/tacct/business-unit">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <div align='center'>
                <h2> {id ? 'Edit' : 'Enter New'} Business Unit </h2>
            </div>
            {buEntryLoader &&
                <div className='bu-spinner'>
                <LdsLoadingSpinner size={90} />
                </div>
            }
            {!buEntryLoader &&
                <form id="BUEntryForm" onSubmit={HandleBUFormSubmit}>
                    <div align='center'>
                        <div className="col-6 col-md-4 lds-form-field row-field">
                            <LdsTextField
                                className='text-input col-md-6'
                                id='business_unit'
                                name='business_unit'
                                label='Business Unit'
                                value={formValues.business_unit}
                                error={
                                    pageError.business_unit && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.business_unit
                                && <LdsValidationError id="errCategory">{pageError.business_unit}</LdsValidationError>
                            }
                            <br/>
                            <LdsTextField
                                className='text-input'
                                id='business_unit_abbreviation'
                                name='business_unit_abbreviation'
                                label='Business Unit abbreviation'
                                value={formValues.business_unit_abbreviation}
                                error={
                                    pageError.business_unit_abbreviation && true
                                }
                                onChange={(e) => handleChange(e)}
                            />
                            {
                                pageError.business_unit_abbreviation
                                && <LdsValidationError id="errCategory">{pageError.business_unit_abbreviation}</LdsValidationError>
                            }
                            <br/>
                            <div className='save'>
                                <LdsButton type="submit" name='draft-btn' id='draft-btn' value='draft-btn' onClick={()=>setStatus('draft')}>Draft</LdsButton>
                                <LdsButton type="submit" name='submit-btn' id='submit-btn' value='submit-btn' onClick={()=>setStatus('add')}>{id ? 'Edit' : 'Add'}</LdsButton>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                </form>
            }
                <LdsToast style={{zIndex:'3'}}/>
                <LdsModal
                    modalId="confirmationModal"
                    open={isConfirmModalOpen}
                    setModalOpen={setIsConfirmModalOpen}
                    persistent
                >
                <h1>Are you sure you want to {getstatus==='draft' ? 'draft' : 'add' } these attributes</h1>
                    <div className="space">
                    <LdsButton onClick={async (e) => { await callBUEntryApi(e)}}>Yes</LdsButton>
                    <LdsButton onClick={() => setIsConfirmModalOpen(false)}>No</LdsButton>
                    </div>
                </LdsModal>
        </>
  )
}
