import { updateTonalityApproval } from "../../../../api/actions/TxmyMgmt/tonality/updateTonalityApproval";
import { createAsyncThunk } from "@reduxjs/toolkit";

const UPDATE_TN_APPROVAL_DATA = createAsyncThunk(
  "overview/updateTonalityApproval",
  async (payload) => {
    const response = await updateTonalityApproval(payload);
    return response.data;
  }
);

export { UPDATE_TN_APPROVAL_DATA };