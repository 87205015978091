import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addMetadata } from '../../../api/actions/metadata/addMetaData';


export const initialState = {
    errorMessage: '',
    loader: false
}

const addMetaData = createAsyncThunk(
  "addMetadata/addMetaDataVals",
  async (payload, { rejectWithValue }) => {
    const response = await addMetadata(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);
// console.log(fetchMetaData);

  const addMetaDataSlice = createSlice({
    name: 'AddMetaData',
    initialState,
    extraReducers(builder) {
      builder.addCase(addMetaData.fulfilled, (state, action) => {
        state.loader = false;
        state.errorMessage = '';
      });
      builder.addCase(addMetaData.pending, (state, action) => {
        state.loader = true;
      });
      builder.addCase(addMetaData.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = 'an error occurred!';
      });
    },
  });

export {addMetaData};
// export {getMetaData};
export default addMetaDataSlice.reducer;