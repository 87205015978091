// import { handleError, clearError } from '../utils';
import {getProduct} from '../../../../api/actions/TxmyMgmt/editProduct/getProducts';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
  getProducts: [],
  totalPages: 0,
};

const fetchProductData = createAsyncThunk(
  "overview/editCategory",
  async (queryParam) => {
    const response = await getProduct(queryParam);
    return response.data;
  }
);


const tacctProductSlice = createSlice({
  name: "editCategory",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchProductData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProductData.fulfilled, (state, action) => {
      let temp = JSON.parse(JSON.stringify(action.payload.data));
        state.getProducts = temp.map((obj)=> {
          obj['check'] = false;
          return obj
          });
         state.loading = false;
    });
    builder.addCase(fetchProductData.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { fetchProductData, initialState };
export default tacctProductSlice.reducer;
