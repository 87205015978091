// import { handleError, clearError } from '../utils';
import {getCategory} from '../../../../api/actions/TxmyMgmt/editCategory/getCategory';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
  getCategories: [],
  totalPages: 0,
};

const fetchCategoryData = createAsyncThunk(
  "overview/editCategory",
  async (queryparams) => {
    const response = await getCategory(queryparams);
    return response.data;
  }
);


const tacctEditCatSlice = createSlice({
  name: "editCategory",
  initialState,
  
  extraReducers(builder) {
   builder.addCase(fetchCategoryData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCategoryData.fulfilled, (state, action) => {
        state.getCategories = action.payload?.data;
        state.totalPages = action.payload?.totalPages;
        state.loading = false;
    });
    builder.addCase(fetchCategoryData.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { fetchCategoryData, initialState };
export default tacctEditCatSlice.reducer;