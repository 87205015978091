import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addEditMetadata, getMetadata } from '../../../api/actions/metadata/metadataManagement';


export const initialState = {
  type: "",
  loader: false,
  loader2: false,
}

const getmetadata = createAsyncThunk(
  "addMetadata/getMetadata",
  async (payload, { rejectWithValue }) => {
    const response = await getMetadata(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const addEditMetaDatavalue = createAsyncThunk(
  "addMetadata/addEditMetadata",
  async (payload, { rejectWithValue }) => {
    const response = await addEditMetadata(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);


const addMetaDataSlice =  createSlice({
  name: 'AddMetaData',
  initialState,
  extraReducers(builder) {
    builder.addCase(getmetadata.fulfilled, (state, action) => {
      state.loader = false;
      state.errorMessage = '';
    });
    builder.addCase(getmetadata.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getmetadata.rejected, (state, action) => {
      state.loader = false;
      state.errorMessage = 'an error occurred!';
    });
    builder.addCase(addEditMetaDatavalue.fulfilled, (state, action) => {
      state.loader = false;
      state.errorMessage = '';
    });
    builder.addCase(addEditMetaDatavalue.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(addEditMetaDatavalue.rejected, (state, action) => {
      state.loader = false;
      state.errorMessage = 'an error occurred!';
    });
  },
});
export default addMetaDataSlice.reducer;


export { addEditMetaDatavalue,getmetadata };