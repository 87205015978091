import putGFApproval from "../../../../api/actions/TxmyMgmt/grammaticalForm/putGFApproval";
import { createAsyncThunk } from "@reduxjs/toolkit";

const UPDATE_GFAPPROVAL_DATA = createAsyncThunk(
  "overview/updateBuApproval",
  async (payload) => {
    const response = await putGFApproval(payload);
    return response.data;
  }
);

export { UPDATE_GFAPPROVAL_DATA };