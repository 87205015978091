/**
 * Get Modal Reports Data.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

import ctx from "../../../plugins/axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default async (dataObj) => {
  const queryStr = `/deviationreports?campaign_id=${dataObj.campaign_id}&list_type=${dataObj.list_type}&receiver=${dataObj.receiver}&search=${dataObj.search}&start_date=${dataObj.start_date}&end_date=${dataObj.end_date}`;
  const { data, status } = await ctx.get(queryStr);
  return { data, status };
};
