import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);
export default function ModalBar({ name, labels, dataList, percentage }) {
  const data = {
    labels: labels,
    datasets: [
      {
        label: name,
        backgroundColor: "#f87979",
        data: dataList,
        barThickness: 20,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    // to add the percentage value at the end of barchart
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 12,
        },
      },
      datalabels: {
        anchor: "end",
        align: "end",
        display: true,
        padding: {
          top: -20,
        },
        offset: 3,
        // to give custom percentage value
        formatter: (value, cntx) => percentage[cntx.dataIndex],
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <Bar options={options} data={data} />;
    </>
  );
}
