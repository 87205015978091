import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateTacticbyID } from '../../../api/actions/tacticentry';
// import { archiveget } from '..api/index';


export const initialState = {
  loader: false,
  records: [],
  pageNumber: 1,
  pageSize: undefined,

};

const updateRequestForApproval = createAsyncThunk(
  "requestforapproval/editrecord",
  async (payload, { rejectWithValue }) => {
    const response = await updateTacticbyID(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const requestSlice = createSlice({
  name: 'edirarchive',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateRequestForApproval.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(updateRequestForApproval.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updateRequestForApproval.rejected, (state, { payload }) => {
      state.loader = false;
    });
  },
});

export { updateRequestForApproval };

export default requestSlice.reducer;


