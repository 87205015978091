import ctx from "../../../plugins/axios";
/**
 * Get List Mgmt  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async ( selectedFilter) => {
    // eslint-disable-next-line prefer-const
    const queryStrcount = `/auditreports?brand=${selectedFilter.brand}&vendor=${selectedFilter.vendor}&campaign_id=${selectedFilter.campaign_id}&indication=${selectedFilter.indication}&campaign_start_date=${selectedFilter.campaign_start_date}&campaign_end_date=${selectedFilter.campaign_end_date}&campaign_type=${selectedFilter.campaign_type}&search=${selectedFilter.searchKeyword}&TargetList=${selectedFilter.TargetList}`;
    const queryStr = `/auditcap?brand=${selectedFilter.brand}&vendor=${selectedFilter.vendor}&campaign_id=${selectedFilter.campaign_id}&indication=${selectedFilter.indication}&campaign_start_date=${selectedFilter.campaign_start_date}&campaign_end_date=${selectedFilter.campaign_end_date}&campaign_type=${selectedFilter.campaign_type}&search=${selectedFilter.searchKeyword}&CurrentPage=${selectedFilter.pageNo}&pageDataLimit=${selectedFilter.limit}&downloadcsv=${selectedFilter.downloadcsv}&TargetList=${selectedFilter.TargetList}&deviation=${selectedFilter.deviation}`;
    const [datacount, displaydata] = await Promise.all([ctx.get(queryStrcount), ctx.get(queryStr)]);
    const { data, status } = displaydata;
    const csvdata = data.downloaddata;
    const { datacounts } = datacount.data;
    return {
      data, status, csvdata, datacounts,
    };
  };
