import React, { useEffect, useState } from 'react'
import HeaderNav from '../../HeaderNav'
import { LdsButton, LdsTile, LdsLoadingSpinner, useToastContext } from '@elilillyco/ux-lds-react'
import ReusableTable from '../../shared/Table'
import { useDispatch } from 'react-redux'
import { fetchGfData } from '../../../store/Components/TACCT/GF/tacctGfOverview'
import "../../../assets/components/TACCT/loader.scss"
import GFEntry from '../../../pages/TACCT/grammaticalForm/gfEntry'
import ExportDataCSV from '../../../data/utils/ExportDataCSV'
import Chatbot from '../../../pages/Chatbot'

const GrammaticalForm = () => {
  const [design, setDesign] = useState()
  const [loader, setLoader] = useState()
  const dispatch = useDispatch();

  const infoToastConfig = {
    toastMessage: "Table has not data in it.",
    actionText: "",
    // actionCallback: () => console.log("View clicked"),
    variant: "informative",
    position: "top",
    align: "center",
    dismissible: true,
    light: false,
    timeout: 5000,
    inline: false,
    autoDismiss: true,
  };
  const { addToast } = useToastContext();

  useEffect(() => {
    dispatch(fetchGfData('overview'))
      .then((data) => (setLoader(true), data?.payload?.data[0] !== undefined ? setDesign(<><ReusableTable column={Object?.keys(data?.payload?.data[0]).slice(0, 3)} tableData={data?.payload?.data} editData={editData} /></>) : addToast(infoToastConfig)))
  }, [])

  function editData(data) {
    window.location.href = `/tacct/grammatical-form/gfattributes-entry?id=${data}`
  }

  function checkNewRequestGFRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin'|| role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_approver_taxccb' || role === 'omt_tacct_admin' || role === 'omt_tacct_bl_approver_omni' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function AddGrammeticalForm() {

    window.location.href = '/tacct/grammatical-form/gfattributes-entry'
  }

  function reqforApproval() {
    window.location.href = '/tacct/grammatical-form/approval'
  }

  const downloadExcel = async () => {
    await dispatch(fetchGfData('overview')).then((data) => {
      ExportDataCSV(data?.payload.data, "TACCT_gramform")
    })
  }


  return (
    <>
      <HeaderNav msg={"Grammatical Form"} />
      <LdsTile className='hero container' >
        <div className="overview-title p-4">
          <span className='overview-text'>Overview</span>
        </div>
        <div className='button-container'>
          {
            checkNewRequestGFRole() &&
            <LdsButton className="col buttons" onClick={AddGrammeticalForm} >Request New Grammatical Form</LdsButton>
          }
          {
            checkApprovalRole() &&

            <LdsButton className="col buttons" onClick={reqforApproval} >Review Approval Requests</LdsButton>

          }
          <LdsButton className="col buttons" icon="DownloadSimple" label="download-csv" onClick={downloadExcel} >Download As CSV</LdsButton>
        </div>
      </LdsTile>
      <div className='container-md'>
        {loader ? design : <div className='spinner container loaderAlignFix'>
          <LdsLoadingSpinner size={90} />
        </div>}
      </div>
      <div>
      <Chatbot fromPage={"Grammatical Form"}/>
      </div>
    </>
  )
}

export default GrammaticalForm
