import ctx from "../../../plugins/axios";

/**
 * Download Response Matrix Summary File Data
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (file_name) => {
  const queryStrcount = `/downloadlistrmsummary?rms_filename=${file_name}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};
