import React, { useState, useEffect } from 'react'
import HeaderNav from '../../HeaderNav'
import { LdsButton, LdsCheckbox, LdsIcon, LdsLoadingSpinner, LdsToast, LdsModal, LdsPagination, LdsTable, LdsTile, useLdsModal, useToastContext, LdsTextarea } from '@elilillyco/ux-lds-react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchTacctRfaData } from '../../../store/Components/TACCT/BL/tacctrequestForApproval';
import '../../../assets/components/TACCT/BL/requestForApproval.scss'
import { updateApprovalData } from '../../../store/Components/TACCT/BL/tacctEditApproval';

export default function TacctRfa() {
  const data = useSelector(({ tacctRfaDataSlice }) => tacctRfaDataSlice.contentRfaBody);
  const tPages = useSelector(({ tacctRfaDataSlice }) => tacctRfaDataSlice.totalPages);
  const loading = useSelector(({ tacctRfaDataSlice }) => tacctRfaDataSlice.loading);
  const [sortedData, setSortedData] = useState(data);
  const [isData, setIsData] = useState([...data])
  const [isChecked, setIsChecked] = useState(false)
  const [getId, setId] = useState();
  useEffect(() => {
    setSortedData(data)
  }, [data])
  useEffect(() => {
    setIsData([...data]);
  }, [data]);
  useEffect(() => {
    setId(
      isData
        ?.filter((item) => item.check === true)
        .map((item) => item.blue_list_subcategory_detail_id)
    );
  }, [isData]);
  // const records = sortedData;
  const [editorRole, setEditorRole] = useState('')
  const [editedStatus, setEditedStatus] = useState('')
  // const [subCatID, setsubCatID] = useState()
  const role = useSelector(({ user }) => user.role_ids);
  const [lastEvent, setLastEvent] = useState('');
  const totalPages = tPages;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRangeVisible] = useState(5);
  // const [checkedValue, setcheckedValue] = useState([])
  const { isModalOpen: isApproveModalOpen, setIsModalOpen: setApprovedIsModalOpen } = useLdsModal();
  const { isModalOpen: isRejectModalOpen, setIsModalOpen: setRejectIsModalOpen } = useLdsModal();
  const { addToast } = useToastContext();
  const [note, setNote] = useState('')
  // const [sortOrder, setSortOrder] = useState(null);

  // const handleSort = (column) => {
  //   const newData = [...sortedData]
  //   newData.sort((a, b) => {
  //     if (a[column] < b[column]) return -1;
  //     if (a[column] > b[column]) return 1;
  //     return 0;
  //   })
  //   if (sortOrder === column) {
  //     newData.reverse()
  //   }
  //   setSortedData(newData)
  //   setSortOrder(column)
  // };

  function handleClick() {
    setApprovedIsModalOpen(false)
    setRejectIsModalOpen(false)
  }
  function rejectHandleClick() {
    setRejectIsModalOpen(false)
    setApprovedIsModalOpen(false)
  }

  function modalOpen() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 2000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    setApprovedIsModalOpen(true)
  }

  function rejectmodalOpen() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 2000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }
    setRejectIsModalOpen(true)
  }

  async function getCurrentUserId() {
    const currentRole = sessionStorage.getItem('role');
    const currentUserData = role.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    return currentUserData[0][currentRole];
  }

  async function approveTactic() {

    const payload = {
      requestData: {
        role: editorRole,
        status: editedStatus,
        subcategory_id: getId,
        user_id: await getCurrentUserId()
      }
    }
    dispatch(updateApprovalData(payload))
    setTimeout(() => {
      window.location.href = '/tacct/blue-list'
    }, 1500)
  };
  async function rejectTactic() {
    if (note.length === 0) {
      setRejectIsModalOpen(false)
      addToast({
        toastMessage: "Please add reject comment.",
        actionText: "",
        // actionCallback: () => console.log("View clicked"),
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 2000,
        inline: false,
        autoDismiss: true,
      })
      return;

    }

    else {
      const payload = {
        requestData: {
          role: editorRole,
          status: editedStatus,
          subcategory_id: getId,
          user_id: await getCurrentUserId(),
          reject_note: note
        }
      }
      dispatch(updateApprovalData(payload))
      window.location.href = '/tacct/blue-list'
    }
    setRejectIsModalOpen(true)
  };




  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    const paramArg = {
      pageSize: 10,
      pageNumber: value,
    }
    const currentRole = sessionStorage.getItem('role');
    if (role.length && currentRole === 'omt_tacct_bl_metadata_requester') {
      paramArg['vendorId'] = getCurrentUserId();
    }
    dispatch(fetchTacctRfaData(paramArg));
  };

  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    const paramArg = {
      pageSize: 10,
      pageNumber: currentPage + 1,
    }
    dispatch(fetchTacctRfaData(paramArg));
  };

  // Called when you click the "previous" link/button
  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    const paramArg = {
      pageSize: 10,
      pageNumber: currentPage - 1,
    }
    dispatch(fetchTacctRfaData(paramArg));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const paramArg = {
      pageSize: 10,
      pageNumber: 1,
    }

    const currentRole = sessionStorage.getItem('role');
    const callGetCurrentUser = async () => {
      const id = await getCurrentUserId();
      return id;
    }
    if (role.length && currentRole === 'omt_tacct_bl_metadata_requester') {
      callGetCurrentUser().then((id) => {
        paramArg['vendorId'] = id;
        dispatch(fetchTacctRfaData(paramArg));
      });
    }
    else {
      if (role.length) { dispatch(fetchTacctRfaData(paramArg)); };
    }
  }, [dispatch, role]);

  function ovButton() {
    window.location.href = '/tacct/blue-list'
  }

  function checkCCBRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin" || role === "omt_tacct_bl_approver_taxccb" || role === "omt_admin") {
      return true;
    }
    return false;
  }

  function checkOmniRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin" || role === "omt_tacct_bl_approver_omni" || role === "omt_admin") {
      return true;
    }
    return false;
  }

  function checkVendorRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_bl_metadata_requester") {
      return true;
    }
    return false;
  }
  function checkedData(e) {
    const value = e.target.id;
    const checked = e.target.checked;
    const tempData = JSON.parse(JSON.stringify(isData));
    for (let obj of tempData) {
      if (obj.blue_list_subcategory_detail_id.toString() === value) {
        obj.check = checked;
      }
    }
    setIsData(tempData);
  }
  function handleMainChange(e) {
    const isChecking = e.target.checked
    setIsChecked(isChecking)
    const tempData = JSON.parse(JSON.stringify(isData))
    const data = tempData.map((obj) => {
      obj.check = isChecking
      return obj
    })
    setIsData(data)
  }

  return (
    <>
      <HeaderNav msg={"Blue List Content Category & Subcategory"} />
      <LdsTile className='hero' >
        <div className="overview-container">
          <div className=' button-container'>
            <LdsButton onClick={ovButton} className="col buttons" >Overview</LdsButton>
          </div>
        </div>
      </LdsTile>
      <LdsTable style={{ minHeight: '20rem' }}>
        <thead className='table-headers'>
          <tr>
            {!checkVendorRole() &&
              <th className="ta-left" scope="col">
                <LdsCheckbox
                  label="Select All "
                  onChange={(e) => { handleMainChange(e) }}
                  checked={isChecked}
                  style={{ marginTop: '-1.5rem' }} /></th>
            }
            <th className='ta-left' scope="col">TaxonomyCCB</th>
            <th className='ta-left' scope="col">Omni Channel Managers</th>
            <th className='ta-left' scope="col">Status</th>
            <th className='ta-left' scope="col">Request Type
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('type') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">Country </th>
            <th className='ta-left' scope="col">Product
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('product_name') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">Category
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('category') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">Sub-Category
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('subcategory') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">Category Operational Definition
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('category_definition') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">Sub-Category Operational Definition
              {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={() => { handleSort('subcategory_definition') }}
              /> */}
            </th>
            <th className='ta-left' scope="col">VVPM</th>
            <th className='ta-left' scope="col">VVMC</th>
            <th className='ta-left' scope="col">AEM</th>
          </tr>
        </thead>
        {!loading ? (data && data.length > 0 ? <tbody className='table-body'>
          {isData.map((entry, rowIndex) => (
            <tr className='ta-left'
              role="row" key={rowIndex}>
              {!checkVendorRole() &&
                <td className="table-ends">
                  <LdsCheckbox name="opt1"
                    id={entry.blue_list_subcategory_detail_id}
                    onChange={(e) => { checkedData(e) }}
                    value={entry.blue_list_subcategory_detail_id}
                    checked={entry.check} />
                </td>
              }
              <td className='ta-left'>{!entry.taxonomy_ccb_status ? 'PENDING' : 'APPROVED'}</td>
              <td className='ta-left'>{!entry.omnichannel_managers_status ? 'PENDING' : 'APPROVED'}</td>
              <td className='ta-left'>{entry.status}</td>
              <td className='ta-left'>{entry.type}</td>
              <td className='ta-left'>{entry.country_names.split(',').length > 3 ? "All" : entry.country_names}</td>
              <td className='ta-left'>{entry.product_name}</td>
              <td className='ta-left'>{entry.category}</td>
              <td className='ta-left'>{entry.subcategory}</td>
              <td className='ta-left'>{entry.category_definition}</td>
              <td className='ta-left'>{entry.subcategory_definition}</td>
              <td className='ta-left'>{entry.vvpm_flag === true ? 'Y' : 'N'}</td>
              <td className='ta-left'>{entry.vvmc_flag === true ? 'Y' : 'N'}</td>
              <td className='ta-left'>{entry.aem_flag === true ? 'Y' : 'N'}</td>
            </tr>
          ))}
        </tbody> : (<tbody>
          <div>
            <span className='no-data'>No Data Available</span>
          </div>
        </tbody>
        )
        ) : <LdsLoadingSpinner class='loader' size={50} animationSpeed={500} />}
      </LdsTable>
      {!loading &&
        <LdsPagination
          onLinkClick={clickLink}
          onNextClick={clickNext}
          onPrevClick={clickPrev}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          pageRangeVisible={pageRangeVisible}
        />
      }
      <div className='approve-sec'>
        {checkCCBRole() &&
          <div className='approve'>
            <h4>Taxonomy CCB</h4>
            <div className='approve-buttons'>
              <LdsIcon className='approve-icon' onClick={() => { modalOpen(); setEditedStatus('APPROVED'); setEditorRole('omt_tacct_bl_approver_taxccb') }} name='CheckCircle' label='Approve' />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to approve these values?"
              >

                <div className="space">
                  <LdsButton onClick={() => { handleClick(); approveTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { handleClick() }}>No</LdsButton>
                </div>
                <LdsToast />
              </LdsModal>

              <LdsIcon className='approve-icon' onClick={() => { rejectmodalOpen(); setEditedStatus('REJECTED'); setEditorRole('omt_tacct_bl_approver_taxccb') }} name="XCircle"
                label="Reject" />

              <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these values?"
              > <div>
                  <LdsTextarea
                    id='charCountTextarea'
                    name='charCountTextarea'
                    label='Please provide comment for rejection'
                    rows='3'
                    maxLength='200'
                    counterText="/200"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />

                </div>

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); rejectTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => rejectHandleClick()}>No</LdsButton>
                </div>

              </LdsModal>

            </div>
          </div>}
        {checkOmniRole() &&
          <div className='approve'>
            <h4>OmniChannel Managers</h4>
            <div className='approve-buttons'>
              <LdsIcon className='approve-icon' onClick={() => { modalOpen(); setEditedStatus('APPROVED'); setEditorRole("omt_tacct_bl_approver_omni") }} name='CheckCircle' label='Approve' />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to approve these values?"
              >

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); approveTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { rejectHandleClick() }}>No</LdsButton>
                </div>

              </LdsModal>

              <LdsIcon className='approve-icon' onClick={() => { rejectmodalOpen(); setEditedStatus('REJECTED'); setEditorRole("omt_tacct_bl_approver_omni") }} name="XCircle"
                label="Reject" />
              <LdsToast />
              <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these values?"
              > <div>
                  <LdsTextarea
                    id='charCountTextarea'
                    name='charCountTextarea'
                    label='Please provide comment for rejection'
                    rows='3'
                    maxLength='200'
                    counterText="/200"
                    counterHiddenLabel='Límite de 30 caracteres'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>

                <div className="space">
                  <LdsButton onClick={() => { rejectHandleClick(); rejectTactic() }}>Yes</LdsButton>
                  <LdsButton onClick={() => { rejectHandleClick() }}>No</LdsButton>
                </div>

              </LdsModal>
            </div>
          </div>}
      </div>
    </>
  )
}