import { LdsButton, LdsIcon, LdsLink, LdsModal, LdsToast, useToastContext } from '@elilillyco/ux-lds-react';
import { useEffect, useRef, useState } from 'react';
import '../../assets/components/adhocListScrubbing/fileUpload.scss';
import infoToastConfig from '../../data/utils/infoToastConfig';
import { setIsVarifiedFileApi, validationCheck } from '../../store/adhocScrubbing/adhocScrubbing';
import ExportExcelSheet from '../../data/utils/ExportExcelSheet';
import { useSelector } from 'react-redux';

const FileUpload = ({ isModal, setIsModal, uploadFile, dispatch, LdsLoadingSpinner }) => {
    const { addToast } = useToastContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const { isFileVerified, isVarifiedFileApi } = useSelector((store) => store.filters);
    const [controller, setController] = useState(null);
    let flag = useRef(true);
    
    useEffect(()=>{
        const abortController = new AbortController();
        setController(abortController);
        if(flag.current){
            flag.current = false;
            return;
        }
        if(!isModal){
            controller.abort();
            dispatch(setIsVarifiedFileApi(true));
            setSelectedFile(null);
        }
    },[isModal])

    const onFileLoad = async (file) => {
        const fileStream = await toBase64(file);
        await dispatch(validationCheck({fileStream:fileStream, controller: controller.signal }));
    }
    const toBase64 = (file) => {
        if (file === null) {
            return;
        }
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files;
        setFile(file);

    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files;
        setFile(file);

    };

    const setFile = (file) => {
        if (file.length !== 0) {
            if (file[0].type !== 'text/csv') {
                addToast(
                    infoToastConfig({
                        toastMessage:
                            "Please upload CSV file.",
                        variant: "informative",
                    })
                );
                return;
            }
            setSelectedFile(file[0]);
            onFileLoad(file[0]);
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        // to clear the input field
        document.querySelector("#fileInput").value = '';
    };

    const handleSubmit = async () => {
        const file = selectedFile;
        const fileStream = await toBase64(file);
        uploadFile(fileStream, selectedFile);
        setIsModal(false);
        setSelectedFile(null);
        document.querySelector("#fileInput").value = '';
    }
    const sampleFileDownload = () => {
        const fileSampleData = [{
            "LILLY_PRSN_ID": 'sample',
            "SEGMENT": 'sample',
        }]

        ExportExcelSheet(fileSampleData, "sample_adhoc_file", false);
    }

    return (
        <LdsModal
            modalId="reasonModal"
            open={isModal}
            setModalOpen={setIsModal}
            modalSizeClass="col-4 col-md-4"
            className="p-4"
        >
            <LdsToast />
            {
                (isVarifiedFileApi === null ? true : isVarifiedFileApi)
                    ?
                    <div>
                        <div
                            className='drag-drop'
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            {
                                <div
                                    className='d-grid'
                                >
                                    <p className='d-grid justify-center align-center'>Drag and drop a file here <br /> <span className='aliging-item' >or</span> </p>
                                    <div className='d-grid justify-center align-center' >
                                        <label htmlFor="fileInput" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                            choose a file
                                        </label>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".csv" // Customize accepted file types
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='mt-3'>
                            Template : <LdsLink onClick={sampleFileDownload} >Sample File Template.csv</LdsLink>
                        </div>
                        {selectedFile &&
                            <div className='mt-4' >
                                <p>
                                    Selected file: <span className='file-name' >{selectedFile.name}</span>
                                    <LdsButton
                                        className='x-circle'
                                        linkButton={true}
                                        onClick={handleRemoveFile}
                                    >
                                        <LdsIcon name="XCircle" inline />
                                    </LdsButton>
                                </p>
                                <div className='mt-3' >
                                    <LdsButton
                                        onClick={handleSubmit}
                                        disabled={!isFileVerified}
                                    >
                                        Submit
                                    </LdsButton>
                                    {
                                        isFileVerified ?
                                            <div className='verifiedFile'>
                                                File is verified. <LdsIcon name="CheckCircle" inline />
                                            </div>
                                            :
                                            <div className='file_validation d-flex justify-center' >
                                                Invalid format. Please refer to the Sample_File_Template.csv
                                            </div>

                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        <div className=' d-flex justify-center ' >
                            Validating File Format...
                        </div>
                        <div className="ta-center p-3 m-4">
                            <LdsLoadingSpinner size={50} />
                        </div>
                    </div>
            }
        </LdsModal>
    );
};

export default FileUpload;