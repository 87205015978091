import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addTonality from "../../../../api/actions/TxmyMgmt/tonality/addTonality";
import getTonalitybyID from "../../../../api/actions/TxmyMgmt/tonality/getTonalitybyID";
import updateTonality from "../../../../api/actions/TxmyMgmt/tonality/updateTonality";

const initialState = {
  tonalityEntryLoader: false,
  errMsg:'',
  toanlityDataForEdit: {},
}

const CREATE_TONALITY = createAsyncThunk(
  "overview/addTonality ",
  async (payload, { rejectWithValue }) => {
    const response = await addTonality(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const FETCH_TONALITY_BY_ID = createAsyncThunk(
  "overview/getTonalitybyID",
  async (id, { rejectWithValue }) => {
    const response = await getTonalitybyID(id);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const UPDATE_TONALITY = createAsyncThunk(
  "overview/updateTonality",
  async (payload, { rejectWithValue }) => {
    const response = await updateTonality(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const tonalityEntrySlice = createSlice({
  name: "tonalityEntrySlice",
  initialState,
  extraReducers(builder) {
    builder.addCase(CREATE_TONALITY.pending, (state, action) => {
        state.tonalityEntryLoader = true;
    });
    builder.addCase(CREATE_TONALITY.fulfilled, (state, action) => {
        state.tonalityEntryLoader = false; 
        state.errMsg = '';
    });
    builder.addCase(CREATE_TONALITY.rejected, (state, action) => {
        state.tonalityEntryLoader = false;
        state.errMsg = 'an error occurred!'
    });
    builder.addCase(FETCH_TONALITY_BY_ID.pending, (state, action) => {
      state.tonalityEntryLoader = true;
    });
    builder.addCase(FETCH_TONALITY_BY_ID.fulfilled, (state, action) => {
        state.tonalityEntryLoader = false;
        state.toanlityDataForEdit = action.payload.data;
        state.errMsg = '';
    });
    builder.addCase(FETCH_TONALITY_BY_ID.rejected, (state, action) => {
        state.tonalityEntryLoader = false;
        state.errMsg = 'an error occurred while fetching!'
    });
    builder.addCase(UPDATE_TONALITY.pending, (state, action) => {
      state.tonalityEntryLoader = true;
    });
    builder.addCase(UPDATE_TONALITY.fulfilled, (state, action) => {
        state.tonalityEntryLoader = false;
        state.errMsg = '';
    });
    builder.addCase(UPDATE_TONALITY.rejected, (state, action) => {
        state.tonalityEntryLoader = false;
        state.errMsg = 'an error occurred while updating!'
    });
  },
});

export {
  CREATE_TONALITY,
  FETCH_TONALITY_BY_ID,
  UPDATE_TONALITY,
}

export default tonalityEntrySlice.reducer;