import { LdsLink, LdsTable, useLdsModal } from "@elilillyco/ux-lds-react";
import "../../assets/components/response-matrix/responseMatrix.scss";
import TacticIdModal from "./tacticIdModal";
import { getRmModalData } from "../../store/responseMatrix/rmCap.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LdsSortableTable from "../../data/utils/SortableTable.js";

export default function RmTable({ rmTableData, tableApiStatus }) {
  const dispatch = useDispatch();
  const { isModalOpen: isTacticModalOpen, setIsModalOpen: setTacticModalOpen } =
    useLdsModal(false);
  const { tacticModalTableData, pageNo } = useSelector(
    ({ rmSlicer }) => rmSlicer
  );
  const [selectedTacticID, setSelectedTacticID] = useState("");
  let [selectedFilterData, setSelectedFilterData] = useState({
    tactic_id: "",
    send_date: "",
    recieve_date: "",
    pageNo: 1,
    limit: 25,
  });

  async function rmModalDataDetails(tacticId) {
    selectedFilterData.tactic_id = tacticId;
    setSelectedTacticID(tacticId);
    selectedFilterData.send_date = "";
    selectedFilterData.recieve_date = "";
    await dispatch(getRmModalData(selectedFilterData));
    setTacticModalOpen(true);
  }

  //table data
  const [tableRowData, setTableRowData] = useState(rmTableData);
  useEffect(() => {
    setTableRowData(rmTableData);
  }, [rmTableData]);

  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }

  return (
    <>
      <section>
        <div className="rm-table-card">
          <div>
            <LdsTable id="rmTable">
              <LdsSortableTable
                headAlign={'align-left b'}
                header={['Tactic Id', 'Veeva Doc Id', 'Campaign Id', 'Brand', 'Indication', 'Department']}
                rowData={rmTableData}
                rowKeyName={['verso_tactic_id', 'approved_job_id', 'verso_campaign_id', 'product', 'disease_state_indication', 'department']}
                sortedRowData={sortedRowData}
              />

              <tbody>
                {
                  tableApiStatus
                    ?
                    tableRowData.map((data, rowIndex) => (
                      <tr role="row" key={rowIndex}>
                        <td role="cell" className="align-left">
                          <LdsLink
                            onClick={() => {
                              rmModalDataDetails(data.verso_tactic_id);
                            }}>
                            {data.verso_tactic_id}
                          </LdsLink>
                        </td>
                        <td role="cell" className="align-left">
                          {data.approved_job_id}
                        </td>
                        <td role="cell" className="align-left">
                          {data.verso_campaign_id}
                        </td>
                        <td role="cell" className="align-left">
                          {data.product}
                        </td>
                        <td role="cell" className="align-left">
                          {data.disease_state_indication}
                        </td>
                        <td role="cell" className="align-left">
                          {data.department}
                        </td>
                      </tr>
                    ))
                    :
                    <tr role="row" >
                      <td className="apiStatus">Unable to load data from API.</td>
                    </tr>
                }
              </tbody>
            </LdsTable>
            {/* TacticIdModal */}
            {isTacticModalOpen && (
              <TacticIdModal
                isTacticModalOpen={isTacticModalOpen}
                setTacticModalOpen={setTacticModalOpen}
                tacticModalTableData={tacticModalTableData}
                selectedTacticID={selectedTacticID}
                pageNo={pageNo}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
