import React from "react";
import "../assets/components/CommonCard.scss";
import { LdsIcon, LdsLink } from "@elilillyco/ux-lds-react";
import { useSelector } from "react-redux";

function CommonCard({
  linkTo,
  labelInfo,
  iconName,
  labelText,
  isDisabled,
  selectedrole,
}) {
  const allRoles = useSelector(({ user }) => user.AllRole);
  const roleaccess = selectedrole?.filter((value) => allRoles?.includes(value));
  return (
    <div className="col-2 p-2">
      <div
        onClick={() =>
          roleaccess?.length > 0
            ? sessionStorage.setItem("role", roleaccess[0])
            : null
        }
        className={isDisabled ? "isDisable" : ""}
      >
        <LdsLink classes="card-link" href={linkTo} target={labelText === 'Omnichannel Data & Insights' ? '_blank': '_self'}>
          <div className="card">
            <div className="card-body">
              <div className={isDisabled ? "" : "card-hover"}>
                <span className="card-info">{labelInfo}</span>
              </div>
              <LdsIcon className="card-icon" name={iconName} />
              <span className="card-text">{labelText}</span>
            </div>
          </div>
        </LdsLink>
      </div>
    </div>
  );
}

export default CommonCard;
