import { Link, useLocation } from 'react-router-dom';
import {
    LdsSideNav ,LdsLillyLogo
  } from '@elilillyco/ux-lds-react';

import '../assets/layout/sideNav.scss'

export function SideNav({ children}) {
  const location = useLocation();

  const SideNav = [
    {
      text: 'Omnichannel Meta data Management',
      link: { to: '/omms' },
    },
    {
      text: "List Management Auditing",
      link: { to: "/list-management-auditing" },
    },
    {
      text: "List Scrubbing",
      link: { to: "/adhoc-List-Scrubbing" },
    },
    {
      text: "History",
      link: { to: "/adhoc-History" },
    },
    {
      text: "Response Matrix",
      link: { to: "/response-matrix" },
    },
  ];

  // Props and children get passed onto your router link from the side nav. This will include things like className and
  // href/ to properties from the menu object.
  const getRouterLink = (props, children) => <Link {...props}>{children}</Link>;

  return (
    
      <LdsSideNav
        className="sidenav"
        logo={<LdsLillyLogo size="lg" paddingOverrideClass="pd-0" ariaLabel="Visit Lilly.com"
        color="red"/>}
        currentRoute={location.pathname}
        routerLink={getRouterLink}
        menu={SideNav}
      >

        {children}

      </LdsSideNav>
  );
}