import ctx from '../../../plugins/axios';
 
export default async  (queryParams) => {
  try {
        if (queryParams.page === 'overview') {
          const { data, status } = await ctx.get(`/vendorentries?status=APPROVED,DRAFT,REJECTED&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&delivery_source=${queryParams.deliverySource}&delivery_source_type=${queryParams.deliverySourceType}&supplier_name=${queryParams.supplierName}&contact_type=${queryParams.contactType}&platform=${queryParams.platform}&delivery_channel=${queryParams.deliveryChannel}&engagement_based_tactic=${queryParams.engagementBasedTactic}&role=${queryParams.role}`);
          return { data, status };
        }
        if (queryParams.page === 'rfa') {
          const { data, status } = await ctx.get(`/vendorentries?status=PENDING&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&search_keyword=${queryParams.searchKeyword}&columnType=${queryParams.columnType}&role=${queryParams.role}`);
          return { data, status };
        }
        const { data, status } = await ctx.get('/vendorentries');
        return { data, status };
      } catch (error) {
        return { error };
      }
};

// export default async (ctx, queryParams) => {
//   try {
//     if (queryParams.page === 'overview') {
//       const { data, status } = await ctx.get(`/vendorentries?status=APPROVED,DRAFT,REJECTED&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&search_keyword=${queryParams.searchKeyword}&columnType=${queryParams.columnType}&role=${queryParams.role}`);
//       return { data, status };
//     }
//     if (queryParams.page === 'rfa') {
//       const { data, status } = await ctx.get(`/vendorentries?status=PENDING&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&search_keyword=${queryParams.searchKeyword}&columnType=${queryParams.columnType}&role=${queryParams.role}`);
//       return { data, status };
//     }
//     const { data, status } = await ctx.get('/vendorentries');
//     return { data, status };
//   } catch (error) {
//     return { error };
//   }
// };
