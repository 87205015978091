import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsersList } from '../../../api/getUsersList';
import { putUsersList } from '../../../api/putUsersList';


export const initialState = {
  role: [],
  loader: false,
};

const UsersList = createAsyncThunk(
  "/userlist",
  async () => {
    const response = await getUsersList();
    return await response.data;
  }
);
const PutUsersList = createAsyncThunk(
  "/userlist",
  async (payload) => {
    const response = await putUsersList(payload);
    return await response.data;
  }
);

const getUsersLists = createSlice({
  name: 'getUsersLists',
  initialState,
  extraReducers(builder) {
    builder.addCase(UsersList.fulfilled, (state, action) => {
      const roleSet = new Set(action?.payload?.data?.map(record => record.role));
      let roleArray = ["All",...roleSet];
      state.role = roleArray
      state.loader = false;
    });
    builder.addCase(UsersList.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(UsersList.rejected, (state, { payload }) => {
      state.loader = false;
    });
  },
});

export { UsersList,PutUsersList };

export default getUsersLists.reducer;

