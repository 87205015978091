import React from 'react';
import "../assets/pages/Home.scss";
import CommonCard from "../components/CommonCard";
import HeaderNav from "../components/HeaderNav";

function Home() {
  const cards = [
    {
      selectedrole: ["omt_list_management_admin", "omms_list_management"],
      navigateto: "/adhoc-list-scrubbing",
      iconDescription: "Ad hoc List Scrubbing Tool",
      iconLabel: "AdHocListScrubbing",
      iconName: "Files",
      linkTo: "/adhoc-list-scrubbing",
      labelText: "Ad hoc List Scrubbing Tool",
      labelInfo:
        "The Ad Hoc List Scrubbing Tool allows BI&A Analysts to upload and apply suppressions to ad hoc HCP target lists and generates a filtered, or scrubbed, ad hoc target list to be used for different use cases. Also provides On demand execution, saving time to generate execution/ contracting lists.",
    },
    {
      selectedrole: ["omt_list_management_admin", "omms_list_management"],
      navigateto: "/list-management-auditing",
      iconDescription: "List Management Auditing Tool",
      iconLabel: "ListManagementAuditing",
      iconName: "CheckSquare",
      linkTo: "/list-management-auditing",
      labelText: "List Management Auditing Tool",
      labelInfo:
        "List Management Auditing tool allows for searching on multiple fields important to the end user, such as brand, channel, campaign id. Download details for your campaign, such as brand, channel, campaign id. View and Download HCP counts for each Contracting List or Execution List sent to vendors.",
    },
    {
      selectedrole: [],
      navigateto: "",
      iconDescription: "Omnichannel Metadata Management System",
      iconLabel: "OmnichannelMetadataManagement",
      iconName: "Folder",
      linkTo: "/omms",
      labelText: "Omnichannel Metadata Management System",
      labelInfo:
        "OMMS is a tool designed to centralize metadata used to identify and classify a specific metric from a SOA media partner as either an Engagement or Impression.",
    },
    {
      selectedrole: ["omt_response_matrix"],
      navigateto: "/response-matrix",
      iconDescription: "Response Matrix Tool",
      iconLabel: "ResponseMatrixTool",
      iconName: "ArrowsLeftRight",
      linkTo: "/response-matrix",
      labelText: "Response Matrix Tool",
      labelInfo:
        "The RM tool facilitates searching across various fields critical to users. It enables downloading RM and RM summary details.",
    },
    {
      selectedrole: [],
      navigateto: "",
      iconDescription: "Taxonomy Attributes & Content Classification Tool",
      iconLabel: "TaxonomyListManagement",
      iconName: "Files",
      linkTo: "/tacct",
      labelText: "Taxonomy Attributes & Content Classification Tool",
      labelInfo:
        "TACCT is a tool designed to centralize & define content specific metadata values across all content modules, tactic types, customer groups, and geographies.",
    },
    {
      selectedrole: [],
      navigateto: "",
      iconDescription: "Omnichannel Data & Insights",
      iconLabel: "OmnichannelDataAndInsights",
      iconName: "Files",
      linkTo:
        "https://lilly.service-now.com/now/nav/ui/classic/params/target/%24pa_dashboard.do%3Fsysparm_dashboard%3D1d42538d877df5189c2e0d4c8bbb35ca%26sysparm_tab%3D66b6afc5873539189c2e0d4c8bbb3510%26sysparm_cancelable%3Dtrue%26sysparm_editable%3Dundefined%26sysparm_active_panel%3Dfalse",
      labelText: "Omnichannel Data & Insights",
      labelInfo: "Service Now Ops Dashboard to view Incidents",
    },
    // ... add more cards here
  ];

  return (
    <div className="page-content">
      <HeaderNav msg={"Omnichannel Management Tool"} />
      <div className="container">
        <div className="tiles col">
          {cards.map((card, index) => (
            <CommonCard key={index} {...card} />
          ))}
        </div>
      </div>

      
    </div>
  );
}

export default Home;
