import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auditHistory } from '../../../api/actions/tacticentry';

export const initialState = {
  auditData : [],
  historyLoader: false,
};

const fetchHistory = createAsyncThunk('overview/auditHistory', async (histPayload) => {
  const response = await auditHistory(histPayload);
  return response.data;
});

const historySlice = createSlice({
  name: 'auditHistory',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      state.auditData = action.payload?.data;
      state.historyLoader = false;
    });
    builder.addCase(fetchHistory.pending, (state, action) => {
      state.historyLoader = true;
    });
    builder.addCase(fetchHistory.rejected, (state, action) => {
      state.auditData = action.payload;
      state.historyLoader = false;
    });
  },
});

export { fetchHistory };

export default historySlice.reducer;
