import ctx from '../../../../plugins/axios';

export async function getPendingRequest (pageFilter) {
  try {
    let queryParam = '?';
    Object.keys(pageFilter).forEach((key) => queryParam += `${key}=${pageFilter[key]}&`)
    queryParam= queryParam.slice(0,queryParam.length-1)
    const { data, status } = await ctx.get(`/bl-subcategory-request${queryParam}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};