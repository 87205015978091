import ctx from "../../../plugins/axios";
/**
 * Get History Filters  list.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */

const rmCapFilters = async (selectedFilter) => {
    const query = `/rmcaplovs?department=${selectedFilter.department}&brand=${selectedFilter.brand}&indication=${selectedFilter.indication}`;
    const { data, status } = await ctx.get(query);
    return { data, status };
};
export default rmCapFilters;
