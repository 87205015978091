import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addNewTacticEntry } from '../../../api/actions/tacticentry';
// import { archiveget } from '..api/index';


export const initialState = {
  loader: false,
  // records: [],
  // pageNumber: 1,
  // pageSize: undefined,
  
};

const addTactic = createAsyncThunk(
  "addtactic/addrecord",
  async (payload, { rejectWithValue }) => {
    const response = await addNewTacticEntry(payload);
    if (response.error) {
      return rejectWithValue(response.error.response);
    }
    return response.data;
  }
);

const addTacticSlice = createSlice({
  name: 'edirarchive',
  initialState,
  extraReducers(builder) {
    builder.addCase(addTactic.fulfilled, (state, action) => {
        state.loader = false;
    });
    builder.addCase(addTactic.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(addTactic.rejected, (state, {payload}) => {
      state.loader = false;
    });
  },
});

export { addTactic };

export default addTacticSlice.reducer;


