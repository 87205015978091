import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default function BarChart({ vendorlabel, vendorValues }) {
  const data = {
    labels: vendorlabel,
    datasets: [
      {
        label: "Vendor Data",
        data: vendorValues,
        backgroundColor: "#f87979",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
}
