import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHistoryFilter } from "../../api/actions/adhoc/index";
import downloadList from "../../api/actions/adhoc/downloadList";

const initialState = {
  brand: [],
  vendor: [],
  indication: [],
  brand_segment: [],
  behavioral_segment: [],
  status_detail: [],
  user_id: [],
  loading: false,
  isFiltersLoading: false,
};

const HistoryFilter = createAsyncThunk("adhochistory/filters", async () => {
  const response = await getHistoryFilter();
  return response.data.data;
});

const downloadListAdmin = createAsyncThunk(
  "adhocHistoryAdminDownload",
  async (jobId) => {
    const response = await downloadList(jobId);
    return response.data;
  }
);

const historyFilterSlicer = createSlice({
  name: "historyFilters",
  initialState,
  extraReducers(builder) {
    builder.addCase(HistoryFilter.pending, (state, action) => {
      state.isFiltersLoading = false;
    });
    builder.addCase(HistoryFilter.fulfilled, (state, action) => {
      // action.payload.brand.sort();
      state.brand = action.payload.brand.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.brand.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.vendor.sort();
      state.vendor = action.payload.vendor.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.vendor.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.indication.sort();
      state.indication = action.payload.indication.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.brand_segment.sort();
      state.brand_segment = action.payload.brand_segment.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.brand_segment.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.behavioral_segment.sort();
      state.behavioral_segment = action.payload.behavioral_segment.map(
        (option) => ({
          value: option.toString(),
          label: option.toString(),
        })
      );
      state.behavioral_segment.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.status_detail.sort();
      state.status_detail = action.payload.status_detail.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.status_detail.unshift({
        value: "",
        label: "Select",
      });
      // action.payload.user_id.sort();
      state.user_id = action.payload.user_id.map((option) => ({
        value: option.toString(),
        label: option.toString(),
      }));
      state.user_id.unshift({
        value: "",
        label: "Select",
      });
      state.isFiltersLoading = true;
    });
    builder.addCase(HistoryFilter.rejected, (state, action) => {});

    builder.addCase(downloadListAdmin.pending, (state, action) => {});
    builder.addCase(downloadListAdmin.fulfilled, (state, action) => {
      state.isAdminDataUrl = true;
    });
    builder.addCase(downloadListAdmin.rejected, (state, action) => {});
  },
});

export { HistoryFilter, downloadListAdmin };

export default historyFilterSlicer.reducer;
