import ctx from '../../../../plugins/axios';


export async function updateTonalityApproval (payload) {
  try {
    const { data, status } = await ctx.put('/tn-approval', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};