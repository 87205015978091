import {
  LdsButton,
  LdsDatepicker,
  LdsLoadingSpinner,
  LdsSelect,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Historytable from "./historyTable";
import "../../assets/components/adhocListScrubbing/history.scss";
import { getHistoryTableData } from "../../store/adhocHistory/adhocHistoryTable";
import { HistoryFilter } from "../../store/adhocHistory/adhochistory";
import infoToastConfig from "../../data/utils/infoToastConfig.js";
import ExportExcelSheet from "../../data/utils/ExportExcelSheet.js";

export default function History() {
  const dispatch = useDispatch();

  const {
    brand,
    vendor,
    indication,
    brand_segment,
    behavioral_segment,
    status_detail,
    user_id,
    isFiltersLoading,
  } = useSelector((store) => store.HistoryFilter);
  const { adhocTableData, isTableDataLoading, pageNo } = useSelector(
    (store) => store.getHistoryTableData
  );
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedIndication, setSelectedIndication] = useState("");
  const [selectedBrandSegment, setSelectedBrandSegment] = useState("");
  const [selectedBehavioralSegment, setSelectedBehavioralSegment] =
    useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const { addToast } = useToastContext();

  useEffect(() => {
    dispatch(HistoryFilter(selectedFilterData));
    dispatch(getHistoryTableData(selectedFilterData));
  }, [dispatch]);

  let [selectedFilterData, setSelectedFilterData] = useState({
    start_date: "",
    end_date: "",
    behavioral_segment: "",
    brand: "",
    indication: "",
    vendor: "",
    brand_segment: "",
    status_detail: "",
    user_id: "",
    pageNo: 1,
    limit: 10,
    downloadcsv: "false",
  });
  const getTableData = () => {
    dispatch(getHistoryTableData(selectedFilterData));
  };
  function selectedValueBrand(brand) {
    selectedFilterData.brand = brand;
    selectedFilterData.pageNo = 1;
    getTableData();
  }
  function selectedValueVendor(vendor) {
    selectedFilterData.vendor = vendor;
    selectedFilterData.pageNo = 1;
    getTableData();
  }
  const selectedValueIndication = (indication) => {
    selectedFilterData.indication = indication;
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  const selectedValueBrandSegment = (brandSegment) => {
    selectedFilterData.brand_segment = brandSegment;
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  const selectedValueBehavioralSegment = (BehavioralSegment) => {
    selectedFilterData.behavioral_segment = BehavioralSegment;
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  const selectedValueStatus = (status) => {
    selectedFilterData.status_detail = status;
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  const selectedValueBCreatedBy = (createdBy) => {
    selectedFilterData.user_id = createdBy;
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  let startPicker = createRef("");
  const startDate = (startDate) => {
    if (startDate.type === "change") {
      let date = startDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.start_date = date;
        dateRangerChecker();
      }
    } else {
      startDate = startDate.replaceAll("/", "-");
      selectedFilterData.start_date = startDate;
      dateRangerChecker();
    }
  };
  const endDate = (endDate) => {
    if (endDate.type === "change") {
      let date = endDate.target.value;
      if (regex.test(date)) {
        date = date.replaceAll("/", "-");
        selectedFilterData.end_date = date;
        dateRangerChecker();
      }
    } else {
      endDate = endDate.replaceAll("/", "-");
      selectedFilterData.end_date = endDate;
      dateRangerChecker();
    }
  };

  const dateRangerChecker = () => {
    if (
      selectedFilterData.start_date > selectedFilterData.end_date &&
      selectedFilterData.end_date !== ""
    ) {
      addToast(
        infoToastConfig({
          toastMessage: "The start date should be less than the end date.",
          variant: "warning",
        })
      );
      return;
    }
    selectedFilterData.pageNo = 1;
    getTableData();
  };
  function pagination(pageno) {
    selectedFilterData.pageNo = pageno;
    getTableData();
  }
  function clearFilter() {
    selectedFilterData.start_date = "";
    selectedFilterData.start_date = "";
    selectedFilterData.end_date = "";
    selectedFilterData.behavioral_segment = "";
    selectedFilterData.brand = "";
    selectedFilterData.indication = "";
    selectedFilterData.vendor = "";
    selectedFilterData.brand_segment = "";
    selectedFilterData.status_detail = "";
    selectedFilterData.user_id = "";
    selectedFilterData.pageNo = 1;
    selectedFilterData.limit = 10;
    selectedFilterData.downloadcsv = "false";
    getTableData();
    setSelectedBrand("");
    setSelectedVendor("");
    setSelectedIndication("");
    setSelectedBrandSegment("");
    setSelectedBehavioralSegment("");
    setSelectedStatus("");
    setCreatedBy("");
    startPicker.current.value = "";
    setSelectedStartDate("");
    setSelectedEndDate("");
  }
  async function downloadcsvData() {
    const { meta, payload } = await dispatch(
      getHistoryTableData({ ...selectedFilterData, downloadcsv: "true" })
    );
    if (meta.requestStatus === "fulfilled") {
      const csvdata = payload.data.data;
      if (csvdata) {
        let exportData = csvdata.map(
          ({
            // eslint-disable-next-line camelcase
            id,
            job_id,
            email_id,
            ...rest
          }) => rest
        );
        exportData = exportData.map((obj) => {
          obj.supression_details = JSON.stringify(obj.supression_details)
            .replace(/{|}/g, "")
            .replaceAll(",", ", ");
          return obj;
        });
        const headers = [
          "user_id",
          "brand",
          "indication",
          "vendor",
          "behavioral_segment",
          "brand_segment",
          "status_detail",
          "initial_target_list_count",
          "final_list_count",
          "created_by",
          "created_date",
          "supression_details",
        ];
        const newData = exportData.map((row) => {
          const newRow = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const key of headers) {
            newRow[key] = row[key];
          }
          return newRow;
        });
        ExportExcelSheet(newData, "adhoc_list_data");
      }
    }
  }

  return (
    <>
      <div className="index-section">
        {isFiltersLoading ? (
          <>
            <div className="row">
              <div className="col-2">
                <LdsSelect
                  id="brand"
                  label="Brand"
                  name="brand"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedBrand(e.value);
                    selectedValueBrand(e.value);
                  }}
                  options={brand}
                  value={selectedBrand}
                />
              </div>
              <div className="col-2">
                <LdsSelect
                  id="vendor"
                  label="Vendor"
                  name="vendor"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedVendor(e.value);
                    selectedValueVendor(e.value);
                  }}
                  options={vendor}
                  value={selectedVendor}
                />
              </div>
              <div className="col-2">
                <LdsSelect
                  id="indication"
                  label="Indication"
                  name="indication"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedIndication(e.value);
                    selectedValueIndication(e.value);
                  }}
                  options={indication}
                  value={selectedIndication}
                />
              </div>
              <div className="col-2">
                <LdsSelect
                  id="brandseg"
                  label="Brand Segment"
                  name="brand_segment"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedBrandSegment(e.value);
                    selectedValueBrandSegment(e.value);
                  }}
                  options={brand_segment}
                  value={selectedBrandSegment}
                />
              </div>
              <div className="col-2">
                <LdsSelect
                  id="Behavioral"
                  label="Behavioral Segment"
                  name="behavioral_segment"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedBehavioralSegment(e.value);
                    selectedValueBehavioralSegment(e.value);
                  }}
                  options={behavioral_segment}
                  value={selectedBehavioralSegment}
                />
              </div>
              <div className="col-2">
                <LdsSelect
                  id="statusdetail"
                  label="Status Detail"
                  name="status_detail"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setSelectedStatus(e.value);
                    selectedValueStatus(e.value);
                  }}
                  options={status_detail}
                  value={selectedStatus}
                />
              </div>
            </div>
            <div className="row mt-4 secondRow">
              <div className="col-2">
                <LdsSelect
                  id="userid"
                  label="Created By"
                  name="user_id"
                  disabled={!isTableDataLoading}
                  required={true}
                  onChange={(e) => {
                    setCreatedBy(e.value);
                    selectedValueBCreatedBy(e.value);
                  }}
                  options={user_id}
                  value={createdBy}
                />
              </div>
              <div className="col-3 datePicker">
                <LdsDatepicker
                  dateFormat="MM/DD/YYYY"
                  id="testPicker2"
                  label="Campaign Start Date"
                  value={selectedStartDate}
                  ref={startPicker}
                  onChange={(e) => {
                    setSelectedStartDate(e);
                    startDate(e);
                  }}
                />
              </div>
              <div className="col-3 ml+6 datePicker">
                <LdsDatepicker
                  dateFormat="MM/DD/YYYY"
                  id="testPicker"
                  label="Campaign End Date"
                  onChange={(e) => {
                    setSelectedEndDate(e);
                    endDate(e);
                  }}
                />
              </div>
              <div className="col-2 clearButton">
                <LdsButton
                  className="clearbt"
                  onClick={clearFilter}
                  disabled={!isTableDataLoading}>
                  Clear
                </LdsButton>
              </div>
              <div className="col-2 mt-3  downloadCsv">
                <LdsButton
                  className="buttons"
                  icon="DownloadSimple"
                  disabled={!isTableDataLoading}
                  onClick={downloadcsvData}>
                  Download As CSV
                </LdsButton>
              </div>
            </div>
            <div className="auditTable mt-4">
              <Historytable
                adhocTableData={adhocTableData}
                pagination={pagination}
                isTableDataLoading={isTableDataLoading}
                pageNo={pageNo}
                selectedFilterData={selectedFilterData.pageNo}
              />
            </div>
          </>
        ) : (
          <div className="ta-center p-3 m-4">
            <LdsLoadingSpinner size={50} />
          </div>
        )}
      </div>
    </>
  );
}
