import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMetaData } from '../../../api/actions/tacticentry';


export const initialState = {
    MetaData: [],
    tacticOptions: [],
    totalPages: 0,
    loader:false
}

const fetchMetaData = createAsyncThunk(
  "getMetaData/fetchMetaData",
  async (queryParams) => {
    const response = await getMetaData(queryParams);
    // console.log('response fetchmetadata',response);
    return await response.data;
  }
);
// console.log(fetchMetaData);

  const metaDataSlice = createSlice({
    name: 'MetaData',
    initialState,
    extraReducers(builder) {
      builder.addCase(fetchMetaData.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.MetaData = action?.payload?.data;
        state.loader = false;
      });
      builder.addCase(fetchMetaData.pending, (state, action) => {
        state.loader = true;
      });
      builder.addCase(fetchMetaData.rejected, (state, action) => {
        state.user = action.payload;
        state.loader = false;
      });
    },
  });

export {fetchMetaData};
// export {getMetaData};
export default metaDataSlice.reducer;