// import { handleError, clearError } from '../utils';
import { editPendingRequest } from '../../../../api/actions/TxmyMgmt/requestForApproval/editPendingRequests';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  getApprovals: [],
  totalPages: 0,
};

const updateApprovalData = createAsyncThunk(
  "overview/updateCategory",
  async (payload) => {
    const response = await editPendingRequest(payload);
    return response.data;
  }
);

const tacctEditApproval = createSlice({
  name: "editApproval",
  initialState,

  extraReducers(builder) {

    builder.addCase(updateApprovalData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateApprovalData.fulfilled, (state, action) => {
      // state.getCategories = action.payload.data;
      // state.totalPages = action.payload.totalPages;
      state.loading = false;
    });
    builder.addCase(updateApprovalData.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.loading = false;
    });
  },
});

export { updateApprovalData };
export default tacctEditApproval.reducer;