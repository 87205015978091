import ctx from '../../../../plugins/axios';


export default async function getTonalitybyID (id) {
  try {
    const { data, status } = await ctx.get(`/edit-tonality?tonality_id=${id}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
