// import { handleError, clearError } from '../utils';
import { createSubcategory } from "../../../../api/actions/TxmyMgmt/addSubCategory/createSubCategory";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  loading:false,
};



const createSubCat = createAsyncThunk(
  "createSubCat/createSubcategory",
  async (payload) => {
    const response = await createSubcategory(payload);
    return response.data;
  }
);



const tacctCreateSubCat = createSlice({
  name: "createSubcategory",
  initialState,
  
  extraReducers(builder) {
   
    builder.addCase(createSubCat.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createSubCat.fulfilled, (state, action) => {
        state.loading = false;
    });
    builder.addCase(createSubCat.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { createSubCat };
export default tacctCreateSubCat.reducer;
