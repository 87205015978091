// import { handleError, clearError } from '../utils';
import {updateProduct} from '../../../../api/actions/TxmyMgmt/editProduct/updateProduct';

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  getProducts: [],
  totalPages: 0,
};

const updateProductData = createAsyncThunk(
  "overview/updateProduct",
  async (payload) => {
    const response = await updateProduct(payload);
    return response.data;
  }
);



const tacctUpdateProductSlice = createSlice({
  name: "updateCategory",
  initialState,
  
  extraReducers(builder) {
   
    builder.addCase(updateProductData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProductData.fulfilled, (state, action) => {
        state.getProducts = action.payload.data;
        state.loading = false;
    });
    builder.addCase(updateProductData.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});

export { updateProductData };
export default tacctUpdateProductSlice.reducer;
