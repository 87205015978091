import ctx from "../../../plugins/axios";
/**
 * Get List Mgmt RM CAP data in Response Matrix Table.
 *
 * @param {Object} ctx Context used to reach to the API.
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (selectedFilter) => {
  const queryStrcount = `/rmcapmetadata?campaign_id=${selectedFilter.campaign}&veeva_doc_id=${selectedFilter.veevaDoc}&brand=${selectedFilter.brand}&indication=${selectedFilter.indication}&department=${selectedFilter.department}&search=${selectedFilter.search}&CurrentPage=${selectedFilter.pageNo}&pageDataLimit=${selectedFilter.limit}&downloadcsv=${selectedFilter.downloadcsv}`;
  const { data, status } = await ctx.get(queryStrcount);
  return { data, status };
};