import ctx from '../../../plugins/axios';

export default async (payload, currentPage) => {
  try {
    const { data, status } = await ctx.get(`/cap-audit?daysAgo=${payload?.defaultFilter}&versotacticid=${payload?.id}`);    
    return { data, status };
  } catch (error) {
    return { error };
  }
};
